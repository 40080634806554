import React from 'react';
import { useState } from 'react';
import Icon from '../../../Icon/Index';
import { plateformsContent, plateforms } from '../ServiceData/Index';
import { CoverageCapabilitiesWrapper } from './ModuleComponentStyle';
import { Slide } from 'react-awesome-reveal';

const CoverageCapabilities = ({ description }) => {
  const [active, setActive] = useState('azure');

  return (
    <CoverageCapabilitiesWrapper>
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="row mt-5 pt-5">
          <div className="d-flex justify-content-center concern_heading">Coverage Capabilities</div>
          {description && (
            <div className="coverage-description mb-4">
              Secure every facet of your digital landscape - cloud infrastructure, workloads, applications, on-premises, and hybrid models - with our 24/7/365 monitoring, threat detection, and
              response capabilities. Trust us for relentless, round-the-clock cybersecurity vigilance
            </div>
          )}
          <div className="coverageBoxContainer mb-5">
            <div className="col-lg-7 col-md-7 col-6 d-flex justify-content-between flex-wrap">
              {plateforms.map((e) => (
                <div className={`roundBox ${active === e ? 'active' : ''}`} key={e} onClick={() => setActive(e)}>
                  <Icon iconType="Home" name={e} width={15} />
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center mb-5 chipContainer">
            {plateformsContent[active].map((e) => (
              <div className="concern_chip" key={e}>
                {e}
              </div>
            ))}
          </div>
        </div>
      </Slide>
    </CoverageCapabilitiesWrapper>
  );
};

export default CoverageCapabilities;
