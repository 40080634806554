import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import './alerttoken.css';

const AlertToken = ({ variant, show, message, duration, onClose }) => {
  const [isVisible, setIsVisible] = useState(show);
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    setIsVisible(show);
    if (show) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose(); // Call the onClose function after the duration
      }, duration);

      // Clear the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [show, duration, onClose]);

  useEffect(() => {
    // Set the background color based on the variant
    if (variant === 'success') {
      setBackgroundColor('#000'); // Black background for success
    } else if (variant === 'danger') {
      setBackgroundColor('red'); // Red background for failure
    }
  }, [variant]);

  const mobileScreen = window.screen.width < 500;

  const alertStyle = {
    padding: '30px 10px 30px 10px',
    position: 'fixed',
    bottom: '10%',
    right: 0,
    zIndex: 10000,
    fontSize: '16px',
    width: mobileScreen ? '100%' : '400px',
    height: 'auto',
    transition: 'transform 0.5s',
    backgroundColor: backgroundColor, // Set the dynamic background color
    color: variant === 'success' ? '#fff' : '#000', // Set text color based on variant
    whiteSpace: 'wrap'
  };

  return (
    <Alert
      variant={variant}
      show={isVisible}
      className={`mt-3 ${isVisible ? 'slide-in' : 'slide-out'}`}
      style={alertStyle} // Apply the dynamic styles here
    >
      {message}
      <button className="close mx-2" onClick={onClose}>
        <span aria-hidden="true" style={{ position: 'absolute', top: '25px', right: '-6px' }}>
          &times;
        </span>
      </button>
    </Alert>
  );
};

export default AlertToken;
