/* eslint-disable linebreak-style */
import Layout from './Layout/Index';
import ZSGlobalStyle from './Styles/ZSGlobleStyle';

const App = () => {

  return (
    <div className="app">
      <ZSGlobalStyle />
      <Layout />
    </div>
  );
};

export default App;
