import React from 'react';

import TermsAndConditionPolicyStyle from './TermsAndConditionPolicyStyle';

const TermsAndConditionPolicy = () => {
  return (
    <TermsAndConditionPolicyStyle className="container">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h2 className="text-center" style={{ marginBottom: '40px' }}>Terms and Conditions</h2>
        <h3>1. Data Collection and Usage</h3>
        <p>We will collect and use your name, contact number, and e-mail ID for the sole purpose of sending you monthly newsletters, contacting you for event updates and o facilitate communication for inquiries, feedback, or to schedule and confirm demo bookings.. Your data will not be used for any other purposes unless explicitly specified.</p>
        <h3>2. Data Protection</h3>
        <p>We prioritize your privacy. Your data will be stored securely, and we will not share or sell your information to third parties without your explicit consent.</p>
        <h3>3. Opt-Out</h3>
        <p>You can unsubscribe or opt-out of our contact list at any time by notifying us at <a href="mailto:info@zeronsec.com" className="FooterSectionOne_left_mail text-center">
        <span style={{ display: 'contents' }}>
            <i className="fa-solid fa-envelope "></i> <span>info@zeronsec.com </span>{' '}
        </span>
        </a>.</p>
        <h3>4. Updates</h3>
        <p>We may occasionally update these terms and conditions. When significant changes are made, we will notify you via the e-mail ID provided.</p>
        <h3>5. Liabilities</h3>
        <p style={{ marginBottom: '50px' }}>We are not liable for any damages or losses resulting from your usage of our platform or services, beyond the extent permitted by law.</p>
      </div>
    </TermsAndConditionPolicyStyle>
  );
};

export default TermsAndConditionPolicy;
