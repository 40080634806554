import { useState } from 'react';
import ZSSocStyle from './ZSSocStyle.js';
import ServiceHeaderTwo from '../ModuleComponent/ServiceHeaderTwo.js';
import ZSEnsureOrganizationBox from '../../ZSHome/Lib/ZSEnsureOrganization/Index.js';
import SocImg from '../../../Image/ZSServices/SOC Services.png';
import responsibility from '../../../Image/ZSServices/SOC SERVCICES - 1.png';
import responsibility2 from '../../../Image/ZSServices/SOC SERVICES-2.png';
import { DownloadValidation } from '../../../Component/ZSModal/Lib/DownloadModal/DownloadValidation.js';
import AlertToken from '../../../Component/AlertTokan/Index.js';
import SOCPdf from '../../../Image/ZSServices/SOCGAP.pdf';
import { Slide } from 'react-awesome-reveal';
import BookingModal from '../../../Component/ZSModal/Lib/BookingModal/Index.js';

const ZSSoc = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    acceptTerms: false,
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    phoneNumberError: '',
    acceptTermsError: ''
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Define handleDemoButtonClick function for the "Know More" button
  const handleDemoButtonClick = () => {
    setShowModal(true);
  };

  // Define closeModal function for closing the modal
  const closeModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
      [`${fieldName}Error`]: '' // Clear the error for the corresponding field
    });
  };

  // Define handleSubmit function for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    resetForm();

    const errors = DownloadValidation(formData);

    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, ...errors });
      return;
    }

    setIsLoading(true); // Start loading

    try {
      const pdfBlob = await fetch(SOCPdf).then((res) => res.blob());

      const pdfUrl = URL.createObjectURL(pdfBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = 'SOCGAP-about.pdf'; // Customize the file name
      const documentTitle = 'SOC-Documnet';
      document.body.appendChild(downloadLink);

      const currentDate = new Date().toLocaleString();

      const config = {
        SecureToken: '5b8e9f5b-5ed8-45c9-aaef-d21ec97f7fe3',
        To: 'pratiknimavat5@gmail.com',
        From: 'pratiknimavat5@gmail.com',
        Subject: 'Web Resource Download Alert!',
        Body: `Hello,<br/><br/>
      A resource from the website is downloaded.<br/><br/>
      <b>Details:</b>
      <li><b>Document:</b> [ ${documentTitle} ] </li>
      <li><b>Download Date:  </b> [ ${currentDate} ] </li>
      <li><b>Customer Email: </b> [ ${formData.email} ] </li>
      Please consider reaching out to them with a thank you note or any other relevant information. <br/>It's these small gestures that make a big difference in building relationships.<br/><br/>
      Zeronsec      
      `
      };

      if (window.Email) {
        await window.Email.send(config);
        setShowSuccess(true);
        downloadLink.click();
        URL.revokeObjectURL(pdfUrl);
        document.body.removeChild(downloadLink);
        setIsLoading(false); // Stop loading
        setShowModal(false);
      }
    } catch (error) {
      console.log(error.text);
      setSendError(true);
      setIsLoading(false); // Stop loading on error
    }
  };

  // Function to reset form data
  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      acceptTerms: false
    });
  };

  return (
    <ZSSocStyle className="">
      <ServiceHeaderTwo
        title={<>Security Operations Center(SOC)</>}
        lineHeight="76.5px"
        content={
          <>
            With expertise in architecting and operating global-scale Cyber Security Operation Centers, we provide unparalleled monitoring for campus networks, data centers, cloud infrastructures,
            industrial systems, and applications. Our extensive experience deploying an array of platforms and applications equips us with unique insights to comprehend your challenges and tailor
            solutions. Entrust us with your cyber security - we've mastered the art of security operations.
          </>
        }
        text="Book a Demo"
        imgsrc={SocImg}
        onclick={handleDemoButtonClick}
      />
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="m-0 py-5">
          <div className="SOCresponsibility container">
            <div className="SOCresponsibility_title py-5 ">
              Because Security is
              <br />a Shared Responsibility
            </div>
            <div className="d-flex flex-column align-items-center SOCresponsibility_chart ">
              <img src={responsibility} alt="" width="95%" className="my-3" />
            </div>
            <div className="d-flex flex-column align-items-center  SOCresponsibility_chart">
              <img src={responsibility2} alt="" width="94.5%" className="my-3" />
            </div>
          </div>
        </div>
      </Slide>
      <BookingModal showModal={showModal} handleCloseModal={closeModal} />
      {/* <DownloadModal showModal={showModal} closeModal={closeModal} formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} isLoading={isLoading} /> */}
      {/* Show success alert */}
      <AlertToken
        variant="success"
        show={showSuccess}
        message={
          <>
            Thank you for downloading the datasheet !<br />
            <br />
            We appreciate your interest. Have a great day !
          </>
        }
        duration={300000000}
        onClose={() => setShowSuccess(false)}
      />
      {/* Show error alert */}
      <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." duration={300000000} onClose={() => setSendError(false)} />
      <ZSEnsureOrganizationBox />
    </ZSSocStyle>
  );
};

export default ZSSoc;
