import { useState } from 'react';
import ZSHeader from './Lib/ZSHeader/Index';
import ZSContent from './Lib/ZSContent/Index';
import ZSFooter from './Lib/ZSFooter/Index';
// import { PacmanLoader } from 'react-spinners';
import { LayoutContainer, SpinnerContainer } from './ZSLayoutStyle';
import ZeronsecLoader from '../Icon/ZeronsecLoader';

const Layout = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <LayoutContainer>
      {loading ? (
        <SpinnerContainer>
          {/* <PacmanLoader color="#1a3cdb" size={30} /> */}
          <ZeronsecLoader />
        </SpinnerContainer>
      ) : (
        <>
          <ZSHeader />
          <ZSContent />
          <ZSFooter />
        </>
      )}
    </LayoutContainer>
  );
};

export default Layout;
