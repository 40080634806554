import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ZSFooterSection2LinksData = (props) => {
  const { links } = props;
  return (
    <Nav className="justify-content-lg-center justify-content-md-center justify-content-center  mb-2 mb-md-0  ">
      {links.map((link, i) => (
        <Nav.Link as={Link} to={link.path} key={i} target='_blank' className="text-light footer__link">
          {link.label}
        </Nav.Link>
      ))}
    </Nav>
  );
};

export default ZSFooterSection2LinksData;
