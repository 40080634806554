import React from 'react';
import ProductFeaturesStyle from './ProductFeaturesStyle.js';
import ImageFullScreen from './FullScreenImg/Index';
import { useState } from 'react';
import Icon from '../../../../Icon/Index';
import { useEffect } from 'react';
import { Slide } from 'react-awesome-reveal';

const ProductFeatures = (props) => {
  const { productData, type } = props;
  const [activeItem, setActiveItem] = useState(1);
  const [featureHeight, setFeatureHeight] = useState(0);
  const [showSubTitleImages, setShowSubTitleImages] = useState({
    1: true,
    2: false,
    3: false,
    4: false,
    5: false
  });
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [expandedItems, setExpandedItems] = useState({
    1: true,
    2: false,
    3: false,
    4: false,
    5: false
  });

  useEffect(() => {
    setActiveItem(1);
    setShowSubTitleImages({
      1: true,
      2: false,
      3: false,
      4: false,
      5: false
    });
    setExpandedItems({
      1: true,
      2: false,
      3: false,
      4: false,
      5: false
    });
    setSelectedImageIndex(0);
    const abc = document.getElementById('cardHeight');
    if (abc) {
      setFeatureHeight(abc.offsetHeight);
    }
  }, [type]);

  // Open the full-screen view
  const handleImageClick = (isVideo, index) => {
    setSelectedImageIndex(index);
    setShowFullScreen(true);
  };

  // Close the full-screen view
  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
  };

  const handleChildBoxClick = (itemNumber) => {
    setSelectedImageIndex(0); // Reset selected image index
    setActiveItem(itemNumber);
    setShowSubTitleImages((prevState) => ({
      ...prevState,
      [itemNumber]: true
    }));
    setExpandedItems((prevExpanded) => ({
      ...prevExpanded,
      [itemNumber]: !prevExpanded[itemNumber]
    }));

    // Collapse all other items
    const updatedExpandedItems = {};
    for (let i = 1; i <= 5; i++) {
      if (i !== itemNumber) {
        updatedExpandedItems[i] = false;
      }
    }
    setExpandedItems((prevExpanded) => ({
      ...prevExpanded,
      ...updatedExpandedItems
    }));
  };

  const handleImage2Click = () => {
    const newProductData = { ...productData };
    const tempImage = newProductData?.productFeatures[`subTitle${activeItem}`]?.productImage1;
    const tempIsVideo = newProductData?.productFeatures[`subTitle${activeItem}`]?.isVideo1;

    newProductData.productFeatures[`subTitle${activeItem}`].productImage1 = newProductData?.productFeatures[`subTitle${activeItem}`]?.productImage2;
    newProductData.productFeatures[`subTitle${activeItem}`].isVideo1 = newProductData?.productFeatures[`subTitle${activeItem}`]?.isVideo2;
    newProductData.productFeatures[`subTitle${activeItem}`].productImage2 = tempImage;
    newProductData.productFeatures[`subTitle${activeItem}`].isVideo2 = tempIsVideo;

    setSelectedImageIndex(selectedImageIndex === 1 ? 0 : 1); // Toggle selectedImageIndex
  };

  const handleImage3Click = () => {
    const newProductData = { ...productData };
    const tempImage = newProductData?.productFeatures[`subTitle${activeItem}`]?.productImage1;
    const tempIsVideo = newProductData?.productFeatures[`subTitle${activeItem}`]?.isVideo1;

    newProductData.productFeatures[`subTitle${activeItem}`].productImage1 = newProductData?.productFeatures[`subTitle${activeItem}`]?.productImage3;
    newProductData.productFeatures[`subTitle${activeItem}`].isVideo1 = newProductData?.productFeatures[`subTitle${activeItem}`]?.isVideo3;
    newProductData.productFeatures[`subTitle${activeItem}`].productImage3 = tempImage;
    newProductData.productFeatures[`subTitle${activeItem}`].isVideo3 = tempIsVideo;

    setSelectedImageIndex(selectedImageIndex === 2 ? 0 : 2); // Toggle selectedImageIndex
  };

  return (
    <ProductFeaturesStyle className="container py-1 mt-5">
      <div className="AnitaFeatures">
        <div className="Anita-title text-center pb-4">{productData?.productFeatures?.title}</div>
        <Slide direction="up" triggerOnce duration={1500} delay={100}>
          <div
            className="AnitaFeatures_mainbox row justify-content-around"
            style={{
              minHeight: productData.product === 'threati' ? '550px' : productData.product === 'anrita' ? 'unset' : '750px'
            }}
          >
            <div id="cardHeight" className="col-lg-5 col-md-12 col-sm-12 d-flex flex-column" style={{ height: '100%' }}>
              <div className={`AnitaFeatures_childbox ${activeItem === 1 ? 'active' : ''}`} onClick={() => handleChildBoxClick(1)}>
                {expandedItems[1] ? (
                  <>
                    <Icon name="arrowright" iconType="Home" style={{ marginLeft: '14px' }} width={8} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_childbox_title">{productData?.productFeatures?.subTitle1?.title}</p>
                    <p className="AnitaFeatures_childbox_para">{productData?.productFeatures?.subTitle1?.subTitle}</p>
                  </>
                ) : (
                  <>
                    <Icon name="arrowdown" iconType="Home" width={12} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_expndtitle">{productData?.productFeatures?.subTitle1?.AnitaFeatures_expndtitle}</p>
                  </>
                )}
              </div>
              <div className={`AnitaFeatures_childbox ${activeItem === 2 ? 'active' : ''}`} onClick={() => handleChildBoxClick(2)}>
                {expandedItems[2] ? (
                  <>
                    <Icon name="arrowright" iconType="Home" style={{ marginLeft: '14px' }} width={8} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_childbox_title">{productData?.productFeatures?.subTitle2?.title}</p>
                    <p className="AnitaFeatures_childbox_para">{productData?.productFeatures?.subTitle2?.subTitle}</p>
                  </>
                ) : (
                  <>
                    <Icon name="arrowdown" iconType="Home" width={12} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_expndtitle">{productData?.productFeatures?.subTitle2?.AnitaFeatures_expndtitle}</p>
                  </>
                )}
              </div>
              <div className={`AnitaFeatures_childbox ${activeItem === 3 ? 'active' : ''}`} onClick={() => handleChildBoxClick(3)}>
                {expandedItems[3] ? (
                  <>
                    <Icon name="arrowright" iconType="Home" style={{ marginLeft: '14px' }} width={8} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_childbox_title">{productData?.productFeatures?.subTitle3?.title}</p>
                    <p className="AnitaFeatures_childbox_para">{productData?.productFeatures?.subTitle3?.subTitle}</p>
                  </>
                ) : (
                  <>
                    <Icon name="arrowdown" iconType="Home" width={12} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_expndtitle">{productData?.productFeatures?.subTitle3?.AnitaFeatures_expndtitle}</p>
                  </>
                )}
              </div>
              <div className={`AnitaFeatures_childbox ${activeItem === 4 ? 'active' : ''}`} onClick={() => handleChildBoxClick(4)}>
                {expandedItems[4] ? (
                  <>
                    <Icon name="arrowright" iconType="Home" style={{ marginLeft: '14px' }} width={8} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_childbox_title">{productData?.productFeatures?.subTitle4?.title}</p>
                    <p className="AnitaFeatures_childbox_para">{productData?.productFeatures?.subTitle4?.subTitle}</p>
                  </>
                ) : (
                  <>
                    <Icon name="arrowdown" iconType="Home" width={12} className="AnitaFeatures_childboxicon" />
                    <p className="AnitaFeatures_expndtitle">{productData?.productFeatures?.subTitle4?.AnitaFeatures_expndtitle}</p>
                  </>
                )}
              </div>
              {productData.product !== 'threati' && (
                <div className={`AnitaFeatures_childbox ${activeItem === 5 ? 'active' : ''}`} onClick={() => handleChildBoxClick(5)}>
                  {expandedItems[5] ? (
                    <>
                      <Icon name="arrowright" iconType="Home" style={{ marginLeft: '14px' }} width={8} className="AnitaFeatures_childboxicon" />
                      <p className="AnitaFeatures_childbox_title">{productData?.productFeatures?.subTitle5?.title}</p>
                      <p className="AnitaFeatures_childbox_para">{productData?.productFeatures?.subTitle5?.subTitle}</p>
                    </>
                  ) : (
                    <>
                      <Icon name="arrowdown" iconType="Home" width={12} className="AnitaFeatures_childboxicon" />
                      <p className="AnitaFeatures_expndtitle">{productData?.productFeatures?.subTitle5?.AnitaFeatures_expndtitle}</p>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 d-flex flex-column  AnitaFeatures_childbox_imgs">
              {showSubTitleImages[activeItem] && (
                <div className="AnitaFeatures_childbox_mainInner">
                  <div
                    className="AnitaFeatures_childbox_mainimg"
                    style={{
                      height: productData.product === 'threati' ? '100%' : '60%',
                      position: 'relative'
                    }}
                  >
                    <div className="AnitaFeatures_subChildbox_Top_childimg">
                      {productData?.productFeatures[`subTitle${activeItem}`]?.productImage1 && (
                        <>
                          {productData?.productFeatures[`subTitle${activeItem}`]?.isVideo1 ? (
                            <>
                              <video
                                controls={false}
                                // height={600}
                                style={{
                                  width: '100%',
                                  objectFit: 'cover',
                                  borderRadius: '20px'
                                }}
                              >
                                <source src={productData?.productFeatures[`subTitle${activeItem}`]?.productImage1} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              <div className="play-button" onClick={() => handleImageClick(true, 0)}>
                                <i className="fa-solid fa-play"></i>
                              </div>
                            </>
                          ) : (
                            <img
                              src={productData?.productFeatures[`subTitle${activeItem}`]?.productImage1}
                              alt={`${productData?.product}FeaturesDashboard1`}
                              onClick={() => handleImageClick(false, 0)}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {productData.product !== 'threati' && (
                    <div
                      className="AnitaFeatures_childbox_childimg d-flex justify-content-between"
                      style={{
                        height: '35%',
                        marginTop: '20px',
                        alignItems: 'end'
                      }}
                    >
                      <div className="AnitaFeatures_subChildbox_childimg">
                        {productData?.productFeatures[`subTitle${activeItem}`]?.productImage2 && (
                          <>
                            {productData?.productFeatures[`subTitle${activeItem}`]?.isVideo2 ? (
                              // <div style={{ width: '46%' }}>
                              <video
                                controls
                                width={370}
                                className={`${selectedImageIndex === 1 ? 'active' : ''}`}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '20px',
                                  objectFit: 'cover'
                                }}
                                onClick={handleImage2Click}
                              >
                                <source src={productData?.productFeatures[`subTitle${activeItem}`]?.productImage2} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              // </div>
                              // <div style={{ width: '46%' }}>
                              <img
                                src={productData?.productFeatures[`subTitle${activeItem}`]?.productImage2}
                                className={`${selectedImageIndex === 1 ? 'active' : ''}`}
                                width={370}
                                style={{
                                  width: '100%',
                                  borderRadius: '20px'
                                }}
                                alt={`Sub Title ${activeItem}`}
                                onClick={handleImage2Click}
                              />
                              // </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="AnitaFeatures_subChildbox_childimg">
                        {productData?.productFeatures[`subTitle${activeItem}`]?.productImage3 && (
                          <>
                            {productData?.productFeatures[`subTitle${activeItem}`]?.isVideo3 ? (
                              <video
                                controls
                                width={370}
                                className={`${selectedImageIndex === 2 ? 'active' : ''}`}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '20px',
                                  objectFit: 'cover'
                                }}
                                onClick={handleImage3Click}
                              >
                                <source src={productData?.productFeatures[`subTitle${activeItem}`]?.productImage3} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              // <div style={{ width: '46%' }}>
                              <img
                                src={productData?.productFeatures[`subTitle${activeItem}`]?.productImage3}
                                width={370}
                                className={`${selectedImageIndex === 2 ? 'active' : ''}`}
                                style={{
                                  width: '100%',
                                  borderRadius: '20px'
                                }}
                                alt={`Sub Title ${activeItem}`}
                                onClick={handleImage3Click}
                              />
                              // </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Slide>
        {showSubTitleImages[activeItem] && showFullScreen && (
          <ImageFullScreen
            images={[
              {
                src: productData?.productFeatures[`subTitle${activeItem}`]?.productImage1,
                isVideo: productData?.productFeatures[`subTitle${activeItem}`]?.isVideo1
              },
              {
                src: productData?.productFeatures[`subTitle${activeItem}`]?.productImage2,
                isVideo: productData?.productFeatures[`subTitle${activeItem}`]?.isVideo2
              },
              {
                src: productData?.productFeatures[`subTitle${activeItem}`]?.productImage3,
                isVideo: productData?.productFeatures[`subTitle${activeItem}`]?.isVideo3
              }
            ].filter((item) => item.src)} // Filter out any undefined/null images
            onClose={handleCloseFullScreen}
          />
        )}
      </div>
    </ProductFeaturesStyle>
  );
};

export default ProductFeatures;
