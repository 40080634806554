import styled from 'styled-components';

export const LayoutContainer = styled.div`
  background: var(--main-light-bg);
  font-family: var(--f-poppins);
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
