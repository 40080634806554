import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Icon from '../../../Icon/Index';
import ZsCarousel from '../../../Component/ZsCarousel';
import ServiceMdrStyle from './ServiceMdrStyle';
import CollapseConent from './CollapseConent';
import EnsureOrganization from '../../ZSHome/Lib/ZSEnsureOrganization/Index';
import ServiceHeaderTwo from '../ModuleComponent/ServiceHeaderTwo';
import { benefitsArray, plateforms, chips, carouselArray2, workFlow } from '../ServiceData/Index';
import DownloadModal from '../../../Component/ZSModal/Lib/DownloadModal/Index.js';
import { DownloadValidation } from '../../../Component/ZSModal/Lib/DownloadModal/DownloadValidation.js';
import AlertToken from '../../../Component/AlertTokan/Index.js';
import mdrimg from '../../../Image/ZSServices/MDR Services.png';
import MDRPdf from '../../../Image/ZSServices/Datasheet - MDR.pdf';
import CoverageCapabilities from '../ModuleComponent/CoverageCapabilities';
import { Slide } from 'react-awesome-reveal';

const Services = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    acceptTerms: false,
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    phoneNumberError: '',
    acceptTermsError: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Define handleDemoButtonClick function for the "Know More" button
  const handleDemoButtonClick = () => {
    setShowModal(true);
  };

  // Define closeModal function for closing the modal
  const closeModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleInputChange = (e) => {
    setSendError(false);
    setShowSuccess(false);
    const fieldName = e.target.name;
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
      [`${fieldName}Error`]: '' // Clear the error for the corresponding field
    });
  };

  // Define handleSubmit function for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = DownloadValidation(formData);

    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, ...errors });
      return;
    }

    setIsLoading(true); // Start loading

    const pdfBlob = await fetch(MDRPdf).then((res) => res.blob());

    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = 'Datasheet - MDR.pdf'; // Customize the file name
    const documentTitle = 'Datasheet - MDR';
    document.body.appendChild(downloadLink);

    const instance = axios.create({
      baseURL: `https://${window.location.hostname}:5000`,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
    });
    const apiData = { method:'get', url:`/datasheet?title=${documentTitle}&email=${formData.email}&lastname=${formData.lastName}&contact=${formData.phoneNumber}&firstname=${formData.firstName}` };
    instance(apiData)
      .then((response) => {
        const { status, type } = response.data;
        if (status) {
          if (type === 'Datasheet') {
            setShowSuccess(true);
            downloadLink.click();
            URL.revokeObjectURL(pdfUrl);
            document.body.removeChild(downloadLink);
            setIsLoading(false); // Stop loading
            setShowModal(false);
            resetForm();
          }
        }
        return response.data;
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          setSendError(true);
          setIsLoading(false);
          setShowModal(false);
          resetForm();
        }
        return error.message;
      });
  };

  // Function to reset form data
  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      acceptTerms: false
    });
  };

  const handleCollapseClick = (index) => {
    if (index !== selectedImageIndex) {
      setSelectedImageIndex(index);
    }
  };

  return (
    <ServiceMdrStyle className="">
      <div className="service">
        <ServiceHeaderTwo
          title={
            <>
              Managed Detection <br /> and Response
            </>
          }
          content={
            <>
              Proactive detection, analysis, and remediation of cyber threats are vital in next-gen security operations. Leverage our Managed Detect and Response services, fusing years of hands-on
              threat remediation experience with top-tier threat intelligence, advanced detection, hunting technologies, and robust remediation strategies. Secure your IT / OT / Cloud infra, apps, and
              data with us today and for the future.
            </>
          }
          text="Know More"
          imgsrc={mdrimg}
          onclick={handleDemoButtonClick}
        />
        <Slide direction="up" triggerOnce duration={1500} delay={100}>
          <div className="container benefitsContainer">
            <div className="row ">
              <div className="d-flex justify-content-center concern_heading ">Benefits</div>
              <div className="boxContainer my-4">
                {benefitsArray.map((e) => (
                  <div className="squerBox" key={e.icon}>
                    <Icon iconType="Home" name={e.icon} width={15} />
                    <div className="box-title">{e.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Slide>
        <Slide direction="up" triggerOnce duration={1500} delay={100}>
          <div className="row carousel-container my-5">
            <div className="carousel-heading">Features</div>
            <ZsCarousel
              nav
              dots
              center={false}
              autoplay={true}
              // responsive
              margin={38}
              responsive={{
                1: {
                  items: 1
                },
                900: {
                  items: 2.25
                },
                1450: {
                  items: 2.75
                }
              }}
            >
              {carouselArray2.map((e) => (
                <div key={e.name} className="carousel-box-item">
                  <div className="header">{e.name}</div>
                  <div className="description mt-3">{e.desc}</div>
                </div>
              ))}
            </ZsCarousel>
          </div>
        </Slide>
        <Slide direction="up" triggerOnce duration={1500} delay={100}>
          <div className="container-md">
            <div className="row pt-5">
              <div className="d-flex justify-content-center concern_heading">How The Service Works?</div>
              <div className="container mt-5 position-relative">
                <div className="z-index-1 collapseContent">
                  <CollapseConent data={workFlow} onCollapseClick={handleCollapseClick} selectedImageIndex={selectedImageIndex} />
                </div>
                <div className="accordianImageContainer">
                  {workFlow.map((step, index) => (
                    <div key={index} className={`image h-100 w-100 ${index === selectedImageIndex ? 'visible' : 'hidden'}`}>
                      <img
                        src={step.image}
                        height="100%"
                        alt={`Step ${index + 1}`}
                        // style={{
                        //   position: 'absolute',
                        //   left: '6rem',
                        //   top: '0',
                        //   height: '620px',
                        //   width: '84%'
                        // }}
                        className="accordianImage"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <div className="container">
          <CoverageCapabilities />
        </div>
        <DownloadModal showModal={showModal} closeModal={closeModal} formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} isLoading={isLoading} />
        {/* Show success alert */}
        <AlertToken
          variant="success"
          show={showSuccess}
          message={
            <>
              Thank you for downloading the datasheet!
              <br />
              <br />
              We appreciate your interest. Have a great day!
            </>
          }
          duration={10000}
          onClose={() => setShowSuccess(false)}
        />
        {/* Show error alert */}
        <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." duration={10000} onClose={() => setSendError(false)} />
        <div className="container">
          <EnsureOrganization />
        </div>
      </div>
    </ServiceMdrStyle>
  );
};

export default Services;
