import styled from 'styled-components';

const ImageFullScreenStyle = styled.div`

.image-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent black overlay */
  z-index: 1000;
  display:flex;
  justify-content:center;
  /* align-items:center; */

  .closePopup {
    position: absolute;
    right: 9%;
    top: calc(42%);
    color: #fff;
    font-size: 1.75rem;
    font-family: 'Poppins';
    cursor: pointer;
  }
}

.image-full-screen-container {
  max-width: 90%;
  max-height: 90%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.image-full-screen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.fa-solid {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 25px;
  cursor: pointer;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 75%;
//   max-height: 100%;
}

.image-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.left-button,
.right-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width:50px;
  height50px;
  background:black;
  border-radius:50%;
  font-weight:bold;
  font-size:30px;
  color:gray;
  transition:all .3s ease;
  &:hover{
    background:#000000ad;
  }
}

.left-button {
  position:absolute;
  top:50%;
  left:20px;
}

.right-button {
    position:absolute;
  top:50%;
  right:20px;
  
}
`;
export default ImageFullScreenStyle;
