// Partner.js
import React from 'react';
import PartnerStyle from './PartnerStyle.js';
import ContactHeader from './Lib/ContactHeader/Index.js'; // Import the new component
import ZeronsecLife from './Lib/ZeronsecLife/Index.js';
import CompanyGloabl from './Lib/ContactGlobal/Index.js';
import AssessmentExpert from '../ZSServices/Lib/AssessmentExpert/Index.js';
import ZSEnsureOrganizationBox from '../ZSHome/Lib/ZSEnsureOrganization/Index.js';

const Partner = () => {
  return (
    <PartnerStyle>
      <ContactHeader />
      <ZeronsecLife />
      <CompanyGloabl />
      <AssessmentExpert />
      <ZSEnsureOrganizationBox />
    </PartnerStyle>
  );
};

export default Partner;
