import Icon from '../../../Icon/Index';
import ZSCyberDefensesBoxContent from './ZSLightSmallCardStyle';

const LightSmallCard = (props) => {
  const { iconName, label, onClick, isActive } = props;
  return (
    <ZSCyberDefensesBoxContent className={`CyberDefenses__box__content mb-3 ${isActive ? 'active' : ''}`} onClick={onClick}>
      <Icon name={iconName} iconType="Home" width={28} className="CyberDefenses__box__content__icon" />
      <div className="CyberDefenses__box__content__label">{label}</div>
    </ZSCyberDefensesBoxContent>
  );
};

export default LightSmallCard;
