import styled from 'styled-components';

const ZSSocStyle = styled.div`
  .SOCresponsibility {
    display: flex;
    flex-direction: column;
    align-items: center;

    .SOCresponsibility_title {
      color: #1a3cdb;
      text-align: center;
      font-size: 57.126px;
      font-style: normal;
      font-weight: 700;
      line-height: 133.9%; /* 76.492px */

      @media (max-width: 1100px) {
        font-size: 47.126px;
      }
      @media (max-width: 780px) {
        font-size: 35px;
      }
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }

    .SOCresponsibility_chart {
      @media (min-width: 1000px) {
        img {
          width: 100%;
        }
        max-width: 90%;
      }
    }
  }
`;

export default ZSSocStyle;
