import styled from 'styled-components';

const ZeronsecLifeStyle = styled.div`
  background: var(--main-dark-bg);
  .ZeronsecLife_title {
    color: #fff;
    text-align: center;
    font-size: 86.811px;
    font-weight: 700;
    line-height: 167.9%; /* 145.756px */
    letter-spacing: -2.17px;
    margin-bottom: -6rem;
  }
  .ZeronsecLife_imgslider {
    transform: translateY(40%);
  }
  .ZeronsecLife_imgslider.companyabout_imgslider {
    &:after,
    &:before {
      display: none;
    }
  }
  @media screen and (min-width: 651px) and (max-width: 1280px) {
    .ZeronsecLife_title {
      font-size: 45.811px !important;
      margin-bottom: -3rem;
    }
  }
  @media screen and (max-width: 650px) {
    .ZeronsecLife_title {
      font-size: 35.811px !important;
      margin-bottom: -4rem;
    }
  }
`;

export default ZeronsecLifeStyle;
