import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ZSHome from '../Page/ZSHome/Index';
import ZSCompany from '../Page/ZSCompany/Index';
import ZSPartner from '../Page/ZSPartner/Index';
import ZSCloudSecurity from '../Page/ZSServices/ZSCloudSecurity/Index';
import ZSMdr from '../Page/ZSServices/ZSMdr/Index';
import ZSSoc from '../Page/ZSServices/ZSSoc/Index';
import ZSAssesmentServices from '../Page/ZSServices/ZSAssesmentServices/Index';
import ZSProducts from '../Page/ZSProduct/Index';
import ZSTerms from '../Page/ZSterms/ZStermsPolicy';
import ZSCookie from '../Page/ZSterms/ZScookiePolicy';
import ZSPrivacy from '../Page/ZSterms/ZSprivacyPolicy';
import ZSTermsAndcondition from '../Page/ZSterms/ZStermsAndcondition';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const HomeRouter = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ZSHome />} />
        <Route path="/product/threati" element={<ZSProducts type="threati" />} />
        <Route path="/product/anrita" element={<ZSProducts type="anrita" />} />
        <Route path="/product/ekasha" element={<ZSProducts type="ekasha" />} />
        <Route path="/services/cloudsecurity" element={<ZSCloudSecurity />} />
        <Route path="/services/mdr" element={<ZSMdr />} />
        <Route path="/services/soc" element={<ZSSoc />} />
        <Route path="/services/assesmentservices" element={<ZSAssesmentServices />} />
        <Route path="/company" element={<ZSCompany />} />
        <Route path="/contact" element={<ZSPartner />} />
        <Route path="/terms" element={<ZSTerms />} />
        <Route path="/cookie" element={<ZSCookie />} />
        <Route path="/privacy" element={<ZSPrivacy />} />
        <Route path="/terms&condition" element={<ZSTermsAndcondition />} />
      </Routes>
    </>
  );
};

export default HomeRouter;
