import AnritaLogo from '../Image/ZSProducts/anrita.png';
import EkashaLogo from '../Image/ZSProducts/ekasha.png';
import ThreatiLogo from '../Image/ZSProducts/threati.png';
import AnritaDashboard from '../Image/ZSProducts/Dashboard_0 - Main 1.png';
import AnritaAboutDashboard from '../Image/ZSProducts/AnritaChart.png';
import ThreatiDashboard from '../Image/ZSProducts/threatiMain.png';
import anritaSS from '../Image/ZSProducts/anrita ss.png';
import anritaf1i1 from '../Image/ZSProducts/anritaf1i1.png';
import anritaf1i2 from '../Image/ZSProducts/anritaf1i2.png';
import anritaf1i3 from '../Image/ZSProducts/anritaf1i3.png';
import anritaf2i1 from '../Image/ZSProducts/anritaf2i1.png';
import anritaf2i2 from '../Image/ZSProducts/anritaf2i2.png';
import anritaf3v1 from '../Image/ZSProducts/anritaf3v1.mp4';
import anritaf3i2 from '../Image/ZSProducts/anritaf3i2.jpg';
import anritaf3i3 from '../Image/ZSProducts/anritaf3i3.jpg';
import anritaf4i1 from '../Image/ZSProducts/anritaf4i1.png';
import anritaf5i1 from '../Image/ZSProducts/anritaf5i1.png';
import ekashaf1i1 from '../Image/ZSProducts/ekashaf1i1.png';
import ekashaf1i2 from '../Image/ZSProducts/ekashaf1i2.png';
import ekashaf2v1 from '../Image/ZSProducts/ekashaf2v1.mp4';
import ekashaf2i2 from '../Image/ZSProducts/ekashaf2i2.png';
import ekashaf2i3 from '../Image/ZSProducts/ekashaf2i3.png';
import ekashaf3v1 from '../Image/ZSProducts/ekashaf3v1.mp4';
import ekashaf3i2 from '../Image/ZSProducts/ekashaf3i2 .png';
import ekashaf3i3 from '../Image/ZSProducts/ekashaf3i3.png';
import ekashaf4i1 from '../Image/ZSProducts/ekashaf4i1.png';
import ekashaf4i2 from '../Image/ZSProducts/ekashaf4i2.png';
import ekashaf4i3 from '../Image/ZSProducts/ekashaf4i3.png';
import ekashaf5v1 from '../Image/ZSProducts/ekashaf5v1.mp4';
import ekashaf5i2 from '../Image/ZSProducts/ekashaf5i2.png';
import ekashaf5i3 from '../Image/ZSProducts/ekashaf5i3.png';
import timeLine from '../Image/ZSProducts/timeLine.png';
import ThreatiF1 from '../Image/ZSProducts/ThreatiF1.png';
import ThreatiF2 from '../Image/ZSProducts/ThreatiF2.png';
import ThreatiF3 from '../Image/ZSProducts/ThreatiF3.png';
import ThreatiF4 from '../Image/ZSProducts/ThreatiF4.png';
import anritaprotectimg from '../Image/ZSProducts/anritaprotect.png';
import threatprotectimg from '../Image/ZSProducts/threatsprotect.png';
import ekashaprotectimg from '../Image/ZSProducts/ekashaprotect - Copy.png';

export const PRODUCTS = {
  THREATI: 'threati',
  ANRITA: 'anrita',
  EKASHA: 'ekasha'
};

const ProductEnvData = [
  {
    product: 'anrita',
    productHeader: {
      title: 'Protect your infrastructure with real-time precise cyber defense technology',
      subTitle: 'Visualize, Analyze, Hunt & Report.',
      productImage: {
        productLogo: AnritaLogo,
        productDashboard: anritaSS
      }
    },
    productAbout: {
      productImage: {
        productAboutDashboard: anritaSS
      },
      title: 'The comprehensive Security Information and Event Management (SIEM) technology that empowers your security team to stay ahead of the game.',
      subTitle:
        'Utilizing advanced analytics and machine learning algorithms, Anrita delivers proactive threat detection and real-time monitoring of security events across your networks, endpoints, and cloud environments. Simplified dashboards, reports, and alerts provide every IT stakeholder with clear actionable information to act upon with flexible architecture and seamless integrations with existing security solutions.'
    },
    productFeatures: {
      title: 'Features',
      subTitle1: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Real-Time Security Insights </span> for all business stakeholders.{' '}
          </span>
        ),
        title: 'Real-time security insights for all business stakeholders.',
        subTitle:
          "Visually stunning dashboards deliver powerful insights on attackers' activity for executives, managers, analysts, administrators, and engineers. MITRE ATT&CK® and Cyber Kill Chain-based detection rules identify and neutralize threats. Customizable dashboards aligned with specific objectives provide deep analysis of live and historical threats.",
        productImage1: anritaf1i1,
        productImage2: anritaf1i2,
        productImage3: anritaf1i3
      },
      subTitle2: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Uncover the hidden threats and analyze </span> relationship between attacked systems, users, processes and network connections.{' '}
          </span>
        ),
        title: 'Uncover the hidden threats and analyse relationship between attacked systems, users, processes and network connections.',
        subTitle:
          "Simplify attack analysis and understand relationship between attackers' activities across systems, networks, applications, databases, and users. Perform root cause analysis of the most complex attack scenarios with ease.",
        productImage1: anritaf2i1,
        productImage2: anritaf2i2,
        productImage3: anritaf1i2
      },
      subTitle3: {
        AnitaFeatures_expndtitle: (
          <span>
            Create and deliver <span className="AnitaFeatures_expndtitlebold"> customized reports </span> to stakeholders worldwide, based on their goals, roles, and preferred delivery schedule.{' '}
          </span>
        ),
        title: 'Create and deliver customized reports to stakeholders worldwide, based on their goals, roles, and preferred delivery schedule.',
        subTitle:
          'Empower your IT stakeholder with detailed reports enabling them to strategize system protection controls and attack remediation actions. Drag-and-drop charts and tables of your choice feature allows you to easily build report templates.',
        productImage1: anritaf3v1,
        isVideo1: true,
        productImage2: anritaf3i2,
        productImage3: anritaf3i3
      },
      subTitle4: {
        AnitaFeatures_expndtitle: (
          <span>
            Proactively <span className="AnitaFeatures_expndtitlebold"> hunt attacks </span> in progress and stop them in their tracks.{' '}
          </span>
        ),
        title: 'Proactively hunt attacks in progress and stop them in their tracks.',
        subTitle:
          'Transform your cyber threat hunting potential with our state-of-the-art technology. Our user-friendly hunting platform enables your security analysts to swiftly execute effective queries, hunt threats, and effortlessly construct relational views of intricate attack elements.',
        productImage1: anritaf4i1
      },
      subTitle5: {
        AnitaFeatures_expndtitle: (
          <span>
            Streamline your security operations with variety of <span className="AnitaFeatures_expndtitlebold"> platform integrations. </span>{' '}
          </span>
        ),
        title: 'Streamline your security operations with variety of platform integrations.',
        subTitle:
          'Seamless integration with attack sources for comprehensive attack information, with threat intelligence to detect threats, and with e-mail, ITSM, and SOAR for effortless alert destinations.',
        productImage1: anritaf5i1
      }
    },
    productProtectYou: {
      title: 'How we Protect you',
      productImage: 'static/images/round-security.svg'
    }
  },
  {
    product: 'ekasha',
    productHeader: {
      title: 'Swift incident remediation with preventative automation',
      subTitle: 'Analyse, Prioritise, Investigate & Orchestrate',
      productImage: {
        productLogo: EkashaLogo,
        productDashboard: timeLine
      }
    },
    productAbout: {
      productImage: {
        productAboutDashboard: timeLine
      },
      title: 'The ultimate Security Orchestration, Automation, and Response (SOAR) Technology that streamlines your SOC operations and remediates emerging threats.',
      subTitle:
        ' Manage full 360 degree of incident life cycle with centralized incident management, KPI dashboards, workflows, and playbooks for efficient and automated incident resolution, integrating all security technologies into one comprehensive platform. Say goodbye to tedious manual tasks and streamline your security operation efficiently with Ekasha.'
    },
    productFeatures: {
      title: 'Features',
      subTitle1: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Clear view leads to clear action. </span> Decipher complex threat data into clear, actionable insights.{' '}
          </span>
        ),
        title: 'Clear view leads to clear action. Decipher complex threat data into clear, actionable insights.',
        subTitle:
          'Unlock powerful insights and smart strategies with our KPI dashboards: prioritize threat scenarios with clarity | Measure and improve your SOC performance | Uncover crucial trends | Stay on top of compliance | Enhance communication flow | Streamline your resources | Fine tune incident responses | Boost transparency | Take control of your cyber risk management.',
        productImage1: ekashaf1i1,
        productImage2: ekashaf1i2,
        productImage3: ekashaf1i2
      },
      subTitle2: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Incident management, redefined: </span> streamlining your path to resolution.{' '}
          </span>
        ),
        title: 'Incident Management, Redefined: Streamlining your path to resolution.',
        subTitle:
          "Steer through the maze of threat alerts, distinguishing genuine threats from false positives. Leverage Ekasha's automation prowess, reshaping the way you manage investigations, threat mitigation, audits, and evidence collection.  Intelligently prioritize incidents based on business impact, empowering you to defend your enterprise effectively and efficiently.",
        productImage1: ekashaf2v1,
        isVideo1: true,
        productImage2: ekashaf2i2,
        productImage3: ekashaf2i3
      },
      subTitle3: {
        AnitaFeatures_expndtitle: (
          <span>
            Break free from the chains of manual delays slowing down your <span className="AnitaFeatures_expndtitlebold"> incident response lifecycle. </span>
          </span>
        ),
        title: 'Break free from the chains of manual delays slowing down your incident response lifecycle.',
        subTitle:
          'Let playbook automation streamline your investigations, swiftly identifying threats and minimize response times. Intelligently gather context, highlight relevant information and remediate threats in no time.',
        productImage1: ekashaf3v1,
        isVideo1: true,
        productImage2: ekashaf3i2,
        productImage3: ekashaf3i3
      },
      subTitle4: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Eliminate inefficiencies and redundancies.</span>{' '}
          </span>
        ),
        title: 'Eliminate inefficiencies and redundancies.',
        subTitle:
          'Seamlessly integrate key systems, networks and security platforms and enable swift data sharing. Get a holistic view, quickly identify, analyse and respond. Minimize the false positives and maximize the accuracy.',
        productImage1: ekashaf4i1,
        productImage2: ekashaf4i2,
        productImage3: ekashaf4i3
      },
      subTitle5: {
        AnitaFeatures_expndtitle: (
          <span>
            Empower your IT and security teams with <span className="AnitaFeatures_expndtitlebold">real-time collaboration. </span>{' '}
          </span>
        ),
        title: 'Empower your IT and security teams with real-time collaboration.',
        subTitle:
          'From alert analysis to remediation, drive collaboration between stakeholders in real time for quicker decision making, co-ordinated threat response and accelerated incident resolution. Retain critical knowledge and co-review past incidents and responses.',
        productImage1: ekashaf5v1,
        isVideo1: true,
        productImage2: ekashaf5i2,
        productImage3: ekashaf5i3
      }
    },
    productProtectYou: {
      title: 'How we Protect you',
      productImage: ekashaprotectimg
    }
  },
  {
    product: 'threati',
    productHeader: {
      title: 'Threat Intelligence',
      subTitle: 'Accurate, Proactive & Actionable ',
      productImage: {
        productLogo: ThreatiLogo,
        productDashboard: ThreatiDashboard
      }
    },
    productAbout: {
      productImage: {
        productAboutDashboard: ThreatiDashboard
      },
      title: 'Accurate, Proactive, and Actionable Threat Intelligence',
      subTitle:
        'Decipher and dismantle threats with efficacy and swiftness. Empower your security operations with our cutting-edge threat intelligence platform. Unleash the power of automation to speed up your threat detection, hunting, and remediation strategies.'
    },
    productFeatures: {
      title: 'Features',
      subTitle1: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Visualize attacks as they happen. </span>{' '}
          </span>
        ),
        title: 'Visualize attacks as they happen.',
        subTitle:
          'With out-of-the-box 3D attack sphere, Threat-i helps you visualize live attack communications happening across your environment with all the essential and critical details.',
        productImage1: ThreatiF1
      },
      subTitle2: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Integrate effortlessly with </span> top tier SOC technologies.{' '}
          </span>
        ),
        title: 'Integrate effortlessly with top tier SOC technologies.',
        subTitle:
          'Threat-i seamlessly integrates with all the security operation technologies such as SIEM, EDR, and SOAR, empowering security teams to swiftly detect and respond to potential threats.',
        productImage1: ThreatiF2
      },
      subTitle3: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Boost your threat detection with our potent and </span> real-time Indicators of Compromise.{' '}
          </span>
        ),
        title: 'Boost your threat detection with our potent and real-time Indicators of Compromise.',
        subTitle: 'Threat hunting is a game of precision and speed. With our cutting-edge IOC feeds, your cyber security operations stays a step ahead of the adversaries.',
        productImage1: ThreatiF3
        // productImage2: anritaf1i2,
        // productImage3: anritaf1i3
      },
      subTitle4: {
        AnitaFeatures_expndtitle: (
          <span>
            <span className="AnitaFeatures_expndtitlebold">Elevate your detection </span> with diverse threat feeds.{' '}
          </span>
        ),
        title: 'Elevate your detection with diverse threat feeds.',
        subTitle:
          'Explore the breadth of our diverse threat intelligence feeds, spanning from ransomware, tor node, malware, phishing and malicious hash feeds. This multi-faceted source matrix supercharges your proactive threat detection, building a fortress of next-gen SOC.',
        productImage1: ThreatiF4
        // productImage2: anritaf2i2
      }
    },
    productProtectYou: {
      title: 'How we Protect you',
      productImage: threatprotectimg
    }
  }
];

export default ProductEnvData;
