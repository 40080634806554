import styled from 'styled-components';

const ProductProtectStyle = styled.div`
  .content {
    height: auto;
    width: 100%;
    .content_first {
      width: 100%;
      height: 100px;
      padding: 0 4% 0% 10%;
      display: flex;
      align-items: center;
      .content_first_child:nth-child(1) {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;
        .threatfeedsources {
          position: absolute;
          right: 30%;
          top: 20%;
          color: #4061fe;
          font-style: normal;
          font-weight: 700;
          line-height: 116.4%; /* 14.497px */
          font-size: 12.454px;
        }
        .anritasmlLinedotted {
          margin-top: 7.5%;
          animation: drawPath 2s linear infinite;
        }
        @keyframes drawPath {
          0% {
            stroke-dashoffset: 1000; /* Adjust this value based on your SVG path length */
          }
          100% {
            stroke-dashoffset: 0;
          }
        }
      }
      .content_first_child:nth-child(2) {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .first_logo_box {
          width: 178.81px;
          flex-shrink: 0;
          border-radius: 18.682px;
          border: 0.801px solid #fae200;
          background: #222947;
          box-shadow: 0px 24.167875289916992px 48.335750579833984px 12.083937644958496px rgba(0, 0, 0, 0.05);
          height: 56.045px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }

        .anritabigLinedotted {
          margin-top: 43%;
          animation: drawPath 2s linear infinite;
          z-index: 1;
        }
      }
    }
    .content2 {
      display: flex;
      .content2_box1 {
        width: 20%;
        height: auto;
        .content2_threat {
          color: #4061ff;
          line-height: 116.4%; /* 15.532px */
          font-style: normal;
          font-weight: 700;
          font-size: 13.344px;
          text-align: center;
          transform: translateY(-20px);
        }
        .content2_threats_icons_wrapper:nth-child(1) span:nth-child(1),
        .content2_threats_icons_wrapper:nth-child(1) span:nth-child(2),
        .content2_threats_icons_wrapper:nth-child(1) span:nth-child(3),
        .content2_threats_icons_wrapper:nth-child(2) span:nth-child(1),
        .content2_threats_icons_wrapper:nth-child(2) span:nth-child(2),
        .content2_threats_icons_wrapper:nth-child(2) span:nth-child(3),
        .content2_threats_icons_wrapper:nth-child(3) span:nth-child(1),
        .content2_threats_icons_wrapper:nth-child(3) span:nth-child(2),
        .content2_threats_icons_wrapper:nth-child(3) span:nth-child(3) {
          width: 1px;
          padding: 0 1px;
          background: #ffa15c;
          margin: 0 2px;
        }
        .content2_threats_icons_wrapper:nth-child(1) span:nth-child(1) {
          height: 50px;
        }
        .content2_threats_icons_wrapper:nth-child(1) span:nth-child(2) {
          height: 100px;
        }
        .content2_threats_icons_wrapper:nth-child(1) span:nth-child(3) {
          height: 40px;
          margin-top: 30px;
        }
        .content2_threats_icons_wrapper:nth-child(2) span:nth-child(1) {
          height: 20px;
          margin-top: 5px;
        }
        .content2_threats_icons_wrapper:nth-child(2) span:nth-child(2) {
          height: 80px;
        }
        .content2_threats_icons_wrapper:nth-child(2) span:nth-child(3) {
          height: 40px;
          margin-top: 15px;
        }
        .content2_threats_icons_wrapper:nth-child(3) span:nth-child(1) {
          height: 50px;
        }
        .content2_threats_icons_wrapper:nth-child(3) span:nth-child(2) {
          height: 100px;
        }
        .content2_threats_icons_wrapper:nth-child(3) span:nth-child(3) {
          height: 40px;
          margin-top: 30px;
        }
        .content2_threats_sources {
          background: #4062ff;
          border-radius: 12px;
          position: relative;

          .content2_threats_sources_title {
            color: #fff;
            font-style: normal;
            font-weight: 700;
            font-size: 13.344px;
            line-height: 94.9%; /* 12.664px */
          }
        }
      }
      .content2_box2 {
        width: 80%;
        height: auto;
        .content2_box2_content {
          background: linear-gradient(192deg, rgba(146, 184, 212, 0.29) 0%, rgba(0, 146, 255, 0) 100%);
          height: 450px;
          border-radius: 40px;
        }
      }
    }
  }
  .dot::before,
  .dot::after {
    content: '';
    width: 4px; /* Half of the original width */
    position: absolute;
    background-color: #fae200;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
  }

  .dot::before {
    left: -4px; /* Adjusted to start from the left edge of the main dot */
  }

  .dot::after {
    right: -4px; /* Adjusted to start from the right edge of the main dot */
  }
  .content2_threats_sources::after {
    top: 80px;
    right: -4px; /* Adjusted to start from the right edge of the main dot */
  }
  .bottom_dot {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: #fae200;
    border-radius: 50%;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
  }

  .bottom_dot::before {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: #222947;
    border-left-radius: 50%;
    top: -4px;
    left: 0;
  }
`;
export default ProductProtectStyle;
