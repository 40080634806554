import ZSRoundChartStyle from './ZSRoundChartStyle';
import ButtonIndex from '../../../../Component/ZSForm/ZSButtons/Index';
import { Link } from 'react-router-dom';
import { Reveal } from 'react-awesome-reveal';
import { ReactSVG } from 'react-svg';
import './round-security.scss';
import { useState } from 'react';

const RoundChart = () => {
  const [animate, setAnimate] = useState(false);

  return (
    <ZSRoundChartStyle>
      <Reveal
        keyframes={null}
        onVisibilityChange={(t) => {
          if (t) setAnimate(true);
        }}
        triggerOnce
        delay={4000}
      >
        <div className="container py-5 d-flex flex-wrap align-items-center justify-content-lg-around  justify-content-md-center justify-content-sm-center flex-column flex-lg-row py-5">
          <div className="RoundChart RoundChart_left">
            <ReactSVG
              afterInjection={(svg) => {
                if (animate) {
                  svg.classList.add('round-security');
                }
              }}
              src="static/images/round-security.svg"
            />
          </div>
          <div className="RoundChart RoundChart_right pt-5">
            <p className="mb-0 RoundChart__title mb-3">
              Are You Facing a <br /> Cyber Attack?
            </p>
            <p className="mb-4 RoundChart__para">
              Turn to Zeronsec during cyber challenges. <br />
              Unearth our steadfast security services.
            </p>
            <Link to="/contact">
              <ButtonIndex ButtonType="Light" text="Get Immediate Help" className="RoundChart__btn" />
            </Link>
          </div>
        </div>
      </Reveal>
    </ZSRoundChartStyle>
  );
};

export default RoundChart;
