import detectimg from '../../../Image/ZSHome/detect-and-respond.jpg';
import manageattackimg from '../../../Image/ZSHome/manage-the-attack-surface.jpg';
import operatingapimg from '../../../Image/ZSHome/assess-the-operation-gaps.jpg';
import invastigationimg from '../../../Image/ZSHome/data-breach-investigation.jpg';
import Vulnerabilitiesimg from '../../../Image/ZSHome/assess-your-vulnerabilities.jpg';
import HuntLiveAttacksinProgressimg from '../../../Image/ZSHome/hunt-live-attacks-in-progress.jpg';
import DesigntheSOC from '../../../Image/ZSHome/design-the-SOC.jpg';
import UnderstandingInsiderthreatimg from '../../../Image/ZSHome/understanding-insider-threat.jpg';

export const CyberDefensesBoxData = [
  {
    id: 'DetectandRespond',
    iconName: 'DetectandRespond',
    label: ['Detect and ', <br key={'break'} />, 'respond'],
    title: 'Proactive threat detection and response management',
    description: 'Around-the-clock cyber threat detection and response. Get comprehensive threat protection with our expert team, proprietary technologies, and proven processes.',
    imageUrl: detectimg
  },
  {
    id: 'ManagaetheAttackSurface',
    iconName: 'ManagaetheAttackSurface',
    label: ['Manage the ', <br key={'break'} />, 'attack surface'],
    title: 'Identify, monitor, and manage potential attack vectors to your IT assets and applications',
    description:
      "Assess your entire IT infrastructure, identify vulnerabilities, and reduce your organization's attack surface with Attack Surface Management. Proactively protect your valuable assets and mitigate the risk of cyber threats.",
    imageUrl: manageattackimg
  },
  {
    id: 'OperationGaps',
    iconName: 'OperationGaps',
    label: ['Assess the', <br key={'break'} />, 'operation gaps'],
    title: 'Identify inefficiencies in your SOC with our gap assessment services.',
    description: 'Our experts assess your technologies, integration, and processes to provide actionable recommendations for improvement.',
    imageUrl: operatingapimg
  },
  {
    id: 'investigation',
    iconName: 'investigation',
    label: ['Data breach', <br key={'break'} />, 'investigation'],
    title: "Don't let a data breach damage your business and reputation.",
    description: 'Allow our specialized data breach investigation services to assist you in pinpointing the origin, evaluating the impact, and mitigating any future occurrences.',
    imageUrl: invastigationimg
  },
  {
    id: 'Vulnerabilities',
    iconName: 'Vulnerabilities',
    label: ['Assess your', <br key={'break'} />, 'vulnerabilities'],
    title: "Don't let vulnerabilities put your business at risk.",
    description: 'Our experts identify critical vulnerabilities across your IT infrastructure and applications, ensuring you stay ahead of potential threats and secure your business.',
    imageUrl: Vulnerabilitiesimg
  },
  {
    id: 'HuntLiveAttacksinProgress',
    iconName: 'HuntLiveAttacksinProgress',
    label: ['Hunt live ', <br key={'break'} />, 'attacks in progress'],
    title: 'Discover and neutralize cyber attacks in progress with our deep cyber threat hunting service, designed to hunt sophisticated threats in your environment.',
    description: '',
    imageUrl: HuntLiveAttacksinProgressimg
  },
  {
    id: 'DesigntheSOC',
    iconName: 'DesigntheSOC',
    label: ['Design', <br key={'break'} />, ' the SOC'],
    title: 'Achieve security operations alignment with business goals through our holistic SOC design approach.',
    description: 'Protect your business with resilient security operations - let our experts help you plan, design and build a robust security operations center.',
    imageUrl: DesigntheSOC
  },
  {
    id: 'UnderstandingInsiderthreat',
    iconName: 'UnderstandingInsiderthreat',
    label: ['Understanding', <br key={'break'} />, ' insider threat'],
    title: 'Gain real-time visibility into user activity and identify potential security threats.',
    description: 'Protect your organization against insider threats with our advanced analytics tools and expert analysis.',
    imageUrl: UnderstandingInsiderthreatimg
  }
];

export const ZSSecurityPartnerBoxData = [
  {
    id: 'ResearchandResultOriented',
    icon: 'ResearchandResultOriented',
    title: 'Research and Result \n Oriented',
    description: 'Extensive research drives all of our solutions and processes. Our experts work in sync with your team to understand your challenges and deliver the desired results expected by you.'
  },
  {
    id: 'HighCalibreService',
    icon: 'HighCalibreService',
    title: 'High Calibre Service',
    description: 'To serve you to the highest standard, our skilled and responsive team always goes the extra mile to provide exceptional support.'
  },
  {
    id: 'HiTechBrains',
    icon: 'HiTechBrains',
    title: 'Hi-tech Brains',
    description: 'Veterans capable of working with all the major arrays of technologies from the past, present, and future. Removing complexity is in our DNA.'
  },
  {
    id: 'EverEvolvingOrganisation',
    icon: 'EverEvolvingOrganisation',
    title: 'Ever Evolving',
    description: 'As attackers and threats evolve, so do we. We safeguard you against the latest trends. We constantly strive to enrich our knowledge base to mitigate any kind of threat now and then.'
  }
];

export const ZSFooterSection1Data = [
  {
    title: 'Products',
    links: [
      { id: 'product1', text: 'Product1', url: '#' },
      { id: 'product2', text: 'Product2', url: '#' },
      { id: 'product3', text: 'Product3', url: '#' },
      { id: 'product4', text: 'Product4', url: '#' }
    ]
  },
  {
    title: 'Services',
    links: [
      { id: 'services1', text: 'Services1', url: '#' },
      { id: 'services2', text: 'Services2', url: '#' },
      { id: 'services3', text: 'Services3', url: '#' },
      { id: 'services4', text: 'Services4', url: '#' }
    ]
  },
  {
    title: 'Company',
    links: [
      { id: 'company1', text: 'Company1', url: '#' },
      { id: 'company2', text: 'Company2', url: '#' },
      { id: 'company3', text: 'Company3', url: '#' },
      { id: 'company4', text: 'Company4', url: '#' }
    ]
  }
];

export const boxes = [
  { id: 'OverallCampus', name: 'OverallCampus', label: 'Your\ncampus' },
  {
    id: 'CriticalSystems',
    name: 'CriticalSystems',
    label: 'Critical\nsystems'
  },
  { id: 'DataCenter', name: 'DataCenter', label: 'Data\ncenters' },
  {
    id: 'CloudInfrastructure',
    name: 'CloudInfrastructure',
    label: 'Cloud\ninfrastructure'
  }
];
