import styled from 'styled-components';

const PartnerStyle = styled.div`
  background: var(--main-dark-bg);
  .contact {
    padding: 4rem 0 !important;
  }
  .partner_form {
    #message {
      @media (min-width: 1200px) {
        height: 11rem;
      }
    }
  }
  .partner_form_title {
    color: #fff;
    font-family: Poppins;
    font-size: 57.126px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.9%; /* 76.492px */

    @media (max-width: 769px) {
      font-size: 47px;
    }

    @media (max-width: 550px) {
      font-size: 35px;
    }
  }
  .contact_header {
    position: relative;
    .contactheader_img {
      width: 100%;
      height: 350px;
      margin-top: -58rem;
    }
    .contactheader_content {
      // transform: translateY(-420px);
      padding: 10px 4%;

      .contactheader_form {
        border-top-left-radius: 9.056px;
        border-bottom-left-radius: 9.056px;
        border: 0px solid #57a1d8;
        background: rgba(0, 0, 0, 0.83);
        box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(21.5px);
        padding: 1rem 0;
        .partnerform {
          @media (max-width: 650px) {
            padding: 0 2rem !important;
          }
        }
      }
      .contactheader_location {
        border-top-right-radius: 9.056px;
        border-bottom-right-radius: 9.056px;
        border: 0px solid #57a1d8;
        background: #000;
        box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
        z-index: 1;
      }
    }
  }
  .contactheader_location_content {
    padding: 0 5%;
    @media screen and (min-width: 1050px) {
      padding: 2rem 3rem !important;
    }
    h3 {
      font-weight: 700;
    }
    .contactheader_locationmainbox {
      width: 100%;
      // height: 175px;
      border-radius: 9.056px;
      border: 0px solid #57a1d8;
      background: rgba(66, 66, 66, 0.51);
      box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
      color: white;
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      @media screen and (max-width: 400px) {
        flex-direction: column;
      }
      .contactheader_locationchildbox1 {
        padding: 1.5rem;
        width: calc(100% - 174px);
        @media screen and (max-width: 450px) {
          padding: 1rem;
        }
        @media screen and (max-width: 400px) {
          order: 2;
          width: 100%;
        }
        .contactheader_locationchildbox1_title {
          font-size: 22.23px;
          font-weight: 700;
          line-height: 133.9%; /* 29.766px */
        }
        .contactheader_locationchildbox1_add {
          font-size: 13.898px;
          font-weight: 400;
          line-height: 134.9%; /* 18.749px */
          text-transform: capitalize;
        }
        .contactheader_locationchildbox1_btn {
          width: 11.5rem;
          height: 41px;
          border-radius: 9.056px;
          border: 0px solid #57a1d8;
          background: #424242;
          box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
          backdrop-filter: blur(21.5px);
          font-size: 12.715px;
          color: white;
          font-weight: 800;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          &:hover {
            background: rgb(61 57 57);
            border: 0px solid rgb(61 57 57);
            transition: 0.3s;
          }
        }
      }
      .contactheader_locationchildbox2 {
        width: 174px;
        height: 175px;
        background: rgba(66, 66, 66, 0.51);
        border-radius: 9.056px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 400px) {
          order: 1;
          width: 90%;
          margin-top: 13px;
        }
        > a {
          @media screen and (max-width: 400px) {
            width: 95%;
          }
        }
        .contactheader_location_img {
          height: 164px;
          width: 162px;
          border-radius: 9.056px;
          @media screen and (max-width: 400px) {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .contactheader_content {
      padding: 0 2.5% !important;
    }
  }

  @media screen and (min-width: 400px) and (max-width: 991px) {
    .contact {
      padding: 1.2rem 0px !important;
    }
    .contactheader_form {
      border-radius: 9.056px !important;
    }
    .contactheader_location {
      border-radius: 9.056px !important;
    }
  }
  @media screen and (min-width: 651px) and (max-width: 991px) {
    .contactheader_img {
      margin-top: -130rem !important;
    }
  }
  @media screen and (min-width: 400px) and (max-width: 650px) {
    .contactheader_locationchildbox1_btn {
      width: 128px !important;
      font-size: 9.5px !important;
    }
  }
  @media screen and (max-width: 650px) {
    .contactheader_img {
      margin-top: -160rem !important;
    }
  }
  @media screen and (min-width: 999px) and (max-width: 1250px) {
    .contactheader_locationchildbox1_btn {
      width: 128px !important;
      font-size: 9.5px !important;
    }
  }
`;

export default PartnerStyle;
