import styled from 'styled-components';

const CompanyMentorStyle = styled.div`
  width: 100%;
  padding: 3.5rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 866px) {
    padding: 3.5rem 1rem;
  }
  .CompanyMentor_title {
    color: #122fba;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.4%; /* 37.248px */
    text-transform: capitalize;
  }
  .mentor_content {
    width: 1050px;
    max-width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    transition: background-color 0.3s ease-out;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .active {
      height: 400px !important;
      transition: height 0.8s ease-out;
    }
    .more_content {
      @media (max-width: 767px) {
        height: auto !important;
      }
    }
    .more_content_second {
      @media (max-width: 767px) {
        height: auto !important;
      }
    }
    .mentor_img {
      height: 145px;
      width: 251px;
      margin-right: 25px;
      @media (max-width: 767px) {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }
    }
    .mentor_details {
      ${'' /* height: 160px; */}
      width: calc(100% - 251px - 25px);
      position: relative;
      border-radius: 5px;
      transition: height 0.8s ease-out;
      @media (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        flex-direction: column;
      }

      @media (max-width: 1199px) {
        height: auto;
      }

      .mentor_linkedin_main {
        position: absolute;
        right: 10px;
        top: 5px;
      }
      .mentor_name {
        color: #293466;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 116.4%; /* 37.248px */
        text-transform: capitalize;
        @media (max-width: 550px) {
          font-size: 22px;
        }
      }
      .mentor_position {
        color: #4061ff;
        font-family: Poppins;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 116.4%;
        text-transform: capitalize;
      }
      .mentor_shortBio {
        color: #848484;
        font-family: Poppins;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 116.4%;
        text-align: justify;
        padding-right: 2rem;

        @media (max-width: 769px) {
          padding-right: unset;
        }
      }
      .mentor_readMore {
        color: #122fb9;
        font-family: Poppins;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 116.4%; /* 19.788px */
        text-transform: capitalize;
        position: absolute;
        right: 5px;
        bottom: 5px;
        cursor: pointer;

        @media (max-width: 1263px) {
          position: inherit;
          text-align: end;
          margin-top: 10px !important;
        }
      }
    }
    .sameColor {
      .mentor_name,
      .mentor_position,
      .mentor_shortBio,
      .mentor_readMore {
        color: #ffffff !important;
      }
    }
    .more_content {
      transition: height 0.8s ease-out;
    }
  }
  .main_more_content {
    border-radius: 9.056px;
    border: 0px solid #57a1d8;
    background-color: #122fba;
    box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(44.5px);
    transition: background-color 0.3s ease-out;
  }
`;

export default CompanyMentorStyle;
