import styled from 'styled-components';

const AttackPreventionStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (min-width: 1000px) {
    max-width: 90% !important;
  }
  @media (min-width: 1700px) {
    max-width: 1400px !important;
  }

  .container-slide-left {
    flex-basis: 38%;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  .container-slide-right {
    flex-basis: 60%;

    @media (max-width: 1000px) {
      margin-bottom: 2rem;
    }
  }
  .container_left_content.container_left_content_1280 {
    padding: 3.5rem 0 5rem;
    @media (max-width: 1000px) {
      padding: 3.5rem 0;
    }
  }

  .containerRightContent {
    /* max-width: 780px; */
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 18px;
    @media (max-width: 1400px) {
      gap: 12px;
    }
    @media (max-width: 769px) {
      gap: 5px;
    }

    .staticImage {
      width: 40%;
      flex: 1;

      &:nth-last-child(2) {
        flex: 1.5;
      }
      @media (max-width: 1400px) {
        width: 30%;
      }
    }
  }

  .attack__prevention__title {
    color: #1b3dde;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 101.9%; /* 58.606px */

    @media (max-width: 600px) {
      font-size: 2.5rem;
    }

    @media (min-width: 1000px) and (max-width: 1400px) {
      font-size: 40px;
    }
  }

  .degree__position {
    position: relative;

    .degree {
      font-size: var(--fs-1);
      position: absolute;
      top: -10px;
    }
  }

  .attack__prevention__tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--fs-1-5);

    .attack__prevention__tabs_dzn {
      margin-right: 0.4rem;
      letter-spacing: 0.1rem;
      color: #a1abb9;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 325.9%; /* 45.626px */
      letter-spacing: 3.78px;
      @media (max-width: 472px) {
        line-height: 30px;
      }
      @media (min-width: 1000px) and (max-width: 1400px) {
        font-size: 10px;
      }
    }
  }
  .container_left_content_btn {
    border-radius: 4.172px;
    border: 2px solid #1b3dde;
    background: transparent;
    width: 160px;
    height: 41px;
    flex-shrink: 0;
    color: #1a3cdb;
    text-align: center;
    font-family: Lato;
    font-size: 12.715px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-right: 7px;
    padding: 0;

    &:hover {
      background: #1b3dde;
      color: #fff;
    }
  }

  @media (max-width: 358px) {
    .attack__prevention__title {
      font-size: 45.513px;
      font-weight: 700;
      line-height: 101.9%; /* 58.606px */
    }
    .container_right_content_btn {
      margin: 10px 0 0 0px;
    }
  }
`;

export default AttackPreventionStyle;
