import React from 'react';
import Icon from '../../../../Icon/ZSHomeIcon/Index';
import { Slide } from 'react-awesome-reveal';

import CompanyValueStyle from './CompanyValueStyle.js';

const CompanyValue = () => {
  const box = [
    'Dependable',
    'Committed',
    'Open-minded',
    'Innovative',
    'Optimistic',
    'Inspiring',
    'Passionate',
    'Respectful',
    'Courageous',
    'Educated',
    'Respected',
    'Dependable',
    'Committed',
    'Open-minded',
    'Innovative',
    'Optimistic',
    'Inspiring',
    'Passionate',
    'Respectful',
    'Courageous',
    'Educated',
    'Respected'
  ];

  return (
    <CompanyValueStyle className="CompanyValue">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div>
          <div className="CompanyValue_title pb-5 mb-4">our Values</div>
          <div className="CompanyValue_slider">
            <div className="CompanyValue_slider_track d-flex">
              {box.map((e) => (
                <div key={e} className="CompanyValue_slider_box d-flex justify-content-center align-items-center bg-light">
                  <Icon name="ourvalue" iconType="Home" width={43} className="mx-2" />
                  <div className="CompanyValue_slider_box_content mx-2 ">{e}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="CompanyValue_slider">
            <div className="CompanyValue_slider_track2 d-flex">
              {box.map((e) => (
                <div key={e} className="CompanyValue_slider_box d-flex justify-content-center align-items-center bg-light">
                  <Icon name="ourvalue" iconType="Home" width={43} className="mx-2" />
                  <div className="CompanyValue_slider_box_content mx-2 ">{e}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Slide>
    </CompanyValueStyle>
  );
};

export default CompanyValue;
