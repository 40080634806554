import styled from 'styled-components';

const ZSProductStyle = styled.div`
  margin-bottom: 4.5rem;

  @media (max-width: 600px) {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    .container {
      max-width: 1200px;
    }
  }

  .Anita-title {
    color: #1230bd;
    text-align: center;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 116.4%; /* 2.328rem */
    text-transform: capitalize;

    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  }
`;

export default ZSProductStyle;
