import React, { useState } from 'react';
import axios from 'axios';
import assessmentexpert from '../../../../Image/ZSServices/assessmentexpert.png';
import ButtonIndex from '../../../../Component/ZSForm/ZSButtons/Index';
import AlertToken from '../../../../Component/AlertTokan/Index';
import EmailModal from '../../../../Component/ZSModal/Lib/EmailModal/Index';
import { Slide } from 'react-awesome-reveal';

const AssessmentExpert = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [sendError, setSendError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setModalVisible(true);
    setEmail('');
    setEmailError('');
    setSendError(false);
    setShowSuccess(false);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleEmailChange = (event) => {
    setSendError(false);
    setShowSuccess(false);
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!isValidEmail(newEmail)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);

    const instance = axios.create({
      baseURL: `https://${window.location.hostname}:5000`,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
    });
    const apiData = { method:'get', url:`/expert?email=${email}` };
    instance(apiData)
      .then((response) => {
        const { status, type } = response.data;
        if (status) {
          if (type === 'expertAssessment') {
            setEmail('');
            setEmailError('');
            setShowSuccess(true);
            setSendError(false);
            closeModal();
            setIsLoading(false);
          }
        }
        return response.data;
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          setIsLoading(false);
          setSendError(true);
          setShowSuccess(false);
          setEmail('');
          closeModal();
        }
        return error.message;
      });
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  return (
    <>
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div
          className="container assesment_expert rounded-2 overflow-hidden"
          style={{ marginBottom: '100px', marginTop: '40px', boxShadow: '0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05)' }}
        >
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 assesment-textWrapper">
              <div className="assesment_title">
                Time to get an expert <br /> assessment?
              </div>
              <div className="assesment_expertPara py-4">
                Uncover the hidden gaps in the existing security operations and detect the attacker's presence in your environment. Speak with our experts now.
              </div>
              <ButtonIndex ButtonType="Dark" text="Book Your Assessment Today" className="assesment_expertbtn" onClick={openModal} />
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 bg-danger p-0">
              <img src={assessmentexpert} style={{ width: '100%', height: '100%' }} alt="Assessment Expert" />
            </div>
          </div>
          {/* Render the DarkModal */}
        </div>
      </Slide>
      <EmailModal
        showModal={modalVisible}
        handleCloseModal={closeModal}
        email={email}
        emailError={emailError}
        handleEmailChange={handleEmailChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
      <AlertToken
        variant="success"
        show={showSuccess}
        onClose={closeModal}
        duration={10000}
        message={
          <>
            Thank you for opting expert assessment! <br />
            <br />
            Hello there, <br />
            <br />
            Thank you for expressing interest in an expert assessment.
            <br />
            <br />
            A member of our A member of our team will reach out to you via email to coordinate the details and set up the meeting. If you have any specific preferences or topics you'd like covered
            during the session, please be sure to let us know. We're committed to providing you with a comprehensive assessment.
            <br />
            <br />
            Warm regards, <br />
            <br />
            Team Zeronsec
          </>
        }
      />
      <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." onClose={closeModal} duration={10000} />
    </>
  );
};

export default AssessmentExpert;
