import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../Icon/Index';
import ProtectBusinessStyle from './ZSProtectBusinessStyle';
import { boxes } from '../../HomeData/Index';
import { Slide } from 'react-awesome-reveal';

const ProtectBusiness = () => {
  return (
    <ProtectBusinessStyle className="container-fluid">
      <Slide className="protectbusiness__slide" direction="right" triggerOnce duration={1500} delay={100}>
        <div className="container protectbusiness__slide_wrapper">
          <div className="ProtectBusinessAnimation">
            <div className="container_left_content container_left_content_1280">
              <div className="protectbusiness__content">
                <p className="mb-0 protectbusiness__title"> Protect your business </p>
                <p className="mb-0 protectbusiness__para pb-4">
                  {' '}
                  with our advanced technologies and services to detect, analyze, and <br /> remediate complex cyber attacks across your entire organization including{' '}
                </p>
              </div>
              <div className="protectbusiness__box">
                {boxes.map((box) => (
                  <div className={`protectbusiness__box__child ${box.name === 'CloudInfrastructure' ? 'cloud-infra-margin' : ''}`} key={box.name} id={box.id}>
                    <Icon className="protectbusiness__box__child_icon" name={box.name} iconType="Home" width={30} />
                    <p className="mb-0 protectbusiness__box__child__label">{box.label}</p>
                  </div>
                ))}
              </div>
              <div className="protectbusiness__box__footer">
                <div className="scroll_d">
                  {' '}
                  <Icon iconType="Home" name="scrolldown" width={15} />{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </ProtectBusinessStyle>
  );
};

ProtectBusiness.propTypes = {
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

ProtectBusiness.defaultProps = {
  boxes: []
};

export default ProtectBusiness;
