import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';

const StyledNavbar = styled(Navbar)`
  position: sticky;
  top: 0;
  left: 0;
  background: var(--main-light-bg);
  z-index: var(--z-index-11);
  padding: 1.5rem 0;

  @media (max-width: 600px) {
    padding: 0.5rem 0;
  }

  .navbar-toggler {
    background: white;
  }

  .dropdown-menu {
    padding: 0 !important;
    background: transparent !important;
    border: none;
    color: white !important;
    font-size: 14px;
  }

  .dropdown-menu a {
    color: white !important;
    text-decoration: none;
    transition: all 0.5s ease;
  }

  .secondnav_label {
    svg {
      path {
        fill: white;
      }
    }
  }

  .secondnav_btn {
    background: var(--main-light-bg);
    color: var(--main-dark-bg);
  }

  .secondnav_btn:hover {
    border: 1px solid white;
    color: var(--main-light-bg);
  }

  .secondnav_menu {
    color: white !important;
    margin: 2px 25px 10px 25px;

    @media (max-width: 900px) {
      margin-left: 0;
    }
  }

  @media (max-width: 900px) {
    .navbar-nav {
      width: 100%;

      &.mr-auto {
        margin-top: 2rem;
      }

      a {
        font-size: 1rem;
        margin-left: 0;
        border-bottom: 1px solid #fff;
        margin-bottom: 1rem;
      }

      .dropdown-menu {
        a {
          border-bottom: none;
        }
      }
    }
  }

  .navbar-nav a {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    background: var(--main-dark-bg);
  }
  .smallsize_nav {
    display: none;
  }

  @media (max-width: 767px) {
    .bigsize_nav {
      display: none !important;
    }
    .smallsize_nav {
      display: block;
    }
  }

  .navbar-nav .nav-link::after {
    display: none;
  }

  .dropdown_icon {
    margin-left: 10px;
    transition: all 0.5s ease-in;
  }

  .dropdown-toggle {
    color: black;
  }

  /* Header.css */

  .service-box {
    position: absolute;
    top: 6.75rem;
    /* left: 40%;
    width: 50%; */
    left: 26%;
    width: 70%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition:
      max-height 0.3s ease-out,
      opacity 0.3s ease-out;
  }

  .service-box.visible {
    opacity: 1;
    max-height: 450px; /* Adjust the max height to fit your content */
    transition:
      max-height 0.3s ease-in,
      opacity 0.3s ease-in;
    ${'' /* overflow: auto; */}
  }
  .service-box.visible::-webkit-scrollbar {
    width: 0; /* Set the width of the scrollbar to 0 to hide it */
  }
  .service-box-child {
    height: 100px;
    border-left: 1px solid rgba(0, 0, 0, 0.18);
    text-decoration: none;
    color: #1230bd !important;
    font-weight: 600;
    cursor: pointer;
    padding: 0.75rem 1rem;
    display: flex;
    :hover {
      background-color: #f2f2f2;
    }
    :first-child {
      border: none;
    }

    /* padding: 2%; */

    @media (min-width: 1100px) {
      .serviceMenuItems {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .serviceMenuText {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 1;
          max-width: 130px;
        }

        .serviceMenuIcons {
          svg,
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .product-box {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 4px;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); */
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition:
      max-height 0.3s ease-out,
      opacity 0.3s ease-out;
  }

  .product-box-content-logo {
    width: 130px;
    flex-shrink: 0;
    margin-left: 1rem;
    margin-bottom: 1.1rem;
  }

  .product-box-content-img {
    margin-top: 15px;
    width: 170px;
  }

  .product-box.visible {
    opacity: 1;
    max-height: 450px; /* Adjust the max height to fit your content */
    transition:
      max-height 0.3s ease-in,
      opacity 0.3s ease-in;
    ${'' /* overflow: auto; */}
  }
  .product-box.visible::-webkit-scrollbar {
    width: 0; /* Set the width of the scrollbar to 0 to hide it */
  }

  .product-box-content {
    color: #ffffff;
    padding-left: 1.7rem;
    min-height: 100%;

    a {
      margin-top: auto;
    }
  }

  .Product-arrowicon {
    margin-left: 10px;
  }

  .product-box-child {
    border-right: 1px solid #494949;
    padding: 3rem 2%;
    background-color: #000000d9;
    margin-bottom: 5px;
    transition: all 300ms;

    :last-child {
      border-right: none;
    }

    &:hover {
      background: var(--main-dark-bg);
      margin-bottom: 0 !important;
      .product-box-child-btn {
        color: var(--main-dark-bg);
        background-color: white !important;
      }
    }

    @media (max-width: 1369px) {
      padding: 3rem 1%;
    }

    &:nth-child(2) {
      .product-box-content {
        .product-box-content-logo {
          transform: scale(0.9);
        }
      }
    }
  }

  .product-box-content-title {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0.6rem;
    line-height: 1.1;
    margin-top: 1.7rem;
  }

  .product-box-content-para {
    color: #fff;
    font-family: Poppins;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 114.9%;
  }

  .product-box-child-btn {
    background: gray;
    border: 1px solid gray;
    color: #ffffff;
    text-align: center;
    font-family: Lato;
    font-size: 0.6em;
    font-weight: 500;
    padding: 0.4rem 1.3rem;
  }

  .company-box {
    position: absolute;
    top: 6.75rem;
    left: 38%;
    width: 50%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    text-align: center;
    transition:
      max-height 0.3s ease-out,
      opacity 0.3s ease-out;

    a {
      color: var(--main-dark-bg) !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 1;
      padding: 1rem;
      border-left: 1px solid rgba(0, 0, 0, 0.18);

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
  .company-box.visible {
    opacity: 1;
    min-height: 65px;
    transition:
      max-height 0.3s ease-in,
      opacity 0.3s ease-in;
    ${'' /* overflow: auto; */}
  }

  @media screen and (max-width: 1280px) {
    .product-box-child {
      ${'' /* flex-direction:column */}
    }
    .product-box-content-title {
      font-size: 14px;
    }

    .product-box-content-para {
      font-weight: var(--fw-300);
      font-size: 12px;
    }

    .product-box-child-btn {
      width: 110px;
      font-size: 12px;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 1100px) {
    .product-box-content-para {
      display: none;
    }

    .product-box-child-btn {
      width: 110px;
      font-size: 12px;
      color: #ffffff;
    }
    .product-box-content-img {
      display: none;
    }
    .product-box-content-logo {
      width: 120px;
    }
  }

  & .navbar-brand {
    font-size: var(--fs-22);
    font-weight: var(--fw-700);
    color: var(--main-dark-clr);

    &:hover {
      color: var(--main-dark-clr);
    }
  }

  & .navbar-toggle {
    justify-content: center;
  }

  & .navbar-collapse {
    justify-content: end;
  }

  & .nav-link {
    margin: 0 25px;
    font-size: 16px;
    font-weight: var(--fw-500);
    opacity: 0.8;
    align-items: center !important;
    color: #000 !important;

    &:hover {
      color: var(--main-dark-clr) !important;
    }

    &.active {
      // color: var(--main-dark-clr) !important;
      transition: all 0.5s ease-in;
    }

    @media (max-width: 1025px) {
      font-size: 0.9rem;
    }

    @media (max-width: 992px) {
      font-size: 0.6rem;
    }
  }
`;

export default StyledNavbar;
