import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --dark:#000;
    --light:#fff;
    --main-dark-bg: #1230BD;
    --main-light-bg: #f5f5f5;
    --main-dark-clr:#1230BD ;
    --main-light-clr:#fff;
    --blue-light-clr:#1b3ddf;
    --blue-light-border:1px solid #1b3ddf;
    --light-border:1px solid #f5f5f5;
    --light-gray:rgba(128, 128, 128, 0.585);
    --fs-14:14px;
    --title-1:2.5rem;
    --title-2:2rem;
    --title-3:1.8rem;
    --title-2-light:2rem;
    --fw-300:300;
    --fw-600:600;
    --fw-700:700;
    --fs-1:1rem;
    --fs-1-1:1.1rem;
    --fs-1-5:1.5rem;
    --fs-2:2rem;
    --5-radius:5px;
    --container-p:6% 1%;
    --pb-2:2.5rem;
    --footer-dark-clr:#1b3ddf;
    --z-index-1: 1;
    --z-index-11: 11;
    --f-poppins:"Poppins", sans-serif;
    --fs-12:12px;
    --fs-18:18px;
    --fs-22:22px;
    --fs-24:24px;
    --fs-26:26px;
    --fs-30:30px;
    --fs-35:35px;
    --fw-800:800;
    --fw-500:500;
    --fw-400:400;
    --dark-border:1px solid #1A3CDB;
    --light-border:1px solid #f5f5f5;
    --3-radius:3px;

    --mb-10:10px;
    --padding-12:12px;
  }



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "poppins", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
}

.stop-scrolling {
  overflow-y: hidden;

  #contentData {
    pointer-events: none;
  }

  @media (max-width: 1023px) {
    overflow-y: auto;

    #contentData {
      pointer-events: all;
    }
  }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

    body::-webkit-scrollbar-track {
    background-color: rgba(128, 128, 128, 0.087);
    height: 10px;
}

    body::-webkit-scrollbar-thumb {
    background-color: #1A3CDB;
    border-radius: 10px;
    height: 6px;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #1231bde8;
}

body::-webkit-scrollbar {
    width: 10px;
}

.Anita-title{
  font-size: 2.5rem;
  font-weight: var(--fw-700);
  color: var(--main-dark-clr);
  line-height:50px;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn .8s ease-in-out;
}


.assesment_expert{
  background:#fff;

  @media (max-width: 850px) {
    margin-bottom: 2rem !important;
  }
  @media (min-width: 1400px) {
    max-width: 85%;
  }
}

.assesment-textWrapper {
  margin: auto;
  padding: 5rem;

  @media (max-width: 850px) {
    padding: 3rem;
  }
}

.assesment_title {
  color: #1230BD;
  font-family: Poppins;
  font-size: 48.432px;
  font-style: normal;
  font-weight: 700;
  line-height: 99.9%; /* 48.384px */
  text-transform: capitalize;
  @media (max-width: 850px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: 550px) {
    font-size: 26px;
    line-height: 1.1;

  }
  @media (min-width: 1000px) and (max-width: 1400px) {
      font-size: 45.432px;
  }
}
.assesment_expertPara{
  color: #000;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.9%; /* 25.631px */
  @media (min-width: 1000px) {
    max-width: 570px;
  }
  @media (max-width: 550px) {
    font-size: 16px;
  }
}

.assesment_expertbtn{
  width:220px !important;
  text-transform: capitalize;
}

// product page

/* Keyframes for the animations */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(350px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveFromCenter {
  0% {
    transform: translatey(350px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.form-label{
  color:white !important;
}

.companyabout_imgslider {
  height: 250px;
  margin: auto;
  position: relative;
  //  width:90%;
  display: grid;
  place-items: center;
  overflow: hidden;

  &::before,
  &::after {
    background: linear-gradient(
      to right,
      rgb(29 62 188) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
  }

  &::before {
    left: 0;
    top: 0;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .companyabout_imgslider_track {
    display: flex;
    width: calc(400px * 8);
    animation: scroll 20s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-400px * 4));
    }
  }

  .companyabout_imgslider_slide {
    height: 250px;
    width: 400px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;

    .companyabout_imgslider_box {
      width: 400px;
      height: 100%;
      transition: transform 1s;
      img {
        width: 100% !important;
        height: 100%;
      }
      &:hover {
        transform: translateZ(10px);
      }
    }
  }
}
.assessment_btn{
  width: 200px;
}

.Email_valid{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.close{
  background:none;
  color:#fff;
  border:none;
  font-size:28px;
  position:absolute;
  right:8px;
  top:-24px;
}

@media screen and (min-height: 820px) {
  .companyabout_imgslider {
    height: 320px !important;
  }
  .companyabout_imgslider_slide {
    height: 320px !important;
    width: 450px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .companyabout_imgslider {
    height: 220px !important;
  }
  .companyabout_imgslider_slide {
    height: 220px !important;
    width: 350px !important;
  }
}
@media screen and (min-width: 651px) and (max-width: 999px) {
  .companyabout_imgslider {
    height: 220px !important;
  }
  .companyabout_imgslider_slide {
    height: 220px !important;
    width: 350px !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 650px) {
  .companyabout_imgslider {
    height: 200px !important;
  }
  .companyabout_imgslider_slide {
    height: 200px !important;
    width: 300px !important;
  }
}
.darkmodal_closeBtn{
  border:none;
  background:none;
  font-size:35px;
  color:#fff;
  position:absolute;
  top:0;
  right:20px;
}
.form-label {
  color: #FFF;
  text-align: justify;
  font-family: Poppins;
  font-size: 13.898px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.9%; /* 18.749px */
  text-transform: capitalize;
}
 label {
  color: #FFF;
  text-align: justify;
  font-family: Poppins;
  font-size: 13.898px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.9%; /* 18.749px */
}
.fixed-textarea {
  resize: none;
}

.form-control:focus {
  border-color: #7089FF !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.25) !important;
  outline: 0 !important;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff; /* Use your preferred loading spinner color */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite; /* Apply the spin animation */
  margin-left:10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.download_btn{
  display:flex;
  justify-content:center;
  align-items:center;
}

.image{
    background-color: #E6E8F3;
    max-width: 372px;
    max-height: 423px;
  }

`;

export default GlobalStyle;
