/* eslint-disable space-before-function-paren */
/* eslint-disable array-bracket-spacing */
import * as d3 from 'd3';
import location1 from './location.svg';

export const replaceAllFunction = (value) => {
  return value.split('/').join('_');
};

export const createchart = ({ svgWidth, svgHeight, selectedTimeZone, mapCreate }) => {
  d3.select('#Map-container').append('div').attr('class', 'mapTooltip').style('position', 'absolute').style('display', 'none').style('width', '278px').style('padding', '1.5rem 2rem');

  const projection = d3.geoEquirectangular().rotate([10, 0]);
  // where world split occurs
  const path = d3.geoPath().projection(projection);

  const svg = d3
    .select('#Map-container')
    .append('svg')
    .attr('viewBox', `0 0  ${svgWidth - 50} ${svgHeight}`);

  const defs = svg.append('defs');

  // Append the provided linearGradient
  const providedGradient = defs
    .append('linearGradient')
    .attr('id', 'paint0_linear_1503_877') // Use the provided ID
    .attr('x1', '948')
    .attr('y1', '100') // Adjust these values
    .attr('x2', '948')
    .attr('y2', '900') // Adjust these values
    .attr('gradientUnits', 'userSpaceOnUse');

  providedGradient
    .append('stop')
    .attr('offset', '') // Add this offset
    .attr('stop-color', '#143DFF')
    .attr('stop-opacity', '0.70');

  providedGradient.append('stop').attr('offset', '1').attr('stop-color', '#200E47').attr('stop-opacity', '0.38');

  const g = svg.append('g');
  const topology = mapCreate;
  g.selectAll('path').data(topology.features).enter().append('path').attr('d', path).attr('class', 'path').style('fill', 'url(#paint0_linear_1503_877)');

  g.selectAll('.country')
    .data([...selectedTimeZone]) // draw each point
    .enter()
    .append('svg:image')
    .attr('class', 'country')
    .attr('xlink:href', location1) // location icon
    .attr('x', (d) => projection([d.lng, d.lat])[0] - 13) // project coordinates into the projection
    .attr('y', (d) => projection([d.lng, d.lat])[1] - 27)
    .attr('width', 27)
    .attr('height', 27)
    .on('click', (e, d) => {
      selectedTimeZone.forEach((element) => {
        if (JSON.stringify(element) === JSON.stringify(d)) {
          element.show = !d.show;
        } else {
          element.show = false;
        }
      });

      const mobileScreen = window.innerWidth < 600;
      const toolTipTopCSS = d.countryName === 'USA' ? `${e.pageY + 40}px` : d.countryName === 'Germany' ? `${e.pageY - 110}px` : `${e.pageY - 40}px`;
      const toolTipLeftCSS = d.countryName === 'USA' ? `${e.pageX - 146}px` : d.countryName === 'Germany' ? `${e.pageX + 30}px` : `${e.pageX - 300}px`;

      // tooltip position for mobile  screens
      const toolTipMobileView = d.countryName === 'USA' ? `translate(80px, -20px)` : d.countryName === 'Germany' ? `translate(-10px, 20px)` : `translate(160px, 30px)`;
      d3
        .selectAll('.mapTooltip')
        .style('display', d.show ? 'block' : 'none')
        .style('top', toolTipTopCSS)
        .style('transform', mobileScreen ? toolTipMobileView : '')
        .style('left', toolTipLeftCSS).html(`<div>
                    <div class="title">${d.countryName}</div>
                    <div class="address">
                      ${d.address}
                    </div>
                    <div class="support">
                      <span class="supportText"><a href="tel:${d.tel}" target="_blank" rel="noreferrer">Call Us</a></span>
                    </div>
                  </div>`);
    });
};
