import ZSHomeIcon from './ZSHomeIcon/Index';
import ZSServiceIcon from './ZSServiceIcon/Index';
import ZSProductIcon from './ProductIcon/Index';

const ICON_COMPONENTS = {
  Home: ZSHomeIcon,
  Service: ZSServiceIcon,
  product: ZSProductIcon
  // add more icon types here
};

const Icon = ({ iconType, ...props }) => {
  const IconComponent = ICON_COMPONENTS[iconType] || ZSHomeIcon;

  return (
    <>
      <IconComponent {...props} />
    </>
  );
};

export default Icon;
