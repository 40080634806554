import HomeRoter from '../../../Router/Index';
import ZSContentStyle from './ZSContentStyle';

const Content = () => {
  return (
    <ZSContentStyle id="contentData">
      <HomeRoter />
    </ZSContentStyle>
  );
};

export default Content;
