import styled from 'styled-components';

const ZSHomeSliderStyle = styled.div`
  .Center__title {
    margin-bottom: 2rem;
  }

  .nav-tabs .nav-link {
    width: 300px;
    font-size: 1.25rem;
    color: #6b6b6b;
    font-weight: var(--fw-700);
    line-height: 1.3;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }

  .nav-tabs {
    border-bottom: 1px solid blue !important;
  }

  .nav-link {
    border: none !important;
    background: transparent !important;
    border: 0 0 6px 0 solid black !important;
    position: relative !important;
    transition: all 0.5s ease;
  }

  .nav-tabs .nav-link.active {
    color: var(--main-dark-clr);
    // border-bottom: 10px solid blue !important;
    border-radius: px;
  }
  .ZsHomeTabKey1_content {
    background: white;
    border-radius: 10px;
    color: var(--main-dark-clr);
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    padding: 3.6rem 2rem 3rem 2rem;
    line-height: 1.3;
    transition: all 0.3s ease-out;
    box-shadow: 0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05);

    &:hover {
      background: var(--main-dark-clr);
      transform: scale(1.1);
      color: white;
      font-weight: 700;
      cursor: pointer;
    }

    @media screen and (min-width: 1100px) {
      min-height: 250px;
    }

    @media (max-width: 600px) {
      width: 43% !important ;
      font-size: 0.8rem;
      padding: 1.1rem 1rem 1rem;
    }
  }

  .activeBottom_border {
    height: 12px;
    background: var(--main-dark-clr);
    position: relative;
    top: 12px;
    border-radius: 19px;
  }

  .container_left_content_btn {
    padding: 0.6rem 2.5rem;
  }
`;

export default ZSHomeSliderStyle;
