import styled from 'styled-components';

const TermsPolicyStyle = styled.div`
a {
    color: blue !important;
    font-weight: 600;
}
`;

export default TermsPolicyStyle;
