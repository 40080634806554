import CompanyAbout from './Lib/CompanyAbout/Index';
import CompanyTitle from './Lib/CompanyTitle/Index';
import CompanyValue from './Lib/CompanyValue/Index';
import CompanyMission from './Lib/CompanyMission/Index';
import ZSSecuirityPartner from '../../Page/ZSHome/Lib/ZSSecurityPartner/Index';
import ZSTrustedIndustryLeaders from '../../Page/ZSHome/Lib/ZSTrustedIndustryLeaders/Index';
import CompanyLeader from './Lib/CompanyLeader/Index';
import CompanyMentor from './Lib/CompanyMentor/Index';
import AssessmentExpert from '../ZSServices/Lib/AssessmentExpert/Index';
import ZSEnsureOrganization from '../../Page/ZSHome/Lib/ZSEnsureOrganization/Index';

import './company.scss';

const Company = () => {
  return (
    <div className="company">
      <CompanyTitle />
      <CompanyAbout />
      <CompanyValue />
      <CompanyMission />
      <ZSSecuirityPartner />
      <ZSTrustedIndustryLeaders pyClass="py-0" myClass="my-0" />
      <CompanyLeader />
      <CompanyMentor />
      <AssessmentExpert />
      <ZSEnsureOrganization pyClass="py-0" />
    </div>
  );
};

export default Company;
