import styled from 'styled-components';

const ZSCyberDefensesBoxContent = styled.div`
  position: relative;
  padding: 1.4rem 2.56rem;
  background: var(--light);
  border-radius: 10px;
  font-size: 1.25rem;
  font-weight: var(--fw-500);
  color: var(--main-dark-clr);
  fill: #1230bd;
  transition: all 0.2s ease;

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: 1.1rem;
    padding: 1.4rem 2.3rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 1.4rem 2rem;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    padding: 15px !important;
    height: 97px !important;
  }

  &:hover {
    background: var(--main-dark-bg);
    color: var(--light);
    cursor: pointer;
    fill: gold;
  }

  &.active {
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  @media (max-width: 1024px) {
    font-size: 1rem;
  }

  .CyberDefenses__box__content__icon {
    position: absolute;
    top: 15%;
    right: 6%;
    width: 24px;
    height: 24px;
  }

  .CyberDefenses__box__content__lable {
    margin-top: 5%;
    white-space: pre-wrap;
  }
`;

export default ZSCyberDefensesBoxContent;
