import styled from 'styled-components';

const ZeronsecLoader = () => (
  <ZSContainerStyle>
    <svg width="102" height="101" viewBox="0 0 102 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.0733 23.3889C36.2838 22.6933 24.5412 33.3081 23.8456 47.0977C23.4686 54.5725 27.3413 62.93 32.3151 67.7684L17.8017 84.0581C7.96028 74.7467 0.138816 60.5309 0.874851 45.9391C2.21034 19.463 24.756 -0.917372 51.232 0.418118C62.569 0.98997 72.7883 5.45077 80.6636 12.4438L66.2895 29.4313C62.2746 26.0863 55.6942 23.6724 50.0733 23.3889Z"
        fill="#1A3CDB"
      />
      <path
        d="M77.6107 47.6584C80.1027 61.2387 71.1139 74.268 57.5335 76.76C50.1722 78.1109 41.3801 75.3639 35.9331 71.0652L21.6802 87.5833C32.1982 96.1232 47.3143 102.019 61.6848 99.3823C87.7591 94.5976 105.018 69.5814 100.233 43.5071C98.1841 32.3421 92.4258 22.7936 84.4633 15.8999L69.5005 32.3711C73.3415 35.9143 76.5949 42.1228 77.6107 47.6584Z"
        fill="#1D1D1D"
      />
    </svg>
  </ZSContainerStyle>
);

const ZSContainerStyle = styled.div`
  svg {
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
`;

export default ZeronsecLoader;
