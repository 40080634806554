/* eslint-disable react/no-unknown-property */
const ZSHomeIcon = (props) => {
  const { name, color, width, height, className, style } = props;
  const renderIcon = () => {
    switch (name) {
      case 'serviceHeaderIcon':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44 20" fill="none" className={className}>
            <path
              d="M40.9077 16.3633H2.72732C1.22341 16.3633 0 15.14 0 13.636V6.36356C0 4.85964 1.22332 3.63623 2.72732 3.63623H40.9085C42.4124 3.63623 43.6358 4.85956 43.6358 6.36356V13.636C43.6355 15.1399 42.4122 16.3633 40.9083 16.3633H40.9077ZM2.72732 5.4545C2.22579 5.4545 1.81819 5.86239 1.81819 6.36364V13.6361C1.81819 14.1373 2.22575 14.5452 2.72732 14.5452H40.9085C41.4101 14.5452 41.8177 14.1373 41.8177 13.6361V6.36364C41.8177 5.86244 41.4101 5.4545 40.9085 5.4545H2.72732Z"
              fill="#1230BD"
            />
            <path
              d="M40.0534 5.45465H3.69043V4.23656C3.69043 1.90032 5.59109 0 7.92699 0H35.8181C38.1537 0 40.0537 1.90032 40.0537 4.23656L40.0534 5.45465ZM5.58383 3.6368H38.16C37.8929 2.59285 36.9439 1.81861 35.8181 1.81861H7.92699C6.80057 1.81861 5.85159 2.59251 5.58417 3.6368H5.58383Z"
              fill="#1230BD"
            />
            <path
              d="M35.8177 20.0001H7.9265C5.59094 20.0001 3.68994 18.0997 3.68994 15.7635V14.5454H40.0529V15.7635C40.0532 18.0997 38.1532 20.0001 35.8177 20.0001ZM5.58402 16.3637C5.85111 17.4076 6.80008 18.1819 7.92684 18.1819H35.818C36.9437 18.1819 37.8927 17.4076 38.1598 16.3637H5.58402Z"
              fill="#1230BD"
            />
            <path
              d="M6.41776 10.9096H4.59957C4.09704 10.9096 3.69043 10.5027 3.69043 10.0004C3.69043 9.49821 4.097 9.09131 4.59957 9.09131H6.41776C6.92028 9.09131 7.32689 9.49821 7.32689 10.0004C7.32689 10.5023 6.92032 10.9096 6.41776 10.9096Z"
              fill="#1230BD"
            />
            <path
              d="M11.8567 10.9096H10.0385C9.53601 10.9096 9.12939 10.5027 9.12939 10.0004C9.12939 9.49821 9.53597 9.09131 10.0385 9.09131H11.8567C12.3592 9.09131 12.7659 9.49821 12.7659 10.0004C12.7659 10.5023 12.3593 10.9096 11.8567 10.9096Z"
              fill="#1230BD"
            />
            <path
              d="M17.3269 10.9096H15.5087C15.0062 10.9096 14.5996 10.5027 14.5996 10.0004C14.5996 9.49821 15.0062 9.09131 15.5087 9.09131H17.3269C17.8295 9.09131 18.2361 9.49821 18.2361 10.0004C18.2361 10.5023 17.8295 10.9096 17.3269 10.9096Z"
              fill="#1230BD"
            />
            <path
              d="M22.7268 10.9096H20.9086C20.4061 10.9096 19.9995 10.5027 19.9995 10.0004C19.9995 9.49821 20.4061 9.09131 20.9086 9.09131H22.7268C23.2294 9.09131 23.636 9.49821 23.636 10.0004C23.636 10.5023 23.2294 10.9096 22.7268 10.9096Z"
              fill="#1230BD"
            />
            <path
              d="M35.5086 12.7276C34.0047 12.7276 32.7812 11.5043 32.7812 10.0003C32.7812 8.49628 34.0046 7.27295 35.5086 7.27295C37.0126 7.27295 38.2359 8.49628 38.2359 10.0003C38.2359 11.5043 37.0126 12.7276 35.5086 12.7276ZM35.5086 9.09122C35.007 9.09122 34.5994 9.49911 34.5994 10.0004C34.5994 10.5016 35.007 10.9095 35.5086 10.9095C36.0102 10.9095 36.4177 10.5016 36.4177 10.0004C36.4177 9.49911 36.0102 9.09122 35.5086 9.09122Z"
              fill="#1230BD"
            />
          </svg>
        );
      default:
        return null;
    }
  };

  return <>{renderIcon()}</>;
};

export default ZSHomeIcon;
