import styled from 'styled-components';

const ZSAssesmentServicesStyle = styled.div`
  animation: moveFromCenter 2s ease;

  .assesmentDark_card {
    width: 1600px !important;
    max-width: 100%;
  }
  .assesment_card_main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 25px;
    padding: 5% 3%;

    @media (max-width: 554px) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .assesmentDark_card_bg {
      background: var(--main-dark-bg);
      box-shadow: 0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05);
      // margin:0 10px;

      padding: 4.5rem 3rem 3rem 4.5rem;
      @media (max-width: 550px) {
        padding: 2rem;
      }

      .assesmentDark_card_title {
        color: #f5f5f5;
        font-family: Poppins;
        font-size: 48.432px;
        font-style: normal;
        font-weight: 700;
        line-height: 116.4%; /* 56.375px */
        text-transform: capitalize;
      }
      .assesmentDark_card_para {
        color: #fff;
        font-family: Poppins;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.9%; /* 25.631px */
        height: 300px;
        @media (max-width: 1705px) {
          height: 300px;
        }
        @media (max-width: 1399px) {
          height: 340px;
        }
        @media (max-width: 1225px) {
          height: 380px;
        }
        @media (max-width: 1109px) {
          height: auto;
        }
        @media (max-width: 550px) {
          font-size: 17px;
        }
      }
      @media (max-width: 850px) {
        .assesmentDark_card_title {
          font-size: 30px;
          line-height: 40px;
        }
      }
      @media (max-width: 550px) {
        .assesmentDark_card_title {
          font-size: 24px;
          line-height: 30px;
        }
      }
      .knowMoreBtn {
        padding: 10px 35px;
      }
    }

    .assesmentLight_card_bg {
      background: white;
      margin: 0 15px;
      /* height: 410px; */
      box-shadow: 0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05);
      border-radius: 0.5rem;
      padding: 3rem 1.75rem 0;
      min-height: 480px;
      @media (min-width: 1399px) {
        min-height: 440px;
      }
      @media (max-width: 1400px) {
        min-height: 460px;
      }
      @media (max-width: 1100px) {
        height: auto;
        min-height: unset;
        padding: 3rem 1rem 1rem;
      }
    }

    .assesmentLight_card_points {
      margin: 0 0px 0 50px;
      color: #1230bd;
      font-family: Poppins;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 134.9%;
      @media (max-width: 550px) {
        font-size: 1rem;
        margin: 0 0px 0 20px;
      }
    }

    .assesmentLight_card_index {
      position: unset;
      min-width: 32px;
      height: 32px;
      top: 2px;
      text-align: center;
      border-radius: 50%;
      background: var(--main-dark-bg);
      color: white;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      margin-bottom: 0;
    }
  }
`;
export default ZSAssesmentServicesStyle;
