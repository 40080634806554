import Icon from '../../../Icon/Index';

import ZSSecurityPartnerBox from './ZSLightBigCardStyle';

const ZSLightBigCard = (props) => {
  const { icon, title, description } = props;

  return (
    <ZSSecurityPartnerBox
      style={{
        height: title === 'Hi-tech Brains' ? '45%' : title === 'Ever Evolving' ? 'unset' : 'auto'
      }}
    >
      <div className="SecurityPartner_box_box1_icon radius_box">
        <Icon name={icon} iconType="Home" color="#1230bd" width={45} />
      </div>

      <div className="SecurityPartner_box_box1_title">{title}</div>

      <div
        className="SecurityPartner_box_box1_para"
        style={{
          marginBottom: title === 'Hi-tech Brains' ? '40px' : 'unset'
        }}
      >
        {description}
      </div>
    </ZSSecurityPartnerBox>
  );
};

export default ZSLightBigCard;
