/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import CompanyLeaderStyle from './CompanyLeaderStyle.js';
import Icon from '../../../../Icon/ZSHomeIcon/Index';
import { leaders } from '../../CompanyData/Index.js';
import { Slide } from 'react-awesome-reveal';

const CompanyLeader = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const spacingFromTop = 100;
        element.style.marginTop = `${spacingFromTop}px`;
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <CompanyLeaderStyle className="container py-5" id="ourLeadership">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="CompanyLeader py-5">
          <div className="Companyleader_title pb-3">Our Leadership</div>
          <div className="profile_card_main">
            {leaders.map((leader, index) => (
              <div key={index} className="profile_card_childbox">
                <div className="profile_box">
                  <div className="profile_inner_box1">
                    <img src={leader.profileImage} alt={`${leader.name} profile`} />
                    <a href={leader.url} target="_blank" className="profilecard_icon">
                      <Icon name="linkedindark" iconType="Home" width={27} />
                    </a>
                  </div>
                  <div className="profile_inner_box2">
                    <p className="profile_name p-0 m-0">{leader.name}</p>
                    <p className="profile_post p-0 m-0">{leader.position}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Slide>
    </CompanyLeaderStyle>
  );
};

export default CompanyLeader;
