import React from 'react';
import ZsCarousel from '../../../../Component/ZsCarousel/index.js';
import Icon from '../../../../Icon/Index.js';
import { anrita, ekasha } from '../../ProductData/Index.js';
import ProductBenifitsStyle from './ProductBenifitsStyle.js';
import { Slide } from 'react-awesome-reveal';

const ProductBenifits = (props) => {
  const { productData } = props;

  return (
    <ProductBenifitsStyle className="container">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="ProductBenifits py-5">
          {/* <div className=" AnritaBenifits "></div> */}
          <div className="Anita-title text-center pb-5">Benefits</div>
          {productData?.product === 'anrita' && (
            <ZsCarousel
              nav={true}
              dots={true}
              center={false}
              autoplay={true}
              margin={20}
              responsive={{
                1: {
                  items: 1
                },
                900: {
                  items: 1.5
                },
                1200: {
                  items: 2
                },
                1370: {
                  items: 2
                },
                1550: {
                  items: 2.2
                }
              }}
            >
              {anrita.map((d) => (
                <div key={d.name} className="d-flex flex-column align-items-start mainCard">
                  <div className="wrapHeader">
                    <div className="header">{d.name}</div>
                    <div className="headerIcon">
                      <Icon name={d.name} iconType="Home" />
                    </div>
                  </div>
                  <div className="description">{d.desc}</div>
                </div>
              ))}
            </ZsCarousel>
          )}
          {productData?.product === 'ekasha' && (
            <ZsCarousel
              nav={true}
              dots={true}
              center={false}
              autoplay={true}
              margin={20}
              responsive={{
                1: {
                  items: 1
                },
                900: {
                  items: 1.5
                },
                1200: {
                  items: 2
                },
                1370: {
                  items: 2
                },
                1550: {
                  items: 2.2
                }
              }}
            >
              {productData?.product === 'ekasha' &&
                ekasha.map((d) => (
                  <div key={d.name} className="d-flex flex-column align-items-start mainCard ekasha-mainCard">
                    <div className="wrapHeader">
                      <div className="header">{d.name}</div>
                      <div className="headerIcon">
                        <Icon name={d.name} iconType="Home" />
                      </div>
                    </div>
                    <div className="description">{d.desc}</div>
                  </div>
                ))}
            </ZsCarousel>
          )}
        </div>
      </Slide>
    </ProductBenifitsStyle>
  );
};

export default ProductBenifits;
