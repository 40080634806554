import { useRef, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import ZSAttackPrevention from '../ZSHome/Lib/ZSAttackPrevention/Index';
import ZSProtectBusiness from '../ZSHome/Lib/ZSProtectBusiness/Index';
import ZSCyberDefenses from '../ZSHome/Lib/ZSCyberDefenses/Index';
import ZSRoundChart from '../ZSHome/Lib/ZSRoundChart/Index';
import ZsHomeTabKey1 from '../ZSHome/Lib/ZsHomeTabKey1/Index';
import ZSSecurityPartner from '../ZSHome/Lib/ZSSecurityPartner/Index';
import ZSTrustedIndustryLeaders from '../ZSHome/Lib/ZSTrustedIndustryLeaders/Index';
import ZSEnsureOrganization from '../ZSHome/Lib/ZSEnsureOrganization/Index';

import ZSContainerStyle from './ZSContainerStyle';

const Container = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleScrollDown = () => {
      scroll.scrollTo(scrollRef?.current.offsetTop, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    };

    // Scrolling animation on initial render from bottom to top
    const scrollFromBottomToTop = () => {
      scroll.scrollToBottom({
        duration: 800,
        delay: 100,
        smooth: 'easeInOutQuart'
      });
    };

    // Add the scroll event listener for handleScrollDown animation
    const scrollButton = document.querySelector('.scroll_d');
    scrollButton.addEventListener('click', handleScrollDown);

    // Add the load event listener for scrollFromBottomToTop animation
    window.addEventListener('load', scrollFromBottomToTop);

    // Clean up the event listeners
    return () => {
      scrollButton.removeEventListener('click', handleScrollDown);
      window.removeEventListener('load', scrollFromBottomToTop);
    };
  }, []);

  return (
    <>
      <ZSContainerStyle>
        <ZSAttackPrevention />
        <ZSProtectBusiness />
        <div ref={scrollRef}>
          <ZSCyberDefenses />
        </div>
        <ZSRoundChart />
        <ZsHomeTabKey1 />
        <ZSSecurityPartner />
        <ZSTrustedIndustryLeaders />
        <ZSEnsureOrganization />
      </ZSContainerStyle>
    </>
  );
};

export default Container;
