import React from 'react';
import Icon from '../../../../Icon/Index';
import ProductHeadStyle from './ProductHeadStyle';

const ProductsHead = (props) => {
  const { productData } = props;

  return (
    <ProductHeadStyle>
      <div className="container_fluid text-center" style={{ height: '100%' }}>
        <p className="pt-5 anrita_sml_title">{productData?.productHeader?.title}</p>
        <p className="pb-3 anrita_big_title">{productData?.productHeader?.subTitle}</p>
        <div className={'image_container d-flex flex-column align-items-center justify-content-end'}>
          <img className="pt-4 pb-1 enrita_logo" src={productData?.productHeader?.productImage?.productLogo} alt={`${productData?.product}Logo`} width={200} />
          <img className="mt-3 DashbordImg" src={productData?.productHeader?.productImage?.productDashboard} alt={`${productData?.product}Dashbordimage`} />
          <div className="AnritaHead_icon" style={{ cursor: 'pointer' }}>
            <Icon iconType="Home" name="scrolldown" width={15} className="scroll_d" />
          </div>
        </div>
      </div>
    </ProductHeadStyle>
  );
};

export default ProductsHead;
