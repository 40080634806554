import ZSLargeDarkButton from './ZSlargeDarkButtonStyle';

const LargeDarkBtn = (props) => {
  const { href, text, className, onClick } = props;
  return (
    <ZSLargeDarkButton href={href} onClick={onClick} className={`nav_menu_link large_dark_btn ${className}`}>
      {text}
    </ZSLargeDarkButton>
  );
};

export default LargeDarkBtn;
