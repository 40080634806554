import CyberDefensesDetailsStyle from './ZSCyberDefenseStyle';

const DefenseDetails = (props) => {
  const { card } = props;

  return (
    <CyberDefensesDetailsStyle>
      <div className="CyberDefenses__details__content">
        <div className="CyberDefenses__details__inner">
          <img src={card.imageUrl} alt={card.label} className="CyberDefenses__details__img" />
          <div className="CyberDefenses__details__text">
            <h2>{card.title}</h2>
            <p>{card.description}</p>
          </div>
        </div>
      </div>
    </CyberDefensesDetailsStyle>
  );
};

export default DefenseDetails;
