import styled from 'styled-components';

const ZSSecurityPartnerBox = styled.div`
  position: relative;
  margin: 1.3rem 0;
  border-radius: 0.5rem;
  background-color: var(--light);
  padding: 3.7rem 4.3rem;
  box-shadow: 0px 24px 48px 12px rgba(0, 0, 0, 0.05);

  @media (max-width: 769px) {
    padding: 2rem;
  }

  .SecurityPartner_box_box1_icon {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 22px;
    display: inline-block;
    color: var(--main-dark-clr);
  }

  .SecurityPartner_box_box1_para {
    color: #1c1c1c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 132.4%;
    margin-top: 20px;
  }

  @media screen and (max-width: 400px) {
    .SecurityPartner_box_box1_title {
      margin: 25px 0 !important;
    }
  }
  .SecurityPartner_box_box1_title {
    color: #1230bd;
    font-family: Poppins;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    white-space: pre-line;
    line-height: 1.2;

    @media (max-width: 550px) {
      font-size: 1.3rem;
    }
  }
`;

export default ZSSecurityPartnerBox;
