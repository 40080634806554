import ZSFooterSection2LinksData from './ZSFooterSectionLinks2Data/Index';
import ZSFooterSection2IconData from './ZSFooterSection2IconData/Index';
import ZSFooterSectionTwo from './ZSFooterSection2Style';
import { Footer2Links, ZSFooter2Icon } from '../../../../LayoutData/Index';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

const FooterSection2 = () => {
  return (
    <ZSFooterSectionTwo className="py-2  footersection2__bg">
      <div className="container footer__nav">
        <Row>
          <div className="col-lg-6 col-md-12 col-sm-12 pr-4 d-flex align-items-center justify-content-md-center justify-content-lg-start justify-content-center " style={{ cursor: 'pointer' }}>
            <div className="footer_logo" onClick={() => (window.location.href = '/')}>
              {/* <Icon name="ZLogofullWhite" iconType="Home" width={120} />{' '} */}
              &copy; Zeronsec
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <ZSFooterSection2LinksData links={Footer2Links} />
          </div>
        </Row>

        <ZSFooterSection2IconData icons={ZSFooter2Icon} />
      </div>
    </ZSFooterSectionTwo>
  );
};

FooterSection2.propTypes = {
  Footer2Links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  ZSFooter2Icon: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  )
};

FooterSection2.defaultProps = {
  Footer2Links: []
};

export default FooterSection2;
