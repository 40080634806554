import styled from 'styled-components';

export const CoverageCapabilitiesWrapper = styled.div`
  .coverageBoxContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 54px;

    .roundBox {
      height: 124px;
      width: 124px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: #d9d9d9;
      margin: 0 auto 20px;
      cursor: pointer;
      transition: all 0.3s ease;
    }
  }
  .chipContainer {
    max-width: 1011px;
    margin: 0 auto;
  }
  .coverage-description {
    font-size: 19px;
    line-height: 25px;
    font-weight: 400;
    margin-top: 30px;
    text-align: center;
    padding: 0 25%;
  }
  .roundBox.active {
    background-color: #fff !important;
  }
  .concern_chip {
    font-family: Poppins;
    font-weight: 400;
    font-size: 23px;
    line-height: 43px;
    padding: 5px 20px;
    margin: 4px;
    flex: 1 1 auto;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
    text-align: center;
  }
  @media (max-width: 600px) {
    .carousel-heading {
      font-size: 35px !important;
      padding: 0 5% !important;
    }
    .padding-right-200 {
      padding-right: 15px !important;
      padding-left: 25px !important;
      margin-top: 0px !important;
    }
    .concern_chip {
      font-size: 18px;
      line-height: 28px;
      padding: 10px 20px;
    }
  }
`;

export const ServiceHeaderWrapper = styled.div`
  .serviceHederTwo {
    margin-bottom: 120px;
  }

  .service-title {
    font-family: Poppins;
    font-size: 57.13px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    /* color:  #1A3CDB; */
    color: var(--main-dark-bg);

    @media (max-width: 1100px) {
      font-size: 3rem;
      line-height: 1.4 !important;
      font-size: 2rem;
    }

    @media (max-width: 550px) {
      font-size: 1.5rem;
    }
  }

  .padding-right-200 {
    padding-right: 0;
    padding-left: 90px;
  }

  .serviceHeaderWrapper {
    padding-right: 0;
    @media (max-width: 1100px) {
      padding: 0 0 0 2rem;
    }
    @media (max-width: 550px) {
      margin-top: 0 !important;
      padding: 0 2rem;
    }
    @media (min-width: 1400px) and (max-width: 1599px) {
      width: 58%;
    }

    .service-title {
      @media screen and (max-width: 900px) {
        font-size: 32.13px;
      }
    }
  }

  .title {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 700;
    line-height: 26px;
    color: #000000;
  }
  .desc {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    color: #232323;
    @media (max-width: 550px) {
      font-size: 15px;
    }
  }
  .image {
    background-color: #e6e8f3;
    /* min-height: 450px; */

    img {
      @media (max-width: 1100px) {
        object-fit: cover;
      }
    }
  }
`;
