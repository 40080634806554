import styled from 'styled-components';

const RoundChartContainer = styled.div`
  /* Add this CSS to your ZSRoundChartStyle component's style sheet or a global CSS file */

  margin-top: 1rem;

  img {
    width: 500px;
    max-width: 100%;
    height: auto;
  }

  .RoundChart_left {
    flex-basis: 43%;

    svg {
      @media (max-width: 600px) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .RoundChart_right {
    flex-basis: 30%;

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  .RoundChart__title {
    color: var(--main-dark-clr);
    font-size: var(--title-2);
    line-height: 35px;
    font-weight: var(--fw-700);
    letter-spacing: 0.6px;

    @media (max-width: 1024px) {
      font-size: 1.7rem;
    }
  }

  .RoundChart__para {
    font-weight: 500;
    font-size: 1rem;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .RoundChart__btn {
    border: var(--light-border);
    font-size: 0.79469rem;
    font-family: Lato;
    border-radius: 0.26075rem;
    font-weight: 600;
  }

  .RoundChart__btn:hover {
    color: #fff;
    background: var(--main-dark-clr);
  }
`;

export default RoundChartContainer;
