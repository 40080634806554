import styled from 'styled-components';

const CompanyAboutStyle = styled.div`
  background: var(--main-dark-bg);
  position: relative;
  .companyabout {
    animation: moveFromCenter 2s ease;

    .companyabout_imgslider {
      &:after,
      &:before {
        display: none;
      }
    }

    .center_content {
      @media (max-width: 550px) {
        position: relative;
        z-index: 1;
      }
    }

    .center_content_box {
      /* width: 60%; */
      max-width: 950px;
      /* height: 400px; */
      padding: 3rem 5rem 40rem;
      background: white;
      /* border-top-left-radius:10px;
      border-top-right-radius:10px; */
      transform: translateY(-40px);
      border-radius: 10px;
      @media (max-width: 369px) {
        width: 90%;
      }
      @media (min-width: 370px) and (max-width: 769px) {
        width: 80%;
      }
      @media (min-width: 770px) and (max-width: 1100px) {
        max-width: 740px;
        /* height: 970px; */
        padding: 4rem;
        padding-bottom: 50%;
      }
      @media (max-width: 550px) {
        padding: 2rem 2rem 4rem;
      }
      @media (min-width: 550px) and (max-width: 769px) {
        padding: 2rem 2rem 28rem;
      }
      @media (min-width: 1450px) {
        padding-bottom: 45%;
      }
      /* @media (max-width: 1280px) {
        padding-bottom: 6rem;
      }
      @media (max-width: 1551px) {
        height:420px;
      }
      @media(max-width:1387px){
        height:450px;
      }
      @media(max-width:1387px){
        height:450px;
      }
      @media(max-width:1280px){
        height:470px;
      }
      @media(max-width:1130px){
        height:490px;
      }
      @media(max-width:1050px){
        height:515px;
      }
      @media(max-width:977px){
        height:555px;
      }
      @media(max-width:861px){
        height:595px;
      }
      @media(max-width:767px){
        height:auto;
        padding-bottom:3.5rem;
      } */
      .center_content_box_title {
        color: #1230bd;
        font-size: 48.432px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 1rem;
        @media (max-width: 769px) {
          font-size: 28px;
        }
      }
      .center_content_box_para {
        color: #232323;
        text-align: justify;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.9%; /* 25.631px */
        @media (max-width: 769px) {
          font-size: 14px;
        }
      }
    }
    .center_content_box_img {
      width: 100%;
      display: flex;
      justify-content: end;
      /* top: 1070px; */
      position: absolute;
      bottom: 5rem;
      z-index: 1;
      /* transform: translateY(-37rem); */
      @media (max-width: 1100px) {
        bottom: unset;
      }
      @media (min-width: 370px) and (max-width: 769px) {
        top: 42rem;
      }
      @media (min-width: 550px) and (max-width: 769px) {
        top: unset;
        bottom: 5rem;
      }
      @media (min-width: 770px) and (max-width: 1100px) {
        top: 45rem;
      }
      @media (max-width: 550px) {
        flex-direction: column;
        position: relative;
        top: unset;
        bottom: 5rem;
      }
      img {
        width: 90%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .center_content_box2 {
      width: 70%;
      padding: 20px;
      background: white;
      transform: translateY(-45px);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: none;
    }
    .center_content_box_blur {
      width: 250px;
      padding: 3rem;
      flex-shrink: 0;
      width: 250px;
      /* border-radius: 9.056px;
      border: 0px solid #57a1d8;
      background: linear-gradient(
        215deg,
        rgba(4, 4, 9, 0.5) 0%,
        rgba(18, 48, 189, 0) 100%
      );
      box-shadow: 0px 27.167009353637695px 56px 13.583504676818848px
        rgba(0, 0, 0, 0.11);
      backdrop-filter: blur(23.5px); */
      border-radius: 0.566rem;
      border: 0px solid #57a1d8;
      background: linear-gradient(186deg, rgba(4, 4, 9, 0.5) -10.85%, rgba(18, 48, 189, 0) 159.84%);
      box-shadow: 0px 27.16701px 56px 13.5835px rgba(0, 0, 0, 0.11);
      backdrop-filter: blur(23.5px);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      /* top: 665px;
      left: 400px; */
      top: -3rem;
      left: 20rem;
      z-index: 1;
      flex-direction: column;

      @media (max-width: 550px) {
        position: unset;
        width: 80%;
        margin: auto;
        order: 2;
        padding: 2rem;
        margin-top: -2rem;
      }
      @media (max-width: 369px) {
        width: 90%;
      }
      @media (min-width: 550px) and (max-width: 769px) {
        bottom: -5rem;
        top: unset;
        padding: 2rem;
        left: 14%;
      }
      @media (min-width: 770px) and (max-width: 1100px) {
        padding: 2rem;
        left: 24%;
      }
      @media (min-width: 770px) and (max-width: 949px) {
        left: 18%;
      }
      @media (min-width: 950px) and (max-width: 1100px) {
        padding: 2rem;
        left: 24%;
      }

      @media (min-width: 1400px) {
        left: 26%;
      }
      @media (min-width: 1800px) {
        left: 32%;
      }
      /* @media(max-width:1742px){
        top:625px;
      }
      @media(max-width:1551px){
        top:645px;
      }
      @media(max-width:1387px){
        top:675px;
      left: 360px;
      }
      @media(max-width:1280px){
        top:575px;
        width:250px;
      left: 340px;
      }
      @media(max-width:1241px){
        padding: 55px 20px;
        top:595px;
      }

      @media(max-width:1180px){
        padding: 40px 20px;
      left: 310px;
      }
      @media(max-width:1130px){
        top:615px;
        padding: 50px 20px;
      }
      @media(max-width:1050px){
        top:645px;
      }
      @media(max-width:977px){
        top:680px;
      left: 270px;
      width:200px;
      }
      @media(max-width:861px){
        top:720px;
        padding: 40px 20px;
      }

      @media (max-width: 768px) {
        width: 70%;
      position: initial;
      transform: translateY(-40px);
      display: flex;
      margin: 0 auto;
      border-radius:0;
      } */

      .blur_contentTitle {
        color: rgb(255, 255, 255);
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.5;
        text-transform: capitalize;
      }

      .blur_content {
        /* width: 190px; */
        color: #fff;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
        @media (max-width: 769px) {
          font-size: 1.2rem;
        }
        @media (max-width: 550px) {
          font-size: 1rem;
        }
        /* @media(max-width:1820px){
          font-size: 30px;
        }
        @media(max-width:1742px){
          font-size: 29px;
        }
        @media(max-width:1620px){
          font-size: 27px;
        }
        @media(max-width:1552px){
          font-size: 25px;
        }
        @media(max-width:1420px){
          font-size: 24px;
          width:165px;
        }
        @media(max-width:1387px){
          font-size: 23.5px;
        }
        @media(max-width:1280px){
          font-size: 22px;
        }
        @media(max-width:1130px){
          font-size: 20px;
        }
        @media(max-width:1050px){
          font-size: 19.4px;
        }
        @media(max-width:977px){
          font-size: 18px;
        }
        @media(max-width:861px){
          font-size: 17px;
        } */
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
`;

export default CompanyAboutStyle;
