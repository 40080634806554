// formValidation.js
export const initialFormErrors = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  topic: ''
};

export const validateForm = (formData) => {
  let isValid = true;
  const newErrors = {};

  if (!formData.firstName) {
    newErrors.firstName = 'First name is required';
    isValid = false;
  }

  if (!formData.lastName) {
    newErrors.lastName = 'Last name is required';
    isValid = false;
  }

  if (!formData.email) {
    newErrors.email = 'Email is required';
    isValid = false;
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    newErrors.email = 'Invalid email format';
    isValid = false;
  }

  if (!formData.phoneNumber) {
    newErrors.phoneNumber = 'Contact number is required';
    isValid = false;
  } else if (!/^\d+$/.test(formData.phoneNumber)) {
    newErrors.phoneNumber = 'Contact number must contain only numbers';
    isValid = false;
  }

  if (!formData.topic) {
    newErrors.topic = 'Please select a topic';
    isValid = false;
  }

  if (!formData.acceptTerms) {
    newErrors.acceptTerms = 'Accept terms and conditions';
    isValid = false;
  }

  return { isValid, errors: newErrors };
};
