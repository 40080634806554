import styled from 'styled-components';

const ProductHeadStyle = styled.div`
  height: 100%;

  .anrita_sml_title {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 138.5%;
    margin-bottom: 0.6rem;
    /* animation: moveFromCenter2 2s ease; */

    @media (max-width: 600px) {
      font-size: 1.3rem;
      padding: 0 1rem;
    }
  }
  .anrita_big_title {
    color: #1a3cdb;
    font-family: Lato;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: 138.5%;
    margin-bottom: 2.6rem;
    /* animation: moveFromCenter2 2s ease; */

    @media (max-width: 600px) {
      font-size: 2rem;
    }
  }
  .image_container {
    width: 100%;
    ${'' /* height:647px; */}
    background:var(--main-dark-bg);
    position: relative;
    opacity: 1;
    transition: height 2s ease;
  }

  .image_container.hidden {
    opacity: 0;
    pointer-events: none; /* Prevent interactions with hidden element */
    height: 0;
  }

  .DashbordImg {
    width: 75%;

    @media (max-width: 1023px) {
      display: none;
      width: 90%;
      border-radius: 5px;
    }
  }
  .AnritaHead_icon {
    position: absolute;
    bottom: 11%;
    right: 6.5%;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .DashbordImg {
    /* animation: slideInFromBottom 3s ease; */
  }

  /* Smooth move animation for enrita_logo */
  .enrita_logo {
    margin-top: 2rem;
    margin: 2.5rem 0 2rem;
    width: 250px;
    padding: 0 !important;
    /* animation: moveFromCenter2 3s ease; */
    @media (max-width: 769px) {
      margin: 2rem 0 -1rem;
      width: 200px;
    }
  }

  @keyframes moveFromCenter2 {
    0% {
      transform: translatey(350px);
      opacity: 0;
    }
    100% {
      transform: translatex(0);
      opacity: 1;
    }
  }
`;
export default ProductHeadStyle;
