import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ZSLightBigCard from '../../../../Component/ZSCardsLayout/ZSLightBigCard/index';
import { ZSSecurityPartnerBoxData } from '../../HomeData/Index';
import { Slide } from 'react-awesome-reveal';

import ZSSecurityPartnerBox from './ZSSecurityPartnerStyle';

const SecurityPartner = () => {
  return (
    <ZSSecurityPartnerBox className="SecurityPartner mt-4">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <Container className="pb-5 pt-3 container securityPartnerContainer">
          <div className="Center__title pb-4 text-center">
            <p className="mb-4 sectionthree_head">
              Why Choose Zeronsec As <br /> Your Security Partner?
            </p>
          </div>
          <Row className="p-0 m-0 d-flex justify-content-center SecurityPartner_cards">
            <Col className="SecurityPartner_card" sm={5}>
              <div className="SecurityPartner_box ">
                {ZSSecurityPartnerBoxData.slice(0, 2).map((data) => (
                  <ZSLightBigCard key={data.id} id={`card_${data.id}`} icon={data.icon} title={data.title} description={data.description} />
                ))}
              </div>
            </Col>
            <Col className="SecurityPartner_card" sm={5}>
              <div className="SecurityPartner_box" style={{ height: '100%' }}>
                {ZSSecurityPartnerBoxData.slice(2, 4).map((data) => (
                  <ZSLightBigCard key={data.id} id={`card_${data.id}`} icon={data.icon} title={data.title} description={data.description} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Slide>
    </ZSSecurityPartnerBox>
  );
};

SecurityPartner.propTypes = {
  ZSSecurityPartnerBoxData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  )
};

SecurityPartner.defaultProps = {
  ZSSecurityPartnerBoxData: []
};

export default SecurityPartner;
