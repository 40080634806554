import React from 'react';

import CompanyTitleStyle from './CompanyTitleStyle.js';

const CompanyTitle = () => {
  return (
    <CompanyTitleStyle className="container-fluid ">
      <div className="CompanyTitle row py-5 pt-xs-2 pb-xs-2 text-center">
        <div className="col-lg-12 py-5">
          <div className="CompanyTitle_title pb-3">We are Zeronsec.</div>
          <div className="CompanyTitle_para">
            A team of industry veterans driven by a deep passion for
            <br />
            cutting-edge technologies that protect your business.{' '}
          </div>
        </div>
      </div>
    </CompanyTitleStyle>
  );
};

export default CompanyTitle;
