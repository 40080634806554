import ZSFooterSection1 from '../ZSFooter/FooterChildLib/ZSFooterSection1/Index';
import ZSFooterSection2 from '../ZSFooter/FooterChildLib/ZSFooterSecton2/Index';

const ZSFooter = () => {
  return (
    <footer id="fooerContent">
      <ZSFooterSection1 />
      <ZSFooterSection2 />
    </footer>
  );
};

export default ZSFooter;
