/* eslint-disable react/jsx-no-target-blank */
const ZSFooterSection2IconData = (props) => {
  const { icons } = props;
  return (
    <div className="d-flex align-items-center justify-content-center justify-content-md-center footer__icon">
      {icons.map((icon, i) => (
        <a href={icon.url} target="_blank" key={i}>
          <i className={`fa-brands ${icon.name} mx-2 mt-1 iconFooter`}></i>
        </a>
      ))}
    </div>
  );
};

export default ZSFooterSection2IconData;
