import React from 'react';
import { CollapseConentStyle } from './ServiceMdrStyle';

const CollapseConent = ({ data, onCollapseClick }) => {
  return (
    <CollapseConentStyle id="accordion">
      {data.map((element, index) => (
        <div className="card" key={element} data-before={element.label}>
          <div
            className={`card-header collapsed `}
            data-toggle="collapse"
            data-target={`#collapse_${index}`}
            aria-expanded="true"
            aria-controls={`collapse_${index}`}
            onClick={() => onCollapseClick(index)}
            id={`heading_${index}`}
          >
            <button
              className="btn"
              // data-toggle="collapse"
              // data-target={`#collapse_${index}`}
              // aria-expanded="true"
              // aria-controls={`collapse_${index}`}
              // Add this onClick handler
            >
              {element.title}
            </button>
          </div>

          <div id={`collapse_${index}`} className={`collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading_${index}`} data-parent="#accordion">
            <div className="card-body">{element.description}</div>
          </div>
        </div>
      ))}
    </CollapseConentStyle>
  );
};

export default CollapseConent;
