import React from 'react';
import PropTypes from 'prop-types';
import ZSTrustedIndusstryLeadersContainer from './ZStrustedIndustryLeadersStyle';
import ABG from '../../../../Image/ZSTrustedIndustryLeadersImg/ABG.png';
import BIORAD from '../../../../Image/ZSTrustedIndustryLeadersImg/BIORAD.png';
import NPCI from '../../../../Image/ZSTrustedIndustryLeadersImg/NPCI.png';
import ARAMCO from '../../../../Image/ZSTrustedIndustryLeadersImg/ARAMCO.png';
import CERA from '../../../../Image/ZSTrustedIndustryLeadersImg/CERA.png';
import SAFARICOM from '../../../../Image/ZSTrustedIndustryLeadersImg/SAFARICOM.png';
import GEP from '../../../../Image/ZSTrustedIndustryLeadersImg/GEP.png';
import NIC from '../../../../Image/ZSTrustedIndustryLeadersImg/NIC.png';
import { Slide } from 'react-awesome-reveal';

const TrustedIndusstryLeaders = ({ pyClass, myClass }) => {
  return (
    <ZSTrustedIndusstryLeadersContainer className="pt-4">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className={`container ${pyClass} ${myClass}`}>
          <p className="text-center TrustedIndusstryLeaders_title"> Trusted by Industry Leaders </p>
          <div className="leaders_box">
            <div className="leaders_box_img">
              <img src={BIORAD} alt="BIORAD" />
            </div>
            <div className="leaders_box_img">
              <img src={ABG} alt="ABG" />
            </div>
            <div className="leaders_box_img">
              <img src={NPCI} alt="NPCI" />
            </div>
            <div className="leaders_box_img">
              <img src={ARAMCO} alt="ARAMCO" />
            </div>
          </div>
          <div className="leaders_box">
            <div className="leaders_box_img">
              <img src={CERA} alt="CERA" />
            </div>
            <div className="leaders_box_img">
              <img src={SAFARICOM} alt="SAFARICOM" />
            </div>
            <div className="leaders_box_img">
              <img src={GEP} alt="GEP" />
            </div>
            <div className="leaders_box_img">
              <img src={NIC} alt="NIC" />
            </div>
          </div>
        </div>
      </Slide>
    </ZSTrustedIndusstryLeadersContainer>
  );
};

TrustedIndusstryLeaders.propTypes = {
  pyClass: PropTypes.string, // Custom class for padding on the y-axis
  myClass: PropTypes.string // Custom class for margin on the y-axis
};

TrustedIndusstryLeaders.defaultProps = {
  pyClass: 'py-3', // Default padding class
  myClass: 'my-md-5' // Default margin class
};
export default TrustedIndusstryLeaders;
