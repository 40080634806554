import styled from 'styled-components';

const CompanyTitleStyle = styled.div`
  background: var(--main-dark-bg);
  .CompanyTitle {
    animation: moveFromCenter 2s ease;
  }
  .companyabout_imgslider_box {
    width: 80%;
  }
  .CompanyTitle {
    .CompanyTitle_title {
      color: #fff;
      text-align: center;
      font-size: 86.811px;
      font-style: normal;
      font-weight: 700;
      line-height: 167.9%; /* 145.756px */
      letter-spacing: -2.17px;
      @media (max-width: 1000px) {
        font-size: 48px;
        line-height: 107.9%;
      }
      @media (max-width: 550px) {
        font-size: 34px;
      }
    }
    .CompanyTitle_para {
      color: #f5f5f5;
      text-align: center;
      font-size: 22.815px;
      font-style: normal;
      font-weight: 400;
      line-height: 123.4%; /* 28.153px */
      @media (max-width: 767px) {
        font-size: 12.815px;
      }
    }
  }
  @media (max-width: 575px) {
    .CompanyTitle {
      padding: 0 0 !important;
    }
  }
`;

export default CompanyTitleStyle;
