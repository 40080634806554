import styled from 'styled-components';

const ZSFooterSectionTwo = styled.div`
  background: #0823a0;
  position: relative;

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }

  .footer_logo {
    color: white;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    line-height: normal;
  }

  .footer__link {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 10px;
    :hover {
      color: rgb(195 193 193) !important;
    }
    @media (max-width: 1280px) {
      margin: 0;
    }
  }

  .iconFooter {
    :hover {
      color: rgb(195 193 193) !important;
    }
  }

  @media (max-width: 991px) {
    .footer_logo {
      padding-top: 10px;
    }
  }

  .footersection2_socialicon {
    margin: 0 10px;
  }

  .footer__icon {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;

    @media (max-width: 600px) {
      position: static;
    }

    i {
      font-size: 1.5rem;
    }
  }
`;

export default ZSFooterSectionTwo;
