import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useScrollReveal } from '../../../../Helper/Index';
import ButtomIndex from '../../../../Component/ZSForm/ZSButtons/Index';
import ZSEnsureOrganizationBox from './ZSEnsureorganizationStyle';
import AlertToken from '../../../../Component/AlertTokan/Index';
import { Slide } from 'react-awesome-reveal';

const EnsureOrganization = ({ pyClass }) => {
  const { ref, isInViewport } = useScrollReveal();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // State for success message
  const [sendError, setSendError] = useState(false); // State for email sending error
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailSubmit = (event) => {
    event.preventDefault();

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.match(emailPattern)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    const instance = axios.create({
      baseURL: `https://${window.location.hostname}:5000`,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
    });
    const apiData = { method:'get', url:`/getintouch?email=${email}&lastname=''&contact=''&firstname=''&topic=''&message=''` };
    instance(apiData)
      .then((response) => {
        const { status, type } = response.data;
        if (status) {
          if (type === 'GetInTouch') {
            setEmail('');
            setSubmitted(true);
            setEmailError('');
            setShowSuccess(true);
            setSendError(false);
            setIsLoading(false);
          }
        }
        return response.data;
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          setSendError(true);
          setShowSuccess(false);
          setIsLoading(false);
          setSubmitted(true);
          setEmail('');
        }
        return error.message;
      });
  };

  const handleEmailChange = (event) => {
    setSendError(false);
    setShowSuccess(false);
    setEmail(event.target.value);
    setEmailError('');
  };

  useEffect(() => {
    if (submitted) {
      setEmail('');
      setSubmitted(false);
    }
  }, [submitted]);

  return (
    <>
      <ZSEnsureOrganizationBox className={`container  ${pyClass}`}>
        <Slide direction="up" triggerOnce duration={1500} delay={100}>
          <div className="row m-0 p-0">
            <div className="col-xl-6 col-md-12 px-xl-0">
              <div className="EnsureOrganization_box">
                <div className="EnsureOrganization_box_title">
                  Ensure your organization remains <br /> resilient to the latest cyber threats
                </div>
                <div className="EnsureOrganization_box_para">with our array of specialist security tools and services.</div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12" style={{ marginTop: '14px' }}>
              <div className="EnsureOrganization_box">
                <div className="EnsureOrganization_box_input_btn">
                  <Form>
                    <Form.Group controlId="email" className="Email_valid">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter E-Mail Address"
                        value={email}
                        onChange={handleEmailChange}
                        className={`EnsureOrganization_box_input ${emailError ? 'is-invalid' : ''}`}
                      />
                      {emailError && <span className="invalid-feedback">{emailError}</span>}
                    </Form.Group>
                    <br />
                    <ButtomIndex
                      ButtonType="dark"
                      className="EnsureOrganization_box_input_btn_btn"
                      onClick={handleEmailSubmit}
                      disabled={isLoading}
                      text={
                        isLoading ? (
                          <>
                            Sending...
                            <div className="loading-spinner"></div>
                          </>
                        ) : (
                          'Get Immediate Help'
                        )
                      }
                    />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </ZSEnsureOrganizationBox>
      <AlertToken
        variant="success"
        show={showSuccess}
        message={
          <>
            <b>Thank You for Getting in Touch!</b>
            <br />
            <br />
            We've successfully received your request to connect with us. A member of our team will reach out to you soon. In the meantime, feel free to explore our website for more insights.
            <br />
            Your trust and interest mean a lot to us. We're looking forward to speaking with you soon.
            <br />
            <br />
            Team Zeronsec
          </>
        }
        duration={10000}
        onClose={() => setShowSuccess(false)}
      />
      <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." duration={10000} onClose={() => setShowSuccess(false)} />
    </>
  );
};

EnsureOrganization.propTypes = {
  pyClass: PropTypes.string // Custom class for padding on the y-axis
};

EnsureOrganization.defaultProps = {
  pyClass: 'py-5' // Default padding class
};

export default EnsureOrganization;
