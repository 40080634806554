import styled from 'styled-components';

const ZSContainerStyle = styled.div`
  @media (min-width: 1400px) {
    .container {
      max-width: 1200px;
    }
  }
`;

export default ZSContainerStyle;
