import styled from 'styled-components';

const CompanyGloablStyle = styled.div`
  margin-top: 10rem;
  .mapTitle {
    color: #1a3cdb;
    text-align: center;
    font-family: Poppins;
    font-size: 86.811px;
    font-style: normal;
    font-weight: 700;
    line-height: 167.9%; /* 145.756px */
    letter-spacing: -2.17px;
    padding: 5% 0 0 0;
  }
  @media screen and (min-width: 1281px) and (max-width: 1600px) {
    margin-top: 8rem !important;
  }
  @media screen and (min-width: 651px) and (max-width: 1280px) {
    margin-top: 4rem !important;
    .mapTitle {
      font-size: 45.811px !important;
    }
  }
  @media screen and (min-width: 400px) and (max-width: 650px) {
    margin-top: 4rem !important;
  }

  @media screen and (max-width: 850px) {
    .mapTitle {
      padding: 4rem 0 1rem !important;
    }

    .map_contain {
      padding-bottom: 1rem !important;
    }
  }
  @media screen and (max-width: 650px) {
    .mapTitle {
      font-size: 35.811px !important;
      padding: 3rem 0 1rem !important;
    }
  }
`;

export default CompanyGloablStyle;
