import styled from 'styled-components';

const ZSLargeLightButton = styled.button`
  font-size: var(--fs-14);
  font-weight: var(--fw-600);
  background: var(--light);
  color: var(--main-dark-clr);
  border: none;
  border-radius: 3px;
  border: var(--light-border);
  padding: 10px 20px;
  transition: all 0.5s ease;
  font-family: Lato;

  &:hover {
    background: none;
    color: var(--light);
  }
`;

export default ZSLargeLightButton;
