import styled from 'styled-components';

const CyberDefensesDetailsStyle = styled.div`
  width: 100%;
  height: 100%;

  .CyberDefenses__details__content {
    height: 97%;
    position: relative;

    .CyberDefenses__details__inner {
      position: relative;
      height: 100%;
      z-index: 2;
      display: flex;
      align-items: flex-end;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background: linear-gradient(10deg, rgba(87, 131, 216, 0.76) -15.25%, rgba(87, 131, 216, 0) 94.73%); */
        z-index: 1;
        background: linear-gradient(0deg, rgb(0 0 0 / 65%), rgba(0, 0, 0, 0));
        border-radius: 10px;
      }

      @media (max-width: 600px) {
        padding: 1rem;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .CyberDefenses__details__text {
      padding: 0 9%;
      color: white;
      z-index: 2;
      padding-bottom: 1.8rem;
      padding-top: 10px;
      text-shadow: 0 0 1px black;

      @media (max-width: 768px) {
        padding-top: 3rem;
      }

      h2 {
        color: #fff;
        font-family: Poppins;
        font-weight: 900;
        font-size: 1.625rem;
        letter-spacing: 0.8px;

        @media (max-width: 600px) {
          font-size: 1rem;
        }
      }

      p {
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;

        @media (max-width: 600px) {
          font-size: 0.8rem;
        }
      }
    }
  }

  /* @media screen and (min-width: 1025px) and (max-width: 1200px) {
    .CyberDefenses__details__content .CyberDefenses__details__text {
      top: 18% !important;
    }
  } */

  @media screen and (max-width: 991px) {
    height: 100%;
    .CyberDefenses__details__content .CyberDefenses__details__text {
      bottom: 9%;
    }
  }
  @media screen and (max-width: 776px) {
    height: 100%;
    .CyberDefenses__details__content .CyberDefenses__details__text {
      bottom: 5%;
    }
  }

  @media screen and (max-width: 450px) {
    height: 100%;
    .CyberDefenses__details__content .CyberDefenses__details__text {
      /* top: 18% !important; */
      padding: 10px;
      bottom: 0%;
    }
  }

  @media screen and (max-width: 330px) {
    .CyberDefenses__details__content .CyberDefenses__details__text {
      /* top: 5% !important; */
      padding: 10px;
      font-size: 12px;
    }
  }
`;

export default CyberDefensesDetailsStyle;
