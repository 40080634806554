import React from 'react';
import ButtonIndex from '../../../Component/ZSForm/ZSButtons/Index.js';
import { Link } from 'react-router-dom';
import { ServiceHeaderWrapper } from './ModuleComponentStyle.js';
import { Slide } from 'react-awesome-reveal';

const ServiceHeaderTwo = ({ title, content, imgsrc, onclick, name, to, text, lineHeight }) => {
  return (
    <ServiceHeaderWrapper>
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="container-lg">
          <div className=" row my-5">
            <div className="col-lg-8 col-md-6 mt-5 padding-right-200 serviceHeaderWrapper">
              <div className="service-title mb-5" style={{ lineHeight: lineHeight }}>
                {title}
              </div>
              <div className="desc mb-5 mt-3">{content}</div>
              <Link to={to}>
                <ButtonIndex ButtonType="Dark" text={text} onClick={onclick} />
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-5 d-flex justify-content-center">
              {/* <img src={imgsrc} width="100%" height="100%" name={name} alt="soc image" /> */}
              <div className="image h-100 w-100">
                <img src={imgsrc} width="100%" height="100%" name={name} alt="soc image" />
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </ServiceHeaderWrapper>
  );
};

export default ServiceHeaderTwo;
