import React from 'react';
import axios from 'axios';
import ButtonIndex from '../../../Component/ZSForm/ZSButtons/Index';
import cloudimg from '../../../Image/ZSServices/Cloud Security.png';
import DownloadModal from '../../../Component/ZSModal/Lib/DownloadModal/Index.js';
import { DownloadValidation } from '../../../Component/ZSModal/Lib/DownloadModal/DownloadValidation.js';
import AlertToken from '../../../Component/AlertTokan/Index.js';
import CloudPdf from '../../../Image/ZSServices/Datasheet - Cloud Security.pdf';
import { useState } from 'react';

const ServiceHeader = ({ rule, imgsrc, alt }) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    acceptTerms: false,
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    phoneNumberError: '',
    acceptTermsError: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Define handleDemoButtonClick function for the "Know More" button
  const handleDemoButtonClick = () => {
    setShowModal(true);
  };

  // Define closeModal function for closing the modal
  const closeModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleInputChange = (e) => {
    setSendError(false);
    setShowSuccess(false);
    const fieldName = e.target.name;
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
      [`${fieldName}Error`]: '' // Clear the error for the corresponding field
    });
  };

  // Define handleSubmit function for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = DownloadValidation(formData);

    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, ...errors });
      return;
    }

    setIsLoading(true); // Start loading

    const pdfBlob = await fetch(CloudPdf).then((res) => res.blob());

    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = 'Datasheet - Cloud Security.pdf'; // Customize the file name
    const documentTitle = 'Datasheet - Cloud Security';
    document.body.appendChild(downloadLink);

    const instance = axios.create({
      baseURL: `https://${window.location.hostname}:5000`,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      }
    });
    const apiData = { method:'get', url:`/datasheet?title=${documentTitle}&email=${formData.email}&lastname=${formData.lastName}&contact=${formData.phoneNumber}&firstname=${formData.firstName}` };
    instance(apiData)
      .then((response) => {
        const { status, type } = response.data;
        if (status) {
          if (type === 'Datasheet') {
            setShowSuccess(true);
            downloadLink.click();
            URL.revokeObjectURL(pdfUrl);
            document.body.removeChild(downloadLink);
            setIsLoading(false); // Stop loading
            setShowModal(false);
            resetForm();
          }
        }
        return response.data;
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          setSendError(true);
          setIsLoading(false);
          setShowModal(false);
          resetForm();
        }
        return error.message;
      });
  };

  // Function to reset form data
  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      acceptTerms: false
    });
  };

  return (
    <>
      <>
        <>
          <div className="row my-5">
            <div className="col-lg-8 col-md-6 mt-5 padding-right-200">
              <div className="service-title" style={{ marginBottom: '31px' }}>
                Reign over <br /> Cloud Security
              </div>
              {rule ? (
                <>
                  <div className="title mt-2">Real-time monitoring at your command</div>
                  <div className="desc">
                    As your trusted partner, we tackle all your cloud security challenges head-on. With expertise in threat detection, vulnerability management, and ensuring compliance, we maintain
                    the integrity of your cloud environment.
                  </div>
                  <div className="title mt-4">Our mission?</div>
                  <div className="desc mb-5">To deliver uncompromising cloud security that empowers your cloud journey with confidence and peace of mind.</div>
                </>
              ) : (
                <div className="desc mb-5 mt-3">
                  Proactive detection, analysis, and remediation of cyber threats are vital in next gen security operations. Leverage our Managed Detect and Response services, fusing years of hands-on
                  threat remediation experience with top-tier threat intelligence, advanced detection, hunting technologies, and robust remediation strategies. Secure your IT Infra, Apps, and Data
                  with us, today and for the future.
                </div>
              )}
              <ButtonIndex ButtonType="Dark" text="Know More" className="AnritaAbout_btn AnritaAbout_btn_right" onClick={handleDemoButtonClick} />
            </div>
            <div className="col-lg-4 col-md-6 mt-5 d-flex justify-content-center">
              <div className="image h-100 w-100">
                <img src={cloudimg} alt="cloud img" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <DownloadModal showModal={showModal} closeModal={closeModal} formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} isLoading={isLoading} />
        </>
        <AlertToken
          variant="success"
          show={showSuccess}
          message={
            <>
              Thank you for downloading the datasheet!
              <br />
              <br />
              We appreciate your interest. Have a great day!
            </>
          }
          duration={10000}
          onClose={() => setShowSuccess(false)}
        />
      </>
      <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." duration={10000} onClose={() => setSendError(false)} />
    </>
  );
};

export default ServiceHeader;
