import React from 'react';
import Icon from '../../../Icon/Index';
import ZsCarousel from '../../../Component/ZsCarousel';
import ZeronsecService from './ServiceStyle';
import EnsureOrganization from '../../ZSHome/Lib/ZSEnsureOrganization/Index';
import ServiceHeader from '../ModuleComponent/ServiceHeader';
import { concernArrya, plateforms, chips, carouselArray } from '../ServiceData/Index';
import CoverageCapabilities from '../ModuleComponent/CoverageCapabilities';
import { Slide } from 'react-awesome-reveal';

const Services = () => {
  return (
    <ZeronsecService className="container-fluid">
      <div className="service">
        <div className="container-lg">
          <Slide direction="up" triggerOnce duration={1500} delay={100}>
            <ServiceHeader rule />
          </Slide>
        </div>
        <div className="container-lg">
          <Slide direction="up" triggerOnce duration={1500} delay={100}>
            <div className="row my-5">
              <div className="d-flex justify-content-center concern_heading">Your Concerns</div>
              <div className="boxContainer my-5">
                {concernArrya.map((e) => (
                  <div className="squerBox" key={e.icon}>
                    <Icon iconType="Home" name={e.icon} width={15} />
                    <div className="box-title">{e.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </Slide>
        </div>
        <Slide direction="up" triggerOnce duration={1500} delay={100}>
          <div className="carousel-container">
            <div className="carousel-heading">Here's how we solve your concerns</div>
            <ZsCarousel
              nav
              autoplay
              dots
              center
              // responsive
              responsive={{
                1: {
                  items: 1
                },
                900: {
                  items: 2
                },
                1400: {
                  items: 2
                },
                2000: {
                  items: 2
                }
              }}
            >
              {carouselArray.map((e) => (
                <div key={e.name} className="d-flex flex-column align-items-center">
                  <div className="header">{e.name}</div>
                  <div className="description">
                    <div className="m-3">{e.desc}</div>
                  </div>
                </div>
              ))}
            </ZsCarousel>
          </div>
        </Slide>
        <div className="container-lg">
          <CoverageCapabilities />
          <EnsureOrganization />
        </div>
      </div>
    </ZeronsecService>
  );
};

export default Services;
