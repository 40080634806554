import { useEffect } from 'react';
import ButtonIndex from '../../../../Component/ZSForm/ZSButtons/Index';
import AttackPreventionStyle from './AttackPreventionStyled';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Slide } from 'react-awesome-reveal';
import staticImage1 from '../../../../Image/ZSHome/homepage-slide-1.png';
import staticImage2 from '../../../../Image/ZSHome/homepage-slide-2.png';
import staticImage3 from '../../../../Image/ZSHome/homepage-slide-3.png';
import staticImage4 from '../../../../Image/ZSHome/homepage-slide-4.png';
import staticImage5 from '../../../../Image/ZSHome/homepage-slide-5.png';
import staticImage6 from '../../../../Image/ZSHome/homepage-slide-6.png';

const Tab = (props) => {
  const { label } = props;
  return <p className="mb-0 attack__prevention__tabs_dzn">{label}</p>;
};

Tab.propTypes = {
  label: PropTypes.string.isRequired
};

const AttackPrevention = () => {
  const tabs = [{ label: 'DEFEND |' }, { label: ' VISUALISE |' }, { label: 'ANALYSE |' }, { label: 'REMEDIATE' }];
  const homePageStaticImages = [staticImage1, staticImage2, staticImage3, staticImage4, staticImage5, staticImage6];

  useEffect(() => {
    $('.containerRightContent').hide().fadeIn(1000);
  }, []);

  return (
    <AttackPreventionStyle className="container container_wrapper">
      <Slide direction="left" triggerOnce duration={1500} delay={100} className="container-slide-left">
        <div className="container_left_content container_left_content_1280">
          <div className="attack__prevention__title">
            <p className="mb-2">
              Get{' '}
              <span className="degree__position">
                360<span className="degree">o</span>
                <br />
              </span>
              Attack Prevention
            </p>
          </div>
          <div className="attack__prevention__tabs">
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </div>
          <div className="attack__prevention__buttons ">
            <Link to="/contact">
              <ButtonIndex ButtonType="Dark" text="Get Immediate Help" className="container_right_content_btn" />
            </Link>
          </div>
        </div>
      </Slide>
      <Slide direction="left" triggerOnce duration={1500} delay={100} className="container-slide-right">
        <div className="containerRightContent" style={{ display: 'none' }}>
          {homePageStaticImages.map((url, index) => (
            <img className="staticImage" key={index} src={url} alt="Static Images" />
          ))}
        </div>
      </Slide>
    </AttackPreventionStyle>
  );
};

AttackPrevention.defaultProps = {
  Tab: []
};

export default AttackPrevention;
