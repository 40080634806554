import ZSLargeDarkbtn from './ZSLargeDarkButton/Index';
import ZSLargeLightbtn from './ZSLargeLightButton/Index';
import PropTypes from 'prop-types';

const Button_COMPONENTS = {
  Dark: ZSLargeDarkbtn,
  Light: ZSLargeLightbtn
  // add more icon types here
};

const ButtonIndex = ({ ButtonType, ...props }) => {
  const ButtonComponent = Button_COMPONENTS[ButtonType] || ZSLargeDarkbtn;

  return (
    <>
      <ButtonComponent {...props} />
    </>
  );
};

ButtonIndex.defaultProps = {
  // add default props here
};

export default ButtonIndex;
