import ZSLargeLightButton from './ZSLargeLightButtonStyle';

const LargeLightBtn = (props) => {
  const { href, text, className, onClick } = props;
  return (
    <ZSLargeLightButton href={href} onClick={onClick} className={`${className}`}>
      {text}
    </ZSLargeLightButton>
  );
};

export default LargeLightBtn;
