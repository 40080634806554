/* eslint-disable array-bracket-spacing */
/* eslint-disable space-before-function-paren */
/* eslint-disable comma-dangle */
import React, { useEffect, useState } from 'react';
import './map.css';
import { createchart } from './d3Chart';
import getMapData from './mapData.json';

function ZSMap() {
  const svgHeight = 500;
  const svgWidth = 1000;
  const selectedTimeZone = [
    {
      countryName: 'USA',
      tel: '+1 888 243 8171',
      address: '5319 SW Westgate Drive Suite #232 Portland, OR 97221',
      lat: 45.50956533375505,
      lng: -122.73138638937668,
      show: false
    },
    {
      countryName: 'India',
      tel: '+91 701 638 6519',
      address: '1st Floor, Plot-3, Navjivan Society - 2, Ajwa Road, Vadodara, Gujarat 390006<br /> +91 701 638 6519',
      lat: 22.30703351851673,
      lng: 73.23026042698426,
      show: false
    },
    {
      countryName: 'Germany',
      tel: '+41 79 500 68 07',
      address: 'Kleine Rosenstraße 5, 66111 Saarbrücken, Germany<br /> +41 79 500 68 07',
      lat: 49.23350939404082,
      lng: 7.002851928835955,
      show: false
    },
    {
      countryName: 'Australia',
      tel: '+61 41 158 4856',
      address: '65 Coronata Pde, Colebee NSW 2761, Australia<br /> +61 41 158 4856',
      lat: -33.51624208038658,
      lng: 150.9468425605974,
      show: false
    }
  ];

  const [mapCreate, setMapCreate] = useState({});

  const selectData = (e, d) => {
    document.getElementById(d.timezone.replace('/', '_')).style.backgroundColor = '#1c1c26';
    const el = document.getElementById('Map-container');
    const el1 = document.getElementById('Map-container1');
    if (el !== null) {
      el.innerHTML = '';
      el1.innerHTML = '';
      createchart({
        svgWidth,
        svgHeight,
        selectedTimeZone,
        selectData,
        mapCreate
      });
    }
  };

  useEffect(() => {
    if (mapCreate && mapCreate.features) {
      const el = document.getElementById('Map-container');
      const el1 = document.getElementById('Map-container1');
      if (el !== null) {
        el.innerHTML = '';
        el1.innerHTML = '';
      }
      // eslint-disable-next-line no-use-before-define
      createchart({
        svgWidth,
        svgHeight,
        selectedTimeZone,
        selectData,
        mapCreate
      });
    }
  }, [mapCreate]);

  useEffect(() => {
    setMapCreate(getMapData);
  }, []);

  return (
    <>
      <>
        <div data-test="map_container" id="Map-container" style={{ padding: '0 8%' }} />
      </>
      <div id="Map-container1" />
    </>
  );
}

export default ZSMap;
