// ContactHeader.js
import React from 'react';
import ContactForm from './ContactForm/Index';
import HeaderImg from '../../../../Image/ZSPartner/headerbg.png';
import Location1 from '../../../../Image/ZSPartner/locationmap1.png';
import Location2 from '../../../../Image/ZSPartner/locationmap2.jpg';
import Location3 from '../../../../Image/ZSPartner/locationmap3.jpg';
import Location4 from '../../../../Image/ZSPartner/locationmap4.png';
import { Slide } from 'react-awesome-reveal';

import ContactHeaderStyle from './ContactHeaderStyle.js';

const ContactHeader = () => {
  return (
    <ContactHeaderStyle>
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="contact container-fluid px-0">
          <div className="contact_header">
            <div className="container-fluid contactheader_content">
              <div className="row m-0 p-0">
                <div className="col-lg-7 col-md-12 col-sm-12 contactheader_form mb-2 mb-md-2 mb-sm-2 mb-xs-2">
                  <ContactForm />
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12  contactheader_location mb-2 mb-md-2 mb-sm-2 mb-xs-2">
                  <div className="contactheader_location_content py-3">
                    <h3 className="py-3 text-light "> All Locations </h3>
                    <div className="contactheader_locationmainbox">
                      <div className="contactheader_locationchildbox1">
                        <p className="contactheader_locationchildbox1_title m-0 mb-1">USA</p>
                        <p className="contactheader_locationchildbox1_add m-0 mb-3">
                          5319 SW Westgate Drive Suite #232 Portland, OR 97221
                          <br />
                          +1 888 243 8171
                        </p>
                        <div className="contactheader_locationchildbox1_btn">
                          {' '}
                          <a href="tel:+1 678 523 5599" target="_blank" rel="noreferrer">
                            {' '}
                            Call Us{' '}
                          </a>
                        </div>
                      </div>
                      <div className="contactheader_locationchildbox2">
                        <a href="https://maps.app.goo.gl/xkEoXZ5J8zZFFo36A" target="_blank" rel="noreferrer">
                          <img src={Location1} className="contactheader_location_img p-1" />
                        </a>
                      </div>
                    </div>
                    <div className="contactheader_locationmainbox">
                      <div className="contactheader_locationchildbox1">
                        <p className="contactheader_locationchildbox1_title m-0 mb-1">India</p>
                        <p className="contactheader_locationchildbox1_add m-0 mb-3 d-flex flex-wrap">1st Floor, Plot-3, Navjivan Society - 2, Ajwa Road, Vadodara, Gujarat 390006 <br />+91 701 638 6519</p>
                        <div className="contactheader_locationchildbox1_btn">
                          {' '}
                          <a href="tel:+91 701 638 6519" target="_blank" rel="noreferrer">
                            {' '}
                            Call Us{' '}
                          </a>
                        </div>
                      </div>
                      <div className="contactheader_locationchildbox2">
                        <a href="https://goo.gl/maps/biSZ1HjNMd9LhgcM8" target="_blank" rel="noreferrer">
                          <img src={Location2} className="contactheader_location_img p-1" />
                        </a>
                      </div>
                    </div>
                    <div className="contactheader_locationmainbox">
                      <div className="contactheader_locationchildbox1">
                        <p className="contactheader_locationchildbox1_title m-0 mb-1">Germany</p>
                        <p className="contactheader_locationchildbox1_add m-0 mb-3">
                          Kleine Rosenstraße 5 Germany
                          <br />
                          +41 79 500 68 07
                        </p>
                        <div className="contactheader_locationchildbox1_btn">
                          {' '}
                          <a href="tel:+41 79 500 68 07" target="_blank" rel="noreferrer">
                            {' '}
                            Call Us{' '}
                          </a>
                        </div>
                      </div>
                      <div className="contactheader_locationchildbox2">
                        <a href="https://goo.gl/maps/fjyntWGXUo4HegX49" target="_blank" rel="noreferrer">
                          <img src={Location3} className="contactheader_location_img p-1" />
                        </a>
                      </div>
                    </div>
                    <div className="contactheader_locationmainbox">
                      <div className="contactheader_locationchildbox1">
                        <p className="contactheader_locationchildbox1_title m-0 mb-1">Australia</p>
                        <p className="contactheader_locationchildbox1_add m-0 mb-3">
                          65 Coronata Pde Colebee NSW 2761
                          <br />
                          +61 41 158 4856
                        </p>
                        <div className="contactheader_locationchildbox1_btn">
                          {' '}
                          <a href="tel:+61 41 158 4856" target="_blank" rel="noreferrer">
                            {' '}
                            Call Us{' '}
                          </a>
                        </div>
                      </div>
                      <div className="contactheader_locationchildbox2">
                        <a href="https://maps.app.goo.gl/yvBefCB7gCac6RZ36" target="_blank" rel="noreferrer">
                          <img src={Location4} className="contactheader_location_img p-1" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={HeaderImg} className="contactheader_img" alt="header image" />
          </div>
        </div>
      </Slide>
    </ContactHeaderStyle>
  );
};

export default ContactHeader;
