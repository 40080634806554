import { Link } from 'react-router-dom';
import ButtonIndex from '../../../../../Component/ZSForm/ZSButtons/Index';
import { ZSFooterSection1Data } from '../../../../LayoutData/Index';
import ZSFooterSectionOne from './ZSFooterSectuion1Style';
import PropTypes from 'prop-types';
import Icon from '../../../../../Icon/Index';

const FooterSectionOne = () => {
  return (
    <ZSFooterSectionOne className="pt-md-5 py-sm-1">
      <div className=" container py-md-5 py-sm-1">
        <div className="row mx-1">
          <div className="col-md-5 FooterSectionOne_left">
            <h3 className=" mb-3 FooterSectionOne_title">
            <Icon name="ZLogoWhite" iconType="Home" width={150} />
            </h3>
            <p className="text-white  fw-light FooterSectionOne_para mb-3">
              {' '}
              We are on a misson to develop and improve pioneering information security technologies and services to safeguard our clients, empower our team to be the foremost experts in the areas we
              serve.
            </p>
            <div className="FooterSectionOne_left_contact">
              <Link to="/contact">
                <ButtonIndex ButtonType="Light" text="Contact Us" className="FooterSectionOne_btn" />
              </Link>
              <a href="mailto:info@zeronsec.com" className="FooterSectionOne_left_mail text-center">
                <span style={{ display: 'contents' }}>
                  {' '}
                  <i className="fa-solid fa-envelope "></i> <span>info@zeronsec.com </span>{' '}
                </span>
              </a>
            </div>
          </div>
          <hr className="w-100 clearfix d-md-none text-light" />
          <div className="col-md-6 offset-md-1 py-md-3  FooterSectionOne_right">
            <div className="row float-right footerMenuWrapper">
              {ZSFooterSection1Data.map((section) => (
                <div className="col-sm-4 footerMenuCol" key={section.title}>
                  <p className="text-light  FooterSectionOne_right_headings">{section.title}</p>
                  {section.links.map((link) => (
                    <p key={link.id}>
                      <a href={link.url} className="text-light FooterSectionOne_right_links">
                        {link.text}
                      </a>
                    </p>
                  ))}
                  <hr className="w-100 clearfix d-md-none text-light" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ZSFooterSectionOne>
  );
};

FooterSectionOne.propTypes = {
  ZSFooterSection1Data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          url: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired
        })
      ).isRequired
    })
  )
};

FooterSectionOne.defaultProps = {
  ZSFooterSection1Data: []
};

export default FooterSectionOne;
