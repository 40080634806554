import React from 'react';
import img1 from '../../../../Image/ZSCompany/1.jfif';
import img2 from '../../../../Image/ZSCompany/2.jfif';
import img3 from '../../../../Image/ZSCompany/3.jfif';
import img4 from '../../../../Image/ZSCompany/4.jfif';
import { Slide } from 'react-awesome-reveal';

import ZeronsecLifeStyle from './ZeronsecLifeStyle.js';

const images = [img1, img2, img3, img4, img1, img2, img3, img4];

const ZeronsecLife = () => {
  return (
    <ZeronsecLifeStyle className="container-fluid p-0">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="ZeronsecLife_title">Life at Zeronsec</div>
        <div className="companyabout_imgslider ZeronsecLife_imgslider ">
          <div className="companyabout_imgslider_track">
            {images.map((img, index) => (
              <div className="companyabout_imgslider_slide" key={index}>
                <div className="companyabout_imgslider_box">
                  <img src={img} className="rounded-4" alt={`Image ${index + 1}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Slide>
    </ZeronsecLifeStyle>
  );
};

export default ZeronsecLife;
