import styled from 'styled-components';

import homeProtectBusinessBg from '../../../../Image/ZSHome/home-protect-business-bg.jpg';

const ProtectBusinessStyle = styled.div`
  position: relative;
  overflow: hidden;

  .container_left_content.container_left_content_1280 {
    padding-left: 0;

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1a3cdb;
    z-index: 2;
    opacity: 0.9;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${homeProtectBusinessBg});
    z-index: 1;
    filter: blur(15px);
  }

  .protectbusiness__slide {
    position: relative;
    z-index: 2;

    .protectbusiness__slide_wrapper {
      @media (min-width: 1000px) {
        max-width: 90% !important;
      }
      @media (min-width: 1700px) {
        max-width: 1400px !important;
      }
    }
  }

  .protectbusiness__content {
    color: var(--light);

    .protectbusiness__title {
      color: #fff;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.1;
    }
    .protectbusiness__para {
      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
    }
  }

  .protectbusiness__box {
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .protectbusiness__box__child {
    background: #09154f;
    border-radius: var(--5-radius);
    padding: 1rem 1.4rem;
    line-height: 15px;
    color: var(--light);
    display: flex;
    align-items: center;
    font-weight: var(--fw-600);
    fill: var(--light);
    margin-right: 1.1rem;

    &:hover {
      background: var(--light);
      color: var(--main-dark-clr);
      cursor: pointer;
      fill: var(--main-dark-clr);
    }

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }

  .protectbusiness__box__child p {
    white-space: pre-line;
    margin-left: 1.2rem;
  }

  .protectbusiness__box__footer {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.719);
    font-weight: var(--fw-300);
    display: flex;
    justify-content: end;
    margin-top: 10px;
    position: absolute;
    right: 6%;
    bottom: 10%;

    .scroll_d {
      cursor: pointer;
      transition: all 0.5s ease;
    }
  }
`;

export default ProtectBusinessStyle;
