import styled from 'styled-components';

const ProductBenifitsStyle = styled.div`
  padding: 0 2% 0% 3%;
  margin-top: 2.7rem;
  padding-bottom: 2rem;
  background: var(--main-light-bg);

  .AnritaBenifits_slider {
    display: grid;
    /* .carousel {
            .carousel-indicators, .carousel-control-prev, .carousel-control-next {
                display: none;
            }
        } */
  }

  .AnritaBenifits_slider_content {
    width: 700px;
    ${'' /* height:250px; */}
    background:red;
    margin: 10px;
  }

  .mainCard {
    background-color: #fff;
    border-radius: 21px;
    padding: 40px;
    height: 100%;
    min-height: 317px;
    cursor: pointer;

    @media (max-width: 900px) {
      min-height: 390px;
    }

    @media (min-width: 1500px) {
      min-height: 360px;
    }
    .wrapHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .header {
        width: 100%;
        color: #000;
        font-family: Poppins;
        font-size: 31px;
        font-style: normal;
        font-weight: 700;
        line-height: 116.4%; /* 36.084px */

        @media (max-width: 600px) {
          font-size: 1.3rem;
        }
      }
      .headerIcon {
        position: relative;
        top: -17px;
        width: 20%;
        display: flex;
        justify-content: end;

        svg {
          @media (max-width: 600px) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .description {
      color: #000;
      font-family: Poppins;
      font-size: 16px;
      margin-top: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 134.9%; /* 21.584px */
    }
  }

  .owl-stage-outer {
    overflow: visible;
  }

  .owl-item {
  }
  .owl-carousel {
    .owl-nav {
      button.owl-prev,
      button.owl-next {
        > span {
          color: rgb(18, 48, 189) !important;
        }
      }
    }
    button.owl-dot.active {
      span {
        background: rgb(18, 48, 189) !important;
      }
    }
  }
`;

export default ProductBenifitsStyle;
