export const anrita = [
  {
    name: 'Real Time Attack Detection',
    desc: 'Cyber attacks happen in real-time, and so should your cyber defence. Anrita detection algorithm inspects systems, network and security events real-time, detects anomalies and suspicious behaviour and generates real-time alert. Empowering you to take immediate action to prevent any damage.'
  },
  {
    name: 'Attack Path Visibility',
    desc: 'See how attacker is cruising through your network and security devices. Understand how attacker controlled devices in your network are communicating with attacker’s systems. Anrita provides real-time network visibility and actionable attack information, making it easy to analyse complex attacker communication and take timely remediation actions.'
  },
  {
    name: 'Centralized Log Management',
    desc: 'Collect, store, and analyze log data from various IT / OT / Cloud infrastructure and applications. Gain enhanced visibility into your log data, identify historical trends, patterns, and anomalies that may indicate potential security threats or operational issues. Easily generate compliance reports from historical logs and demonstrate that your organization is meeting regulatory requirements.'
  },
  {
    name: 'Reduced false positives',
    desc: 'Filter out noise and false alarms, focus on more critical security issues. By filtering out false positives, you can avoid wasting time and resources investigating non-critical events.'
  },
  {
    name: 'Correlation and Analysis',
    desc: 'Auto correlate attack information from various sources, including logs from network devices, servers, and applications. Detect and respond to threats more efficiently, potentially reducing the impact of a security incident.'
  }
];
export const ekasha = [
  {
    name: 'Performance propelling SLAs',
    desc: 'Set benchmarks for response times and quality. Fuel performance, offer measurable targets that inspire your team to reach new heights. Let SLAs navigate and prioritize your tasks based on urgency and significance.'
  },
  {
    name: 'Automate for efficiency, consistency, and round-the-clock protection',
    desc: 'Seamlessly scale with rising threats, offer swift and real-time responses. Let automation deliver round-the-clock protection with consistency and precision. Leverage automation to tirelessly manage high-volume tasks. Reduce error risks and enhance your security posture.'
  },
  {
    name: 'Purify alert volume, eliminate inaccuracies, understand what is critical',
    desc: 'Prioritize your alerts smartly, focusing on their impact and the criticality of your business assets. Be proactive with alerts from key assets, users, and data.'
  },
  {
    name: 'Efficiency meets expertise: seamless SOC ticket handovers',
    desc: 'Boost your SOC operations. Use distinctive timeline feature to put a spotlight on analyst activity tracking and facilitate effortless handovers. Immerse in the wave of heightened efficiency, from cybersecurity analysis to evidence collection and remediation.'
  }
];
