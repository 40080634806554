import React from 'react';
import DarkModal from '../../DarkModalFormat/Index';

import './emailmodal.scss';

const EmailModal = ({ showModal, handleCloseModal, email, emailError, handleEmailChange, handleSubmit, isLoading }) => {
  return (
    <DarkModal showModal={showModal} handleCloseModal={handleCloseModal}>
      <div>
        <div className="modal-header">
          <h5 className="modal-title pb-2">Book Your Assessment</h5>
          <button type="button" className="close" onClick={handleCloseModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="email_modal_title p-0 mb-2">Enter Your Email To Book Your Assessment:</p>
          <input type="email" className={`form-control mb-2 ${emailError ? 'is-invalid' : ''}`} placeholder="Enter Your Email" value={email} onChange={handleEmailChange} />
          {emailError && (
            <div
              className="invalid-feedback position-absolute "
              style={{
                top: '87px',
                fontSize: '13px',
                fontWeight: '600'
              }}
            >
              {emailError}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary d-flex justify-content-center align-items-center" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? (
              <>
                Booking...
                <div className="loading-spinner"></div>
              </>
            ) : (
              'Book'
            )}
          </button>
        </div>
      </div>
    </DarkModal>
  );
};

export default EmailModal;
