import ProductProtectStyle from './ProductProtectStyle.js';
import { Slide } from 'react-awesome-reveal';
import AnritaSvgComp from './AnritaSvgComp.js';
import { PRODUCTS } from '../../../../envData/ProductEnvData.js';
import ThreatISvgComp from './ThreatISvgComp.js';
import EkashaSvgComp from './EkashaSvgComp.js';

const ProductProtect = (props) => {
  const { productData } = props;

  let imageWidth = '100%'; // Default width

  if (productData.product === PRODUCTS.THREATI) {
    // Change the width for the 'threati' product
    imageWidth = '95%'; // Set the desired width for the threati image
  }

  return (
    <ProductProtectStyle className="container">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="ProductProtect">
          <div className="Anita-title text-center p-4" data-id={productData?.productProtectYou?.productImage}>
            {productData?.productProtectYou?.title}
          </div>
          {productData.product === PRODUCTS.ANRITA ? <AnritaSvgComp /> : null}
          {productData.product === PRODUCTS.THREATI ? <ThreatISvgComp /> : null}
          {productData.product === PRODUCTS.EKASHA ? <EkashaSvgComp /> : null}
        </div>
      </Slide>
    </ProductProtectStyle>
  );
};

export default ProductProtect;
