import React from 'react';

import CompanyGloablStyle from './CompanyGloablStyleStyle.js';
import ZSMap from '../../../../Component/ZSMap';
import { Slide } from 'react-awesome-reveal';

const CompanyGloabl = () => {
  return (
    <CompanyGloablStyle>
      <div className="container-fluid pb-5">
        <h1 className="mapTitle pb-5">Our Global Presence</h1>
        <div className="map_contain pb-5">
          <ZSMap />
        </div>
      </div>
    </CompanyGloablStyle>
  );
};

export default CompanyGloabl;
