import detactionimg from '../../../Image/ZSServices/CardImg/detection.png';
import analysisimg from '../../../Image/ZSServices/CardImg/analysis.png';
import responseimg from '../../../Image/ZSServices/CardImg/response.png';
import collectionimg from '../../../Image/ZSServices/CardImg/collection.png';

export const liveAttackCardItems = [
  {
    index: '1',
    points: 'We review your IT design, help configure your platforms and forward logs to our cloud.'
  },
  {
    index: '2',
    points: 'We configure and tune our cloud platforms to process and detect the threats.'
  },
  { index: '3', points: 'We start threat detection, hunting and analysis exercise.' },
  {
    index: '4',
    points: 'We deliver security analysis dashboards and reports to you.'
  }
];

export const socGapCardItems = [
  {
    index: '1',
    points: 'Reviewing your existing security operations design.'
  },
  {
    index: '2',
    points: 'Evaluating your SIEM, Threat Intelligence, Vulnerability Management, and SOAR implementations.'
  },
  {
    index: '3',
    points: 'Conduct integration check between key security operations technologies.'
  },
  { index: '4', points: 'Identifying missing SOC functions.' },
  { index: '5', points: 'Identifying gaps in your SOC processes.' },
  {
    index: '6',
    points: 'Providing actionable and preventative recommendations for remediation.'
  }
];

export const concernArrya = [
  {
    icon: 'unAuthorisedDataAccess',
    title: 'Unauthorized Data Access'
  },
  {
    icon: 'configurationMistakes',
    title: 'Configuration Mistakes'
  },
  {
    icon: 'insiderThreats',
    title: 'Insider Threats'
  },
  {
    icon: 'complianceChallenges',
    title: 'Compliance Challenges'
  },
  {
    icon: 'lackOfVisibility',
    title: 'Lack of Visibility'
  },
  {
    icon: 'threatDetectionAndResponse',
    title: 'Threat Detection and Response'
  },
  {
    icon: 'workloadProtection',
    title: 'Workload Protection'
  },
  {
    icon: 'cloudSecuritySkillShortage',
    title: 'Shortage of Cloud Security Skills'
  },
  {
    icon: 'APISecurityMonitoring',
    title: 'API Security Monitoring'
  }
];

export const plateforms = ['azure', 'aws', 'googleCloud', 'office'];

export const plateformsContent = {
  googleCloud: [
    'Google Compute Engine Threat Detection',
    'Google Cloud Storage Monitoring',
    'Google Kubernetes Engine (GKE) Security Monitoring ',
    'Google Cloud Functions Threat Detection',
    'Google Cloud IAM Monitoring',
    'Google Cloud VPC Traffic Analysis',
    'Google Cloud Pub/Sub Security Monitoring',
    'Google Cloud Security Command Center Integration ',
    'Google Cloud Firewall Monitoring'
  ],
  azure: [
    'Azure Virtual Machine (VM) Threat Detection',
    'Azure Active Directory (AAD) Monitoring',
    'Azure Storage Account Monitoring',
    'Azure Network Security Group (NSG) Analysis',
    'Azure SQL Database Threat Detection',
    'Azure App Service Threat Monitoring:',
    'Azure Kubernetes Service (AKS) Security Monitoring',
    'Azure Functions Threat Detection',
    'Azure Security Center Integration'
  ],
  office: [
    'Email Security',
    'User Behavior Analytics',
    'Data Loss Prevention',
    'Unauthorised Access Detection',
    'OneDrive, SharePoint, and Exchange Security Monitoring',
    'Office 365 Advanced Threat Protection (ATP) Monitoring',
    'Office 365 Audit Log Analysis',
    'Office 365 Compliance Monitoring',
    'Office 365 Incident Response'
  ],
  aws: [
    'Amazon EC2 Instance Threat Detection ',
    'Amazon S3 Bucket Monitoring',
    'AWS IAM Monitoring',
    'AWS Lambda Security Monitoring ',
    'Amazon RDS Database Threat Detection',
    'AWS CloudTrail Analysis',
    'AWS VPC Traffic Monitoring',
    'AWS GuardDuty Integration ',
    'AWS Security Hub Integration'
  ]
};

export const carouselArray = [
  {
    name: 'Monitor logs in real time',
    desc: 'Continuously monitor and analyze logs from cloud infrastructure and services for signs of suspicious activity, unauthorized access, or abnormal behaviour.'
  },
  {
    name: 'Detect intrusions and anomalies',
    desc: 'Detect unauthorized access attempts to your virtual machines, containers, cloud storage and other cloud resources. Identify and investigate anomalies in user behaviour, network traffic, or resource utilization within the cloud environment that may indicate potential security incidents.'
  },
  {
    name: 'Hunt and analyze network flows',
    desc: 'Monitor, analyze and alert on unusual network connections and volumes. Filter the clutter and focus on analyzing connections which matters to your investigation.'
  },
  {
    name: 'Ensure integrity of data, configuration files and applications',
    desc: 'Monitor for threat indicators such as file integrity changes, unusual configuration changes, or malicious code execution within cloud instances or applications.'
  },
  {
    name: 'Keep eye on your data operations, prevent data breach.',
    desc: 'Monitor data transfers and access patterns to detect potential data breaches or unauthorized exfiltration of sensitive information from your cloud environment.'
  },
  {
    name: 'Manage identity and access',
    desc: 'Detect unusual account activities, unauthorized privilege escalations, or suspicious account behaviour.'
  },
  {
    name: 'Security group and firewall monitoring',
    desc: 'Monitor network security groups, firewalls, and access control policies to identify misconfigurations, unauthorized network access attempts, or policy violations.'
  },
  {
    name: 'Monitor API',
    desc: 'Monitor and analyze API calls to cloud service provider interfaces, identifying potentially malicious or unauthorized activities.'
  },
  {
    name: 'Scan for cloud infrastructure vulnerability',
    desc: 'Conduct regular vulnerability scans on cloud infrastructure components to identify and prioritize security weaknesses, ensuring timely patching and mitigation.'
  },
  {
    name: 'Swiftly respond to incidents and threat hunting',
    desc: 'Investigate incidents, proactively hunt threats, and perform post-incident analysis to identify the root cause, enhance security controls, and prevent future incidents.'
  }
];

export const carouselArray2 = [
  {
    name: 'Best-in-class technology stack',
    desc: 'An array of hi-tech security operations platforms and specialised services that are precisely curated and in accordance with needs.'
  },
  {
    name: 'World class security analytics and reporting',
    desc: 'Custom built dashboards and automatically delivered reports on your scheduled time with all necessary, actionable, and remedy information suiting needs of all the IT stakeholders.'
  },
  {
    name: 'Advanced threat detection and hunting',
    desc: 'MITRE ATT&CK based detection framework and graphical chart-based hunting capabilities to enhance deeper threat visibility.'
  },
  {
    name: 'Swiftly mapping your security operations needs to solutions',
    desc: 'Through agile BRM, we swiftly identify your needs, then plan and implement efficient cyber threat monitoring and remediation services'
  },
  {
    name: 'Targetted attacks forensic with IOC extraction',
    desc: 'With a blend of seasoned attack investigators and cyber research mavens, we wield our in-house cyber forensic tools to dissect malicious files and URLs.'
  },
  {
    name: 'Comprehensive incident lifecycle management with precision',
    desc: 'Harnessing our unique homegrown tools, our expert analysts manage the entire incident lifecycle. Trained in handling cyber complexities, we are your dependable partner, ensuring your digital world is secure.'
  }
];

export const benefitsArray = [
  {
    icon: 'lackOfVisibility',
    title: 'Substantial Threat Visibility'
  },
  {
    icon: 'complianceChallenges',
    title: 'Streamline Compliance'
  },
  {
    icon: 'roundClockDetection',
    title: 'Round-the-clock Threat Detection'
  },
  {
    icon: 'experenceProwess',
    title: 'Experience With Prowess'
  },
  {
    icon: 'agileResponse',
    title: 'Agile Response'
  }
];

export const workFlow = [
  {
    title: 'COLLECTION',
    description:
      'We meticulously gather attack data, encapsulated within logs and netflow packets, from a comprehensive array of IT assets including endpoints, networks, systems, cloud infrastructures, and applications. Utilizing our advanced threat intelligence, we proactively identify potential attack vectors right from the collection phase.',
    label: 'STEP 01',
    image: collectionimg
  },
  {
    title: 'DETECTION',
    description:
      'Employing our comprehensive detection rule base and sophisticated threat intelligence, we scrutinize attack data for indicators of suspicious activities, compliance infringements, user/entity behavioral irregularities, anomalies, malware, ransomware, botnet activities, and dark web threat actors. Any detected threat triggers an automatic alert to your team and our dedicated investigators.',
    label: 'STEP 02',
    image: detactionimg
  },
  {
    title: 'ANALYSIS AND INVESTIGATION',
    description:
      'Utilizing an automated system, incidents are efficiently prioritized in alignment with the criticality of your assets, users and data. Those emanating from crucial assets are immediately accorded high-priority status, ensuring prompt and appropriate response measures.',
    label: 'STEP 03',
    image: analysisimg
  },
  {
    title: 'RESPONSE',
    description:
      'Leveraging sophisticated algorithms and automation, our guided remediation process ensures swift containment and action response, accelerating threat elimination and minimising potential impact on your systems.',
    label: 'STEP 04',
    image: responseimg
  }
];
