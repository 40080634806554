/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './CarouselStyle.css';
const Index = (props) => {
  const { children, nav, dots, center, responsive, margin } = props;
  return (
    <OwlCarousel
      className="owl-theme"
      loop
      nav={nav}
      dots={dots}
      dotsEach={true}
      autoplayHoverPause={true}
      autoplay={false}
      center={center}
      margin={margin || 8}
      smartSpeed={1000}
      responsive={responsive}
      autoHeightClass="owl-loaded"
      {...props}
    >
      {children}
    </OwlCarousel>
  );
};

export default Index;
