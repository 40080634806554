import styled from 'styled-components';

const CompanyValueStyle = styled.div`
  background: var(--main-dark-bg);
  padding: 5rem 0;
  display: none;
  @media (max-width: 550px) {
    padding: 2rem 0;
  }
  @media (min-width: 770px) and (max-width: 1000px) {
    padding: 14rem 0 4rem;
  }
  .CompanyValue_title {
    color: #fff;
    text-align: center;
    font-size: 48.432px;
    font-style: normal;
    font-weight: 700;
    line-height: 99.9%; /* 48.384px */
    text-transform: capitalize;
    @media (max-width: 770px) {
      font-size: 34px;
      padding-bottom: 1rem !important;
    }
  }

  .CompanyValue_slider {
    height: 100px;
    margin: auto;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    &::before,
    &::after {
      background: linear-gradient(to right, rgb(29 62 188) 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      height: 100%;
      position: absolute;
      width: 15%;
      z-index: 2;
      display: none;
    }

    &::before {
      left: 0;
      top: 0;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }
  }
  .CompanyValue_slider_track {
    display: flex;
    width: calc(233px * 12);
    white-space: nowrap;
    animation: scroll 15s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-233px * 4));
    }
  }
  @keyframes reversscroll {
    0% {
      transform: translateX(calc(-233px * 4));
    }
    100% {
      transform: translateX(0);
    }
  }
  .CompanyValue_slider_box {
    width: 233px;
    height: 85px;
    border-radius: 0.5rem;
    padding: 10px;
    margin: 0 10px;
    perspective: 100px;
  }

  .CompanyValue_slider_box_content {
    color: #1b1b1b;
    leading-trim: both;
    text-edge: cap;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 121.9%; /* 24.38px */
    letter-spacing: -0.5px;
    text-transform: capitalize;
    @media (max-width: 770px) {
      font-size: 18px;
    }
  }

  .CompanyValue_slider_track2 {
    display: flex;
    width: calc(233px * 12);
    animation: reversscroll 15s linear infinite;
  }
`;

export default CompanyValueStyle;
