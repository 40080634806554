import styled from 'styled-components';
import CarouselBg from '../../../Image/ZSHomeSliderImg/carouselBg.png';

const ZeronsecService = styled.div`
  animation: moveFromCenter 2s ease;

  .service-title {
    font-family: Poppins;
    font-size: 57.13px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    /* color:  #1A3CDB; */
    color: var(--main-dark-bg);
    @media screen and (max-width: 900px) {
      font-size: 32.13px;
    }
  }
  .service {
    .padding-right-200 {
      padding-right: 0px;
      padding-left: 90px;
      margin-top: 6rem !important;
      @media (max-width: 900px) {
        padding-left: 2rem;
        margin-top: 3rem !important;
      }
      @media (max-width: 550px) {
        margin-top: 0 !important;
        padding: 0 2rem;
      }
    }
    .title {
      font-family: Poppins;
      font-size: 17px;
      font-weight: 700;
      line-height: 26px;
      color: #000000;
    }
    .desc {
      font-family: Poppins;
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      color: #232323;
    }
    .image {
      background-color: #e6e8f3;
      /* min-height: 450px; */
    }
    .concern_heading {
      color: var(--main-dark-bg);
      font-family: Poppins;
      font-size: 28px;
      font-weight: 700;
      line-height: 37px;
      @media (max-width: 900px) {
        text-align: center;
      }
    }
    .z-index-1 {
      z-index: 1;
    }
  }
  .benefitsContainer {
    padding: 4.5rem 0 2.5rem;

    .concern_heading {
      font-size: 2rem;
      margin-bottom: 0.6rem;
    }
  }

  .boxContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.8rem;
    .squerBox {
      background-color: var(--main-light-clr);
      height: 176px;
      width: 190px;
      border-radius: 9px;
      padding: 23px 23px 23px 22px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* margin: 6px 9px; */
      transition: transform 0.3s;
      box-shadow: 0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05);
      .box-title {
        color: #1b1b1b;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.025em;
        text-align: left;
      }
      > svg {
        width: 55px;
      }
      :hover {
        background-color: var(--main-dark-bg);
        transform: scale(1.05);
        align-items: flex-end;
        > svg {
          width: 65px;
        }
        .box-title {
          color: var(--light);
        }
        svg path {
          fill: var(--light);
          /* stroke: var(--light); */
        }
      }
    }
  }
  .contact {
    input {
      width: 80%;
      height: 43px;
      border-radius: 5px;
      border: none;
      background: #f1f1f1;
      box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.07) inset;
      padding: 15px;
    }
  }
  .collapseContent {
    max-width: 522px;
    padding-top: 50px;
    margin-left: 2rem;
    min-height: 570px;
    @media (max-width: 900px) {
      max-width: 380px;
      min-height: unset;
    }
    @media (max-width: 600px) {
      max-width: unset;
    }
    @media (max-width: 400px) {
      padding-top: 30px;
    }
  }
  .accordianImageContainer {
    @media (max-width: 600px) {
      margin-top: 1rem;
    }
    image {
      @media (max-width: 600px) {
        max-width: unset;
      }
    }
    .accordianImage {
      position: absolute;
      top: 0;
      height: 500px;
      width: 84%;
      left: 6rem;
      object-fit: cover;
      border-radius: 0.5rem;
      object-position: bottom;
      @media (max-width: 900px) {
        object-fit: cover;
        border-radius: 0.5rem;
      }
      @media (max-width: 600px) {
        position: unset;
        height: auto;
        width: 100%;
      }
    }
  }
  .carousel-container {
    min-height: 400px;
    /* background: #1a3cdbf0; */
    width: 100%;
    position: relative;
    margin-left: 0;
    padding-bottom: 50px;

    .owl-carousel.owl-theme {
      padding-left: 153px;
      @media screen and (max-width: 900px) {
        padding-left: 2rem;
      }
    }
    .carousel-heading {
      color: rgb(255, 255, 255);
      font-family: Poppins;
      font-size: 57.126px;
      font-style: normal;
      font-weight: 700;
      padding: 0px 24px;
      padding-left: 153px;
      z-index: 1;
      margin-top: 80px;
      @media screen and (max-width: 900px) {
        padding: 0 2rem 2rem !important;
        margin-top: 60px;
      }
    }
    .owl-theme {
      /* margin-top: 60px; */
      .owl-nav {
        @media screen and (max-width: 900px) {
          justify-content: space-between;
        }
      }
      .owl-dots {
        .owl-dot {
          span {
            margin: 5px 15px;
          }
        }
      }
    }
    .owl-dots,
    .owl-nav {
      padding-right: 153px;
      @media screen and (max-width: 900px) {
        padding-right: unset;
      }
    }
    .owl-item.center .carousel-box-item {
      background-color: #fff;
      color: #000;
      /* height: 500px;; */
      padding: 45px 20px;
      transition-property: color, padding, background-color;
      transition-duration: 0.6s;
      transition-timing-function: linear;
      height: 216px;
    }
    .carousel-box-item {
      border: 0px solid #57a1d8;
      background: rgba(0, 0, 0, 0.31);
      backdrop-filter: blur(27.5px);
      border-radius: 9px;
      padding: 32px 40px 25px;
      /* transition: background-color 0.6s linear, color 0.6 linear; */
      color: #f5f5f5;
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: linear;
      min-height: 180px;
      @media screen and (max-width: 550px) {
        padding: 2rem !important;
      }
      .header {
        /* text-align: center; */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px; /* 100% */
        border-radius: 6px;
        border: 0px solid #57a1d8;
        letter-spacing: 0.05em;
        cursor: pointer;
      }
      .description {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 0.75px;
        line-height: 15px;
        /* box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05); */
        overflow: hidden;
        cursor: pointer;
      }
    }
    :before {
      content: '';
      background-image: url(${CarouselBg});
      /* background-position: center; */
      background-size: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      /* opacity: 0.1;
      filter: blur(7px); */
    }
    .owl-stage-outer {
      min-height: 240px;
      display: flex;
      align-items: center;
    }

    .owl-item:not(.active) + .owl-item.active .carousel-box-item {
      background: #fff;
      padding: 45px 50px 25px;
      min-height: 216px;
      height: unset;
      max-height: auto !important;
      color: #000;
      .header {
        font-size: 19.65px;
        line-height: 22.93px; /* 100% */
      }
      .description {
        font-size: 17.47px;
        letter-spacing: 0.75px;
        line-height: 22.93px;
        letter-spacing: 0.005em;
        overflow: hidden;
      }
    }
    .owl-stage {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 600px) {
    .carousel-heading {
      font-size: 35px !important;
      padding: 0 5% !important;
      line-height: 45px !important;
    }
    .owl-carousel.owl-theme {
      padding: 0 5% !important;
    }
    .padding-right-200 {
      /* padding-right: 15px !important;
      padding-left: 15px !important; */
      margin-top: 0px !important;
    }
  }
`;

export const CollapseConentStyle = styled.div`
  .card {
    box-shadow: none;
    border: none;
    margin-bottom: 15px;
    z-index: 1;

    .card-header {
      background-color: transparent;
      border-bottom: none;
      cursor: pointer;
      padding: 2.2rem;
      box-shadow: 0px 25.07692px 50.15384px 12.53846px rgba(0, 0, 0, 0.05);
      @media (max-width: 5500px) {
        padding: 1.5rem;
      }

      &:not(.collapsed) {
        padding: 0.7rem 0.7rem 0;
      }
      button {
        color: #1230bd;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 1; /* 18.06px */
        letter-spacing: 0.525px;
        border: none !important;
        padding: 1rem;
        text-align: left;
      }
    }
    .card-body {
      padding: 0px 28px 28px;
      font-size: 12px;
      font-family: Poppins;
      font-weight: 400;
      /* line-height: 14px; */
    }
    :has(.show, .collapsing) {
      background-color: #1230bd;
      .card-header button,
      .card-body {
        color: #fff;
      }
      ::before {
        opacity: 1;
      }
    }

    :last-child {
      margin-bottom: 0;
    }
    ::before {
      content: attr(data-before);
      text-transform: uppercase;
      color: #1230bd;
      position: absolute;
      left: -50px;
      transform: rotate(270deg) translate(12px, -50%);
      top: 50%;
      font-weight: 700;
      opacity: 0.4;
      /* transition: opacity 0.2s linear; */

      @media (max-width: 600px) {
        left: -35px;
      }
    }
  }
  .card-body {
    /* min-height: calc(288px - 75.19px); */
    /* min-height: 170px; */
  }
`;

export default ZeronsecService;
