import styled from 'styled-components';

const TermsAndConditionPolicyStyle = styled.div`
a {
    color: blue !important;
    font-weight: 600;
}
`;

export default TermsAndConditionPolicyStyle;
