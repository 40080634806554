// AssessmentLightCard.js
import React from 'react';

const AssessmentLightCard = ({ items, marginBottom }) => (
  <div className="assesmentLight_card_bg">
    {items.map((item, index) => (
      <div className="d-flex align-items-center assesmentLight_card_bg_box " style={{ marginBottom: marginBottom, position: 'relative' }} key={index}>
        <p className="assesmentLight_card_index">{item.index}</p>
        <p className="assesmentLight_card_points">{item.points}</p>
      </div>
    ))}
  </div>
);

export default AssessmentLightCard;
