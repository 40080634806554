import styled from 'styled-components';

const CyberDefensesStyle = styled.div`
  padding: 4rem 0;
  padding-top: 5.5rem;

  @media (max-width: 600px) {
    padding-bottom: 0;
  }

  .CyberDefenses__para {
    font-weight: var(--fw-500);
    text-align: center;

    @media (max-width: 600px) {
      font-size: 0.9rem;
    }
  }

  .CyberDefenses__needs {
    letter-spacing: 2px;
    color: var(--light-gray);
    font-weight: var(--fw-600);
    text-align: center;
    margin-bottom: 4.5rem;

    @media (max-width: 600px) {
      margin-bottom: 2rem;
    }
  }

  .active {
    background-color: var(--main-dark-bg);
    color: var(--light);
    fill: gold;
  }
`;

export default CyberDefensesStyle;
