import styled from 'styled-components';

const ProductAboutStyle = styled.div`
  height: 100%;
  .AnritaAbout_main {
    padding: 0 !important;
  }
  .AnritaAbout {
    background: var(--main-dark-bg);
    padding: 8% 15px 15px 15px;
  }
  .scroll_features {
    cursor: pointer;
  }
  .AnritaAbout_box {
    position: relative;
  }
  .enrita_logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .AnritaAbout_Prod_img {
    width: 93%;
    margin-left: 2rem;
    margin-top: 1.6rem;
    @media (max-width: 600px) {
      width: 100%;
      margin-left: 0;
    }
  }

  .AnritaAbout_content {
    padding-left: 2rem;
    margin-top: 1rem;
    padding-right: 1rem;

    @media (max-width: 600px) {
      padding-left: 1rem;
    }
  }

  .AnritaAbout_title {
    color: #fff;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 134.9%;

    @media (max-width: 600px) {
      font-size: 0.875rem;
    }
  }
  .AnritaAbout_para {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 134.9%;
  }
  .AnritaAbout_btn {
    margin: 10px 10px 0 0;
  }
  .AnritaAbout_btn_right {
    background: transparent;
    color: #fff;
  }
  .AnritaAbout_down_icon {
    margin: 3% 6%;
  }
`;

export default ProductAboutStyle;
