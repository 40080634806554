import styled from 'styled-components';

const ZSLargeDarkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 12.715px;
  font-weight: 800;
  border-radius: 4.172px;
  border: 0px solid #57a1d8;
  background: #1b3dde;
  padding: 0.61rem 1rem;
  flex-shrink: 0;
  transition: all 0.5s ease;
  border: 2px solid #1b3dde;

  &:hover {
    background: none !important;
    color: var(--main-dark-clr);
  }
`;

export default ZSLargeDarkButton;
