import React from 'react';

const ZsHomeTabContent = ({ items }) => {
  return (
    <div className="container px-3 d-flex gap-3 justify-content-center flex-wrap mt-5">
      {items.map((item, index) => (
        <div key={index} className="ZsHomeTabKey1_content" style={{ width: '200px ' }}>
          <p className="m-0">{item}</p>
        </div>
      ))}
    </div>
  );
};

export const ZsHomeTabKey1 = () => {
  const items = [
    'Managing security logs and ensuring compliance with regulatory standards',
    'Dealing with the growing sophistication of cyber threats and evolving threat landscape',
    'Detecting and hunting threats real-time before they impact any business operations',
    'Correlating security events to detect attack patterns',
    'Visualizing and analysing complex attack with simplicity'
  ];

  return <ZsHomeTabContent items={items} />;
};

export const ZsHomeTabKey2 = () => {
  const items = [
    'Managing high volumes of security alerts and false positives',
    'Identifying and prioritizing critical alerts that require immediate attention',
    'Keeping track of security incidents, response activities, and remediation progress',
    'Collaboration between analysts, threat hunters, threat intelligence team, and incident responders',
    'Developing and testing incident response plans and playbooks'
  ];

  return <ZsHomeTabContent items={items} />;
};

export const ZsHomeTabKey3 = () => {
  const items = ['Maintaining up-to-date database of curated IOCs', 'Visualizing live cyber-attacks', 'Visualizing malicious communications in progress'];

  return <ZsHomeTabContent items={items} />;
};
