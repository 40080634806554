import React, { useState } from 'react';
import ImageFullScreenStyle from './ImageFullScreenStyle.js';

const ImageFullScreen = ({ images, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const activeImage = images[currentImageIndex];

  const handleNextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ImageFullScreenStyle>
      <div className="image-full-screen">
        <i className="fa-solid fa-xmark" onClick={handleClose}></i>
        <div className="image-container">
          <span className="closePopup" onClick={handleClose}>
            &times;
          </span>
          {activeImage.isVideo ? (
            <video controls autoPlay className="fullscreen-video" width={'75%'} onClick={handleClose}>
              <source src={activeImage.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={activeImage.src} alt="Fullscreen" className="fullscreen-image" onClick={handleClose} />
          )}
        </div>
        <div className="image-controls">
          <div className="left-button" onClick={handlePreviousImage}>
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <div className="right-button" onClick={handleNextImage}>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </ImageFullScreenStyle>
  );
};

export default ImageFullScreen;
