import styled from 'styled-components';
import CarouselBg from '../../../Image/ZSHomeSliderImg/carouselBg.png';

const ZeronsecService = styled.div`
  animation: moveFromCenter 2s ease;

  .service-title {
    font-family: Poppins;
    font-size: 57.13px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--main-dark-bg);

    @media screen and (max-width: 900px) {
      font-size: 32.13px;
      line-height: 45px;
    }
  }
  .service {
    .padding-right-200 {
      padding-right: 0;
      padding-left: 90px;
      @media (max-width: 900px) {
        padding: 0 0 0 2rem;
      }
      @media (max-width: 550px) {
        padding: 0 2rem;
      }
    }
    .title {
      color: #000;
      font-family: Poppins;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
    }
    .desc {
      color: #232323;
      font-family: Poppins;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
    }

    .concern_heading {
      color: var(--main-dark-bg);
      font-family: Poppins;
      font-size: 32px;
      font-weight: 700;
      line-height: 37px;
      margin-top: 50px;

      @media (max-width: 600px) {
        text-align: center;
      }
    }
  }
  .boxContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 600px) {
      justify-content: space-between;
    }

    .squerBox {
      background-color: var(--main-light-clr);
      height: 176px;
      width: 190px;
      border-radius: 9px;
      padding: 23px 23px 23px 22px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 6px 9px;
      transition: transform 0.3s;

      @media (max-width: 600px) {
        width: 43%;
      }

      .box-title {
        color: #1b1b1b;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.025em;
        text-align: left;

        @media (max-width: 600px) {
          font-size: 1rem;
        }
      }

      :hover {
        background-color: var(--main-dark-bg);
        transform: scale(1.05);
        align-items: flex-end;
        .box-title {
          color: var(--light);
        }
        svg path {
          fill: var(--light);
          /* stroke: var(--light); */
        }
      }
    }
  }
  .carousel-container {
    min-height: 490px;
    width: 100%;
    /* background: rgba(26, 60, 219, 0.43);
    backdrop-filter: blur(18.5px); */
    position: relative;
    padding-bottom: 10px;

    .carousel-heading {
      color: rgb(255, 255, 255);
      font-family: Poppins;
      font-size: 57.126px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      -webkit-box-align: end;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      text-align: center;
      padding: 0px 23%;
      min-height: 200px;
      line-height: 60px;
      z-index: 1;
      position: relative;

      @media (max-width: 768px) {
        padding-top: 4rem;
        margin-bottom: 2rem;
        font-size: 2.4rem;
        padding: 0 10%;
      }
    }

    :before {
      content: '';
      background-image: url(${CarouselBg});
      /* background-position: center; */
      background-size: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      /* opacity: 0.1;
      filter: blur(7px); */
    }
    .header {
      /* color: #1A3CDB; */
      text-align: center;
      font-family: Poppins;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px; /* 100% */
      height: 113px;
      border-radius: 6px;
      border: 0px solid #57a1d8;
      background: #fff;
      box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
      display: grid;
      place-items: center;
      padding: 20px;
      min-width: 300px;
      width: 564px;
      cursor: pointer;
    }
    .description {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.05em;
      line-height: 20.05px;
      border-radius: 6px;
      border: 0px solid #57a1d8;
      background: #09154f;
      box-shadow: 0px 27.167009353637695px 54.33401870727539px 13.583504676818848px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      height: 0px;
      transition: height 0.8s ease-out;
      width: 538px;
      display: grid;
      cursor: pointer;

      @media (max-width: 600px) {
        font-size: 0.8rem;
        padding: 0 1rem;
        width: unset;
      }

      > div {
        width: 382px;
        margin: auto !important;

        @media (max-width: 600px) {
          width: unset;
        }
      }
    }
    .owl-stage .center .description {
      height: 174px;
      /* padding: 10px; */
    }
    .owl-stage-outer,
    .owl-stage {
      height: 305px;
    }
    .owl-stage {
      display: flex;
      align-items: center;
    }
    .owl-dots {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-bottom: 80px;
      margin-top: 10px;
    }
    .owl-carousel .owl-nav button.owl-next,
    .owl-carousel .owl-nav button.owl-prev {
      padding: 15px 20px !important;
    }
    .owl-theme .owl-nav {
      margin-top: 35px;
    }
  }

  @media (max-width: 600px) {
    .carousel-heading {
      font-size: 35px !important;
      padding: 0 5% !important;
      line-height: 45px !important;
    }
    .padding-right-200 {
      /* padding-right: 15px !important;
      padding-left: 25px !important; */
      padding: 0 2rem;
      margin-top: 0px !important;
    }
    .carousel-container .header {
      max-width: calc(100% - 25px);
    }
    .owl-theme .owl-nav {
      justify-content: space-between;
    }
  }
`;

export default ZeronsecService;
