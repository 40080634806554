import React from 'react';
import axios from 'axios';
import ZSAssesmentServicesStyle from './ZSAssesmentServicesStyle.js';
import ServiceHeaderTwo from '../ModuleComponent/ServiceHeaderTwo.js';
import AssesmentDarkCard from '../ModuleComponent/AssesmentDarkCard .js';
import AssessmentLightCard from '../ModuleComponent/AssessmentLightCard.js';
import { liveAttackCardItems, socGapCardItems } from '../ServiceData/Index.js';
import AssessmentExpert from '../Lib/AssessmentExpert/Index.js';
import assementimg from '../../../Image/ZSServices/Assessment Services.png';
import DownloadModal from '../../../Component/ZSModal/Lib/DownloadModal/Index.js';
import { DownloadValidation } from '../../../Component/ZSModal/Lib/DownloadModal/DownloadValidation.js';
import AlertToken from '../../../Component/AlertTokan/Index.js';
import LiveAttackPdf from '../../../Image/ZSServices/Datasheet - Live Attack Discovery.pdf';
import SOCGappdf from '../../../Image/ZSServices/Datasheet - SOC Gap Assessment.pdf';
import { useState } from 'react';
import BookingModal from '../../../Component/ZSModal/Lib/BookingModal/Index.js';
import { Slide } from 'react-awesome-reveal';

const ZSAssesmentServices = () => {
  const [showModal, setShowModal] = useState(false);
  const [bookingShowModal, setBookingShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    acceptTerms: false,
    pdfPath: '', // Store the PDF path dynamically
    pdfFileName: '', // Store the PDF file name dynamically
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    phoneNumberError: '',
    acceptTermsError: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Define handleDemoButtonClick function for the "Know More" button
  const handleDemoButtonClick = (assessmentType) => {
    setShowModal(true);

    let pdfPath = '';
    let pdfFileName = '';
    let documentTitle = '';

    if (assessmentType === 'LiveAttackBTN') {
      // Import the PDF path for Live Attack
      pdfPath = LiveAttackPdf;
      pdfFileName = 'Datasheet - Live Attack Discovery.pdf';
      documentTitle = 'Datasheet - Live Attack Discovery';
    } else if (assessmentType === 'SOCGapBTN') {
      // Import the PDF path for SOC Gap
      pdfPath = SOCGappdf;
      pdfFileName = 'Datasheet - SOC Gap Assessment.pdf';
      documentTitle = 'Datasheet - SOC Gap Assessment';
    }

    setFormData({
      ...formData,
      pdfPath,
      pdfFileName,
      documentTitle
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    setSendError(false);
    setShowSuccess(false);
    const fieldName = e.target.name;
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
      [`${fieldName}Error`]: '' // Clear the error for the corresponding field
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = DownloadValidation(formData);

    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, ...errors });
      return;
    }

    setIsLoading(true); // Start loading

    const pdfBlob = await fetch(formData.pdfPath).then((res) => res.blob());

    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = formData.pdfFileName;
    document.body.appendChild(downloadLink);

    const instance = axios.create({
      baseURL: `https://${window.location.hostname}:5000`,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      }
    });
    const apiData = { method:'get', url:`/datasheet?title=${formData.pdfFileName}&email=${formData.email}&lastname=${formData.lastName}&contact=${formData.phoneNumber}&firstname=${formData.firstName}` };
    instance(apiData)
      .then((response) => {
        const { status, type } = response.data;
        if (status) {
          if (type === 'Datasheet') {
            setShowSuccess(true);
            downloadLink.click();
            URL.revokeObjectURL(pdfUrl);
            document.body.removeChild(downloadLink);
            setIsLoading(false); // Stop loading
            setShowModal(false);
            resetForm();
          }
        }
        return response.data;
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          setSendError(true);
          setIsLoading(false);
          setShowModal(false);
          resetForm();
        }
        return error.message;
      });
  };

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      acceptTerms: false,
      pdfPath: '',
      pdfFileName: ''
    });
  };

  const handleOpenModal = () => {
    setBookingShowModal(true);
  };

  const handleCloseModal = () => {
    setBookingShowModal(false);
  };

  return (
    <ZSAssesmentServicesStyle className="">
      <ServiceHeaderTwo
        title={
          <>
            Assessment <br /> Services
          </>
        }
        content={
          <>
            Assess your cyber security with our holistic service. Gain clear insights into potential attackers' presence and their stages of operation based on the MITRE Attack Framework. Uncover
            hidden gaps within your Security Operations Center (SOC) and identify discrepancies within your SOC infrastructure and configurations. With us, informed action enhances your defences and
            fortifies your cyber security posture.
          </>
        }
        imgsrc={assementimg}
        name="AssessmentBTN"
        text="Book a Demo"
        onclick={handleOpenModal}
      />
      <div className="container-fluid assesmentDark_card ">
        <Slide direction="up" triggerOnce duration={1500} delay={100}>
          <div className="assesment_card_main">
            <div className="assesment_card_child">
              <AssesmentDarkCard
                title={
                  <>
                    Live Attack <br /> discovery
                  </>
                }
                content={
                  <>
                    Our live attack discovery services help you detect the attacker's presence in your environment and help you see live attacker activities. It also help you understand the origin of
                    the attacker, your compromised assets, and at what attack stage the attacker is operating. <br />
                    <br />
                    In this advanced technical exercise, we leverage our advanced security analytics platforms along with threat intelligence and threat detect rule base to detect an array of threats.
                  </>
                }
                onClick={() => handleDemoButtonClick('LiveAttackBTN')}
                name="LiveAttackBTN"
              />
              <AssessmentLightCard items={liveAttackCardItems} marginBottom="20px" /> {/* Use the new component */}
            </div>
            <div className="assesment_card_child">
              <AssesmentDarkCard
                title={
                  <>
                    SOC Gap <br /> Assessment
                  </>
                }
                content={
                  <>
                    The core of a security operations centre is a combination of people, process, and technology.
                    <br />
                    <br />
                    To achieve a flawless SOC state, Zeronsec addresses and pinpoints all the shortcomings in the current infrastructure with respect to them. We assess skills and competencies of
                    people involved in security operations, technologies used in building SOC integration, and processes used in running security operations.
                  </>
                }
                onClick={() => handleDemoButtonClick('SOCGapBTN')}
                name="SOCGapBTN "
              />
              <AssessmentLightCard items={socGapCardItems} marginBottom="20px" /> {/* Use the new component */}
            </div>
          </div>
        </Slide>
      </div>
      <AssessmentExpert />
      <BookingModal showModal={bookingShowModal} handleCloseModal={handleCloseModal} />
      <DownloadModal showModal={showModal} closeModal={closeModal} formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} isLoading={isLoading} />
      {/* Show success alert */}
      <AlertToken
        variant="success"
        show={showSuccess}
        message={
          <>
            Thank you for downloading the datasheet!
            <br />
            <br />
            We appreciate your interest. Have a great day!
          </>
        }
        duration={10000}
        onClose={() => setShowSuccess(false)}
      />
      {/* Show error alert */}
      <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." duration={10000} onClose={() => setSendError(false)} />
    </ZSAssesmentServicesStyle>
  );
};

export default ZSAssesmentServices;
