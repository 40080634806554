import React from 'react';
import ButtonIndex from '../../../Component/ZSForm/ZSButtons/Index.js';

const AssesmentDarkCard = ({ title, content, onClick, name }) => {
  return (
    <div className="assesmentDark_card_bg rounded-3">
      <div className="assesmentDark_card_title">
        <p className="m-0">{title}</p>
      </div>
      <div className="assesmentDark_card_para">
        <p className="m-0 py-4">{content}</p>
      </div>
      <div className="d-flex justify-content-end">
        <ButtonIndex ButtonType="Light" className="knowMoreBtn" name={name} text="Know More" onClick={onClick} />
      </div>
    </div>
  );
};

export default AssesmentDarkCard;
