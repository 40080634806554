import React, { useState } from 'react';
import axios from 'axios';
import ButtonIndex from '../../../../Component/ZSForm/ZSButtons/Index';
import Icon from '../../../../Icon/Index';
import { useScrollReveal } from '../../../../Helper/Index';
import ProductAboutStyle from './ProductAboutStyle.js';
import { Link } from 'react-router-dom';
import DownloadModal from '../../../../Component/ZSModal/Lib/DownloadModal/Index';
import { DownloadValidation } from '../../../../Component/ZSModal/Lib/DownloadModal/DownloadValidation.js';
import AlertToken from '../../../../Component/AlertTokan/Index'; // Correct import path
import AnritaPDF from '../../../../Image/ZSProducts/Datasheet - Anrita.pdf';
import EkashaPDF from '../../../../Image/ZSProducts/Datasheet - Ekasha.pdf';
import ThreatiPDF from '../../../../Image/ZSProducts/Datasheet - Threat-i.pdf';

const ProductAbout = (props) => {
  const { productData, setShowSuccess, setSendError } = props;
  const { ref, isInViewport } = useScrollReveal();
  const [isLoading, setIsLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    acceptTerms: false,
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    phoneNumberError: '',
    acceptTermsError: ''
  });

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      acceptTerms: false
    });
  };

  const handleInputChange = (e) => {
    setSendError(false);
    setShowSuccess(false);
    const fieldName = e.target.name;
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
      [`${fieldName}Error`]: '' // Clear the error for the corresponding field
    });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = DownloadValidation(formData);

    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, ...errors });
      return;
    }

    setIsLoading(true); // Start loading

    let pdfBlob;
    let pdfUrl;
    let downloadLink;
    let documentTitle;

    if (productData.product === 'anrita') {
      pdfBlob = await fetch(AnritaPDF).then((res) => res.blob());
      documentTitle = 'Datasheet - Anrita';
    } else if (productData.product === 'ekasha') {
      pdfBlob = await fetch(EkashaPDF).then((res) => res.blob());
      documentTitle = 'Datasheet - Ekasha';
    } else if (productData.product === 'threati') {
      pdfBlob = await fetch(ThreatiPDF).then((res) => res.blob());
      documentTitle = 'Datasheet - Threat-i';
    }

    pdfUrl = URL.createObjectURL(pdfBlob);
    downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `${documentTitle}.pdf`; // Customize the file name
    document.body.appendChild(downloadLink);

    const instance = axios.create({
      baseURL: `https://${window.location.hostname}:5000`,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      }
    });
    const apiData = { method:'get', url:`/datasheet?title=${documentTitle}&email=${formData.email}&lastname=${formData.lastName}&contact=${formData.phoneNumber}&firstname=${formData.firstName}` };
    instance(apiData)
      .then((response) => {
        const { status, type } = response.data;
        if (status) {
          if (type === 'Datasheet') {
            setShowSuccess(true);
            downloadLink.click();
            URL.revokeObjectURL(pdfUrl);
            document.body.removeChild(downloadLink);
            setIsLoading(false); // Stop loading
            setShowModal(false);
            resetForm();
          }
        }
        return response.data;
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          setSendError(true);
          setIsLoading(false);
          setShowModal(false);
          resetForm();
        }
        return error.message;
      });

  };

  return (
    <ProductAboutStyle>
      <div className="AnritaAbout" style={{ height: '100%' }}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center AnritaAbout_main" style={{ height: '100%' }}>
            <div className={`col-xl-7 col-lg-12 col-sm-12 AnritaAbout_img AnritaAbout_box ${isInViewport ? 'fade-in' : ''}`} ref={ref}>
              <img
                src={productData?.productAbout?.productImage?.productAboutDashboard}
                alt={`${productData?.product}aboutDash`}
                className="AnritaAbout_Prod_img"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
              {/* <img
              className="py-4 enrita_logo"
              src={productData?.productHeader?.productImage?.productLogo}
              alt={`${productData?.product}Logo`}
              width={200}
            /> */}
            </div>
            <div className={`col-xl-5 col-lg-12 col-sm-12 pt-lg-3 pt-xl-0 AnritaAbout_content AnritaAbout_box ${isInViewport ? 'fade-in' : ''}`} ref={ref}>
              <div className="AnritaAbout_title pb-3">{productData?.productAbout?.subTitle}</div>
              {/* <div className="AnritaAbout_para pb-3">{productData?.productAbout?.subTitle}</div> */}
              <ButtonIndex
                ButtonType="Light"
                text="Know More"
                className="AnritaAbout_btn"
                onClick={openModal} // Call the openModal function when the button is clicked
              />
              {/* <Link to="/contact"> */}
              <ButtonIndex ButtonType="Light" text="View Features" className="scroll_features1 AnritaAbout_btn AnritaAbout_btn_right" />
              {/* </Link> */}
            </div>
          </div>
        </div>
        <div className="AnritaAbout_down_icon d-flex justify-content-end">
          <Icon iconType="Home" name="scrolldown" width={15} className="scroll_features" />
        </div>
      </div>

      {/* Modal */}
      <DownloadModal showModal={showModal} closeModal={closeModal} formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} isLoading={isLoading} />
    </ProductAboutStyle>
  );
};

export default ProductAbout;
