import styled from 'styled-components';

const CompanyLeaderStyle = styled.div`
  .Companyleader_title {
    color: #122fba;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.4%; /* 37.248px */
    text-transform: capitalize;
  }

  /* CompanyLeaderStyle.js (or your stylesheet) */
  .profile_card_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 2rem 0;
  }
  .profile_box {
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    box-shadow: 0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
    width: 307px;
    /* height: 295px; */
    overflow: hidden;
    /* margin: 60px 20px; */

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    .profile_card_childbox {
    }

    .profile_inner_box1 {
      // height:250px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        box-shadow: 0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05);
      }
      a {
        text-decoration: none;
      }
      .profilecard_icon {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
    .profile_inner_box2 {
      background: #fff;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      .profile_name {
        color: #293466;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
      }
      .profile_post {
        color: #848484;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
`;

export default CompanyLeaderStyle;
