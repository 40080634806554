import styled from 'styled-components';

const ProductFeaturesStyle = styled.div`
  @media (min-width: 1400px) {
    .container {
      max-width: 1200px;
    }
  }

  .AnitaFeatures {
    padding: 0 2%;
    background: var(--main-light-bg);
  }

  .Anita-title {
    font-size: 2rem;
    font-weight: var(--fw-700);
    color: var(--main-dark-clr);
    line-height: 50px;
    margin-top: 0.8rem;
  }

  .AnitaFeatures_childbox {
    ${'' /* width:400px; */}
    background:white;
    padding: 20px 20px;
    margin: 10px;
    border-radius: 5px;
    color: var(--main-dark-bg);
    position: relative;

    border-radius: 0.566rem;
    box-shadow: 0px 27.16701px 54.33402px 13.5835px rgba(0, 0, 0, 0.05);

    & svg {
      path {
        fill: var(--main-dark-bg);
      }
    }
    &:hover {
      background: var(--main-dark-bg);
      color: white;
      & svg {
        path {
          fill: white;
        }
      }
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.3s ease-in;
    }
    .AnitaFeatures_childboxicon {
      position: absolute;
      top: 10px;
      right: 15px;
      &:hover {
        color: white;
      }
    }
    .AnitaFeatures_expndtitlebold {
      font-weight: bold;
    }
  }

  .AnitaFeatures_expndtitle {
    font-family: Poppins;
    font-size: 0.9375rem;
    font-style: normal;
    line-height: 133.4%; /* 1.25063rem */
    letter-spacing: 0.03281rem;
    padding-right: 20%;
  }

  .AnitaFeatures_childbox.active {
    background: var(--main-dark-bg);
    color: white;
    & svg {
      path {
        fill: white;
      }
    }
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease-in;
  }
  .AnitaFeatures_childbox_title {
    color: #fff;
    font-family: Poppins;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.4%; /* 1.12875rem */
    letter-spacing: 0.03281rem;
  }
  .AnitaFeatures_childbox_para {
    color: #fff;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%; /* 1.0005rem */
    letter-spacing: 0.02625rem;
  }
  .AnitaFeatures_childbox_mainimg {
    height: 100%;
  }

  .AnitaFeatures_childbox_mainInner {
    padding-left: 3rem;
    margin-top: 2rem;

    @media (max-width: 900px) {
      padding-left: 0;
    }
  }

  .AnitaFeatures_childbox_mainimg img,
  .AnitaFeatures_childbox_childimg img {
    cursor: pointer;
    position: relative;
  }
  .AnitaFeatures_subChildbox_Top_childimg {
    position: relative;
    /* height: 100%; */
    /* border: 1px solid red; */

    img,
    video {
      width: 100%;
      border-radius: 20px;
    }
  }
  .AnitaFeatures_subChildbox_childimg {
    /* border: 1px solid green; */
    height: 80%;
    width: 48%;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    cursor: pointer;
    font-size: 25px;
  }
  .video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export default ProductFeaturesStyle;
