import styled from 'styled-components';

const ZSFooterSectionOne = styled.div`
  background: var(--main-dark-bg);

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }

  .FooterSectionOne_left {
    /* padding-left: 3.5rem; */

    @media (min-width: 1200px) {
      padding-right: 6rem;
      padding-left: 0;
    }

    @media (min-width: 1369px) {
    }

    @media (max-width: 600px) {
      padding-left: 0;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  .FooterSectionOne_left_contact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media (min-width: 1024px) {
      justify-content: space-between;
    }

    .FooterSectionOne_left_mail {
      text-decoration: none;
      color: var(--main-light-clr);
      padding: 5px 0;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: Poppins;
    }
    .fa-envelope {
      font-size: 13px;
      margin-right: 10px;
    }
    .FooterSectionOne_left_mail:hover {
      color: rgb(195 193 193) !important;
    }
    .FooterSectionOne_left_mail span {
      margin-right: 25px;
      font-size: var(--fs-12);

      @media (min-width: 1024px) {
        margin-right: 0;
      }
    }
  }

  .FooterSectionOne_para {
    color: #fff;
    text-align: justify;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 134.4%; /* 17.472px */
    padding-right: 0;
  }

  .footer1__links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .FooterSectionOne_right_links {
    text-align: left;
    :hover {
      color: rgb(195 193 193) !important;
    }
  }
  a {
    text-decoration: none;
  }

  .FooterSectionOne_btn {
    margin-right: 45px;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .FooterSectionOne_right {
    .footerMenuWrapper {
      .footerMenuCol {
        &:nth-child(1) {
          @media (min-width: 1024px) {
            width: 29%;
          }
        }
      }
    }
    .FooterSectionOne_right_headings {
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .FooterSectionOne_right_links {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
`;

export default ZSFooterSectionOne;
