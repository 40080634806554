import React from 'react';
import { Modal } from 'react-bootstrap';

const DarkModal = ({ showModal, handleCloseModal, title, children, closeBtn }) => {
  return (
    <Modal className=" px-0 " show={showModal} onHide={handleCloseModal}>
      <Modal.Header className="border-0 mx-3 mt-4 mb-0">
        <Modal.Title className="bookingform_title">{title}</Modal.Title>
        <button className="darkmodal_closeBtn" onClick={handleCloseModal}>
          {' '}
          {closeBtn}{' '}
        </button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default DarkModal;
