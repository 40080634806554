import PropTypes from 'prop-types';
import { useState } from 'react';
import ZSLightSmallCard from '../../../../Component/ZSCardsLayout/ZSLightSmallCard/Index';
import { CyberDefensesBoxData } from '../../HomeData/Index';
import ZSCyberDefensesImgData from './ZSCyberDefensesLib/ZSCyberDefensesImgData/Index';
import CyberDefensesStyle from './ZSCyberDefensesStyle';
import { Slide } from 'react-awesome-reveal';

const CyberDefenses = () => {
  const [selectedCard, setSelectedCard] = useState(CyberDefensesBoxData[0]);
  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  return (
    <CyberDefensesStyle className="container">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="CyberDefenses">
          <div className="Center__title text-center">
            <p className="mb-3">
              Boost your cyber defenses with our <br /> technologies and advance services
            </p>
          </div>
          <div className="CyberDefenses__para text-center">
            <p className="mb-5">
              Don&apos;t face the latest threats alone - let Zeronsec help you stay ahead of the curve. <br /> Discover how our specialist security services can enhance your cyber resilience.
            </p>
          </div>
          <div className="CyberDefenses__needs text-center">
            <p> YOUR CYBER DEFENCE NEEDS </p>
          </div>
          <div className="CyberDefenses__content">
            <div className="row p-0 m-0">
              <div className="col-lg-6 col-md-12">
                <div className="CyberDefenses__box1">
                  <div className="row pl-0">
                    {CyberDefensesBoxData.map((card) => (
                      <div className="col-md-6" key={card.id}>
                        <ZSLightSmallCard id={card.id} iconName={card.iconName} label={card.label} onClick={() => handleCardClick(card)} isActive={selectedCard.id === card.id} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 ">{selectedCard && <ZSCyberDefensesImgData card={selectedCard} />}</div>
            </div>
          </div>
        </div>
      </Slide>
    </CyberDefensesStyle>
  );
};

CyberDefenses.propTypes = {};

ZSLightSmallCard.propTypes = {
  id: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

ZSCyberDefensesImgData.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
};

CyberDefenses.defaultProps = {
  card: []
};

export default CyberDefenses;
