/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import CompanyMentorStyle from './CompanyMentorStyle.js';
import Icon from '../../../../Icon/ZSHomeIcon/Index';
import { mentors } from '../../CompanyData/Index';
import { Slide } from 'react-awesome-reveal';

const CompanyMentor = () => {
  const [expandedMentorIndex, setExpandedMentorIndex] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMentor = (index) => {
    if (expandedMentorIndex === index) {
      setExpandedMentorIndex(null);
    } else {
      setExpandedMentorIndex(index);
    }
  };

  return (
    <Slide direction="up" triggerOnce duration={1500} delay={100}>
      <CompanyMentorStyle>
        <div className="CompanyMentor_title pb-5">Our Mentors</div>
        {mentors.map((mentor, index) => (
          <div className={`mentor_content rounded mb-2 ${expandedMentorIndex === index ? 'main_more_content' : ''}`} key={index}>
            <div className="mentor_img">
              <img
                className="rounded-3"
                src={mentor.image1} // Show only image1
                alt={`Mentor Profile - ${mentor.name}`}
                width="100%"
                height="100%"
              />
            </div>
            <div className={`mentor_details ${expandedMentorIndex === index ? 'more_content sameColor' : ''}`}>
              <div className="mentor_linkedin_main">
                <a href={mentor.url} target="_blank">
                  <Icon name={expandedMentorIndex === index ? 'linkedin' : 'linkedindark'} iconType="Home" width={27} />
                </a>
              </div>
              <div className="mentor_name m-2">{mentor.name}</div>
              <div className="mentor_position m-2 ">{mentor.position}</div>
              <div className={`mentor_shortBio m-2 ${expandedMentorIndex === index ? 'mb-5' : ''}`}>
                {expandedMentorIndex === index ? mentor.longBio.map((e) => <p key={e}>{e}</p>) : mentor.shortBio}
              </div>
              <div className="mentor_readMore m-2" onClick={() => toggleMentor(index)}>
                {expandedMentorIndex === index ? 'Read Less' : 'Read More'}
              </div>
            </div>
          </div>
        ))}
      </CompanyMentorStyle>
    </Slide>
  );
};

export default CompanyMentor;
