import { Slide } from 'react-awesome-reveal';

import CompanyMissionStyle from './CompanyMissionStyle.js';

const CompanyMission = () => {
  return (
    <CompanyMissionStyle className="container-fluid py-5 mb-5">
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="CompanyMission">
          <div className="CompanyMission_title py-5">We are on a Misson</div>
          <div className="CompanyMission_para pb-5">
            To develop and improve pioneering information security <br /> technologies and services to safeguard our clients. Empower our <br />
            team to be the foremost experts in the areas we serve.
          </div>
        </div>
      </Slide>
    </CompanyMissionStyle>
  );
};

export default CompanyMission;
