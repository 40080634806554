import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ZSHomeSliderStyle from './ZSHomeSliderStyle';
import ButtonIndex from '../../../../Component/ZSForm/ZSButtons/Index';
import { Link } from 'react-router-dom';
import { Slide } from 'react-awesome-reveal';

import { ZsHomeTabKey1, ZsHomeTabKey2, ZsHomeTabKey3 } from './Lib/ZsHomeTabKey1/Index';

const HomeSlider = () => {
  const [activeTabKey, setActiveTabKey] = useState('key1');

  const renderTabContent = (tabKey) => {
    switch (tabKey) {
      case 'key1':
        return <ZsHomeTabKey1 />;
      case 'key2':
        return <ZsHomeTabKey2 />;
      case 'key3':
        return <ZsHomeTabKey3 />;
      default:
        return null;
    }
  };

  return (
    <ZSHomeSliderStyle>
      <Slide direction="up" triggerOnce duration={1500} delay={100}>
        <div className="container-fluid py-4">
          <div className="Center__title text-center">
            <p className="mt-3">
              Challenges <br /> That Zeronsec Solves
            </p>
          </div>
          <Tabs id="controlled-tab-example" activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k)} className="mb-3 d-flex justify-content-center">
            <Tab
              eventKey="key1"
              title={
                <>
                  <span className="home_tabs">
                    Detect, Alert <br /> and Analyse
                  </span>
                  {activeTabKey === 'key1' && <div className="activeBottom_border"></div>}
                </>
              }
            >
              {renderTabContent('key1')}
              <div className={`my-5 d-flex justify-content-center ${activeTabKey === 'key1' ? 'open-box' : ''}`}>
                <Link to="/product/anrita">
                  <ButtonIndex ButtonType="Dark" text="Know More" className="container_left_content_btn" />
                </Link>
              </div>
            </Tab>
            <Tab
              eventKey="key2"
              title={
                <>
                  <span className="home_tabs">
                    Prioritize, Collaborate,
                    <br /> Respond and Remediate
                  </span>
                  {activeTabKey === 'key2' && <div className="activeBottom_border"></div>}
                </>
              }
            >
              {renderTabContent('key2')}
              <div className={`my-5 d-flex justify-content-center ${activeTabKey === 'key2' ? 'open-box' : ''}`}>
                <Link to="/product/ekasha">
                  <ButtonIndex ButtonType="Dark" text="Know More" className="container_left_content_btn" />
                </Link>
              </div>
            </Tab>
            <Tab
              eventKey="key3"
              title={
                <>
                  <span className="home_tabs">
                    Collect, Curate
                    <br /> and Correlate
                  </span>
                  {activeTabKey === 'key3' && <div className="activeBottom_border"></div>}
                </>
              }
            >
              {renderTabContent('key3')}
              <div className={`my-5 d-flex justify-content-center ${activeTabKey === 'key3' ? 'open-box' : ''}`}>
                <Link to="/product/threati">
                  <ButtonIndex ButtonType="Dark" text="Know More" className="container_left_content_btn" />
                </Link>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Slide>
    </ZSHomeSliderStyle>
  );
};

export default HomeSlider;
