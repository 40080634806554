// FormValidation.js
export const validateEmail = (email) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailPattern.test(email);
};

export const DownloadValidation = (formData) => {
  const errors = {};

  if (!formData.firstName) {
    errors.firstNameError = 'First name is required';
  }

  if (!formData.lastName) {
    errors.lastNameError = 'Last name is required';
  }

  if (!formData.email || !validateEmail(formData.email)) {
    errors.emailError = 'Invalid email address';
  }

  const phoneNumberPattern = /^[0-9]+$/;
  if (!formData.phoneNumber || !phoneNumberPattern.test(formData.phoneNumber)) {
    errors.phoneNumberError = 'Invalid contact number';
  }

  if (!formData.acceptTerms) {
    errors.acceptTermsError = 'Accept terms and conditions';
  }

  return errors;
};
