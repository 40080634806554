import styled from 'styled-components';

const CompanyMissionStyle = styled.div`
  background: var(--main-dark-bg);
  @media (min-width: 770px) and (max-width: 999px) {
    padding-top: 12rem !important;
  }

  @media (min-width: 1000px) and (max-width: 1400px) {
    padding-top: 7rem !important;
  }

  .CompanyMission_title {
    color: #fff;
    text-align: center;
    font-size: 48.432px;
    font-style: normal;
    font-weight: 700;
    line-height: 99.9%; /* 48.384px */
    text-transform: capitalize;
    @media (max-width: 770px) {
      font-size: 34px;
    }
  }
  .CompanyMission_para {
    color: #fff;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 162.4%; /* 35.728px */
    @media (max-width: 770px) {
      font-size: 18px;
    }
  }
`;

export default CompanyMissionStyle;
