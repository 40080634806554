import styled from 'styled-components';

const ZSTrustedIndusstryLeadersContainer = styled.div`
  padding: 0 3%;
  .TrustedIndusstryLeaders_title {
    color: #1230bd;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.4%; /* 37.248px */
    text-transform: capitalize;
    margin-bottom: 5rem;

    @media (max-width: 600px) {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
  }

  .leaders_box {
    ${'' /* width:100%; */}
    ${'' /* height:100px; */}
      display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;

    @media (max-width: 600px) {
      margin: 0;
    }

    .leaders_box_img {
      width: 200px;
      ${'' /* height:100px; */}
      display:flex;
      ${'' /* flex-wrap:wrap; */}
      justify-content:center;
      align-items: center;
      margin: 10px;

      @media (max-width: 600px) {
        width: unset;
        margin: 0;
        flex-basis: 40%;
        justify-content: space-around;
        margin-bottom: 2rem;

        img {
          width: 80%;
        }
      }
    }
  }
`;

export default ZSTrustedIndusstryLeadersContainer;
