export const ZSFooterSection1Data = [
  {
    title: 'Products',
    links: [
      { id: 'anrita', text: 'Anrita', url: '/product/anrita' },
      { id: 'ekasha', text: 'Ekasha', url: '/product/ekasha' },
      { id: 'threati', text: 'Threat-i', url: '/product/threati' }
    ]
  },
  {
    title: 'Services',
    links: [
      {
        id: 'cloudsecurity',
        text: 'Cloud Security',
        url: '/services/cloudsecurity'
      },
      { id: 'mdr', text: 'MDR', url: '/services/mdr' },
      { id: 'soc', text: 'SOC', url: '/services/soc' },
      {
        id: 'assessmentservice',
        text: 'Assessment Service',
        url: '/services/assesmentservices'
      }
    ]
  },
  {
    title: 'Company',
    links: [
      { id: 'about', text: 'About', url: '/company' },
      { id: 'leadership', text: 'Our Leadership', url: '/company#ourLeadership' },
      { id: 'contact', text: 'Contact', url: '/contact' }
    ]
  }
];

// export const menuItems = [
//   {
//     id: 'Products',
//     label: 'Products',
//     path: '/',
//     subItems: [
//       { id: 'anrita', label: 'Anrita', path: '/anrita' },
//       { id: 'ekasha', label: 'Ekasha', path: '/ekasha' },
//       { id: 'threati', label: 'Threat i', path: '/threati' }
//     ]
//   },
//   {
//     id: 'Services',
//     label: 'Services',
//     path: '/services',
//     subItems: [
//       { id: 'cloudsecurity', label: 'CloudSecurity', path: '/cloudsecurity' },
//       { id: 'mdr', label: 'MDR', path: '/mdr' },
//       { id: 'soc', label: 'SOC', path: '/soc' },
//       { id: 'assessmentservice', label: 'Assessment Service', path: '/assessmentservice' }
//     ]
//   },
//   {
//     id: 'Company',
//     label: 'Company',
//     path: '/company',
//     subItems: [
//       { id: 'about', label: 'About', path: '/about' },
//       { id: 'resources', label: 'Resources', path: '/resources' },
//       { id: 'contact', label: 'Contact', path: '/contact' }
//     ]
//   },
//   { id: 'partner', label: 'Partner', path: '/partner' }
// ];

export const Footer2Links = [
  { label: 'Terms of Service', path: '/terms' },
  { label: 'Cookie Policy', path: '/cookie' },
  { label: 'Privacy Policy', path: '/privacy' }
];

export const ZSFooter2Icon = [
  { name: 'fa-linkedin', url: 'https://www.linkedin.com/company/zeronsec' },
  { name: 'fa-twitter', url: 'https://twitter.com/zeronsec' },
  { name: 'fa-instagram', url: 'https://www.instagram.com/zeronsec' }
];
