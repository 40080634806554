import styled from 'styled-components';

const ZSContentStyle = styled.div`
  background: var(--main-light-bg);
  overflow: hidden;

  .container_left_content {
    padding: var(--container-p);
  }
  
  .Center__title {
    color: #1230BD;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.4%; /* 37.248px */
    text-transform: capitalize;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }

  @media screen and (max-width: 776px) {
    .container_left_content {
      padding: 10% 0;
    }
  }
  
  @media screen and (min-width: 776px) and (max-width: 1300px) {
    .container_left_content_1280 {
      padding: 5% 1%;
    }
  }
  @media screen and (max-width: 1920px) {
    .container_left_content_1280 {
      padding: 5% 1%;
    }
  }
`;

export default ZSContentStyle;
