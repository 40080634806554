import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './downloadmodal.scss';
import DarkModal from '../../DarkModalFormat/Index';

const DownloadModal = ({ showModal, closeModal, formData, handleInputChange, handleSubmit, isLoading }) => {
  return (
    <DarkModal showModal={showModal} handleCloseModal={closeModal}>
      <div className="modal-header mb-3" style={{ marginTop: '-30px' }}>
        <h5 className="modal-title pt-4 pb-2">Download Datasheet</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="firstName" className="mb-4">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" name="firstName" placeholder="Enter Name" value={formData.firstName} onChange={handleInputChange} />
          <Form.Text className="download_error">{formData.firstNameError}</Form.Text>
        </Form.Group>
        <Form.Group controlId="lastName" className="mb-4">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" name="lastName" placeholder="Enter Name" value={formData.lastName} onChange={handleInputChange} />
          <Form.Text className="download_error">{formData.lastNameError}</Form.Text>
        </Form.Group>
        <Form.Group controlId="email" className="mb-4">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" placeholder="Enter Email" value={formData.email} onChange={handleInputChange} />
          <Form.Text className="download_error">{formData.emailError}</Form.Text>
        </Form.Group>
        <Form.Group controlId="phoneNumber" className="mb-4">
          <Form.Label>Contact Number</Form.Label>
          <Form.Control type="tel" name="phoneNumber" placeholder="Enter Contact Number" value={formData.phoneNumber} onChange={handleInputChange} />
          <Form.Text className="download_error">{formData.phoneNumberError}</Form.Text>
        </Form.Group>
        <Form.Group controlId="acceptTerms">
          <div style={{ display: 'flex', alignItems: 'baseline' }}><div><Form.Check type="checkbox" label="I accept the" name="acceptTerms" checked={formData.acceptTerms} onChange={handleInputChange} className="mt-4" /></div> <a href='/terms&condition' target='_blank'><div style={{ marginLeft: '5px', color: 'rgb(130 180 255)', fontFamily:'Poppins', fontSize: '13.898px', cursor: 'pointer' }}>terms and conditions</div></a></div>
          <Form.Text className="download_error" style={{ bottom: '60px' }}>{formData.acceptTermsError}</Form.Text>
        </Form.Group>
        <Button variant="primary" className="download_btn mb-4" type="submit" style={{ float: 'right' }} disabled={isLoading}>
          {isLoading ? (
            <>
              Downloading...
              <div className="loading-spinner"></div>
            </>
          ) : (
            'Download'
          )}
        </Button>
      </Form>
    </DarkModal>
  );
};

export default DownloadModal;
