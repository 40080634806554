import profile1christofsir from '../../../Image/ZSCompany/Christog A.jpg';
import profile2dipaksir from '../../../Image/ZSCompany/Dipak V.jpg';
import profile3ravisir from '../../../Image/ZSCompany/Ravi V.jpg';
import profile4paramSingh from '../../../Image/ZSCompany/Param S.jpg';
import profile7amodPatankar from '../../../Image/ZSCompany/Amod Patankar.png';
import mentorpavansir1 from '../../../Image/ZSCompany/Pawan Sharma.png';
import mentorpavansir2 from '../../../Image/ZSCompany/Pawan Sharma - 1.png';
import mentorjrksir1 from '../../../Image/ZSCompany/JRK Sir.png';
import mentorjrksir2 from '../../../Image/ZSCompany/JRK Sir - 1.png';

export const leaders = [
  {
    name: 'Dipak Vagadiya',
    position: 'Founder & Chief Executive Officer',
    profileImage: profile2dipaksir,
    url: 'https://www.linkedin.com/in/deepak-vagadiya-49a7349/'
  },
  {
    name: 'Ravi Vagadiya',
    position: 'Chief Technology Officer',
    profileImage: profile3ravisir,
    url: 'https://www.linkedin.com/in/ravi-vagadiya-a9aa3999/'
  },
  {
    name: 'christof Anderi',
    position: 'Director, Germany',
    profileImage: profile1christofsir,
    url: 'https://www.linkedin.com/in/christof-h-anderi-00a2075/'
  },
  {
    name: 'Param Singh',
    position: 'Head Cyber Security, ANZ',
    profileImage: profile4paramSingh,
    url: 'https://www.linkedin.com/in/psingh22'
  },
  {
    name: 'Amod Patankar',
    position: 'VP Cyber Security, North America',
    profileImage: profile7amodPatankar,
    url: 'https://www.linkedin.com/in/amodpatankar/'
  }
];

export const mentors = [
  {
    name: 'Pawan Sharma',
    position: 'Board Member',
    shortBio: ' Pawan Sharma serves as a board member for Zeronsec and is an active angel investor in companies globally.',
    longBio: [
      '    He has helped companies set up & grow Consulting, ITO, Engineering and BPO operations which included hardware and software products across the globe for companies like HCL, HP, IBM, KPIT and Tech Mahindra.',
      'Pawan has served Tech Mahindra as the President & Global Head of Strategic Initiatives, KPIT as the CEO & Board Member, IBM as Country Head for Software Group, Software Labs, and Developer Relations, and HCL, heading several business and entities.',
      'An engineer by profession, Pawan has participated in the ISB-Kellogg Global Advanced Management Program.He is a certified Digital Leader from Hasso Plattner Institute - Potsdam and Stanford USA on Design Thinking and Innovation and a certified professional on Advanced Cybersecurity from Stanford(USA).He served as an active member of the Strategic Advisory Board for the North Carolina State Department of Computer Science(USA), and was an active member on the Standards Board of JD Powers & Software Support Professional Association - USA, PMI USA Board of Special Initiative Group on Strategic Outsourcing, and as the founder, CEO & President of PMI North India Chapter.'
    ],
    image1: mentorpavansir1,
    image2: mentorpavansir2,
    url: 'https://www.linkedin.com/in/pawan-sharma-228442/'
  },
  {
    name: 'K. Jayaramakrishnan',
    position: 'Board Member',
    shortBio: '  K. Jayaramakrishnan, popularly known as JRK, serves as a board member for Zeronsec, having 35+ years of experience in the IT Industry.',
    longBio: [
      '    K. Jayaramakrishnan, popularly known as JRK, serves as a Board Member for Zeronsec, having 35+ years of experience in the IT Industry.He had roles starting from ground level execution and transforming into senior leadership and board level responsibilities and experience in sales & marketing across Global markets.He has been widely recognized for operational excellence and innovative business approaches and managing international markets.',
      'JRK is currently a consultant in Tata Consultancy Services (TCS) after superannuating from TCS as Vice President, Head - Global Alliances.He is also a Director of Rajalakshmi Institutions.While at TCS, JRK was Client Relationship Manager for American Express, Time Warner, Target, Nasdaq, Regional Manager at Maryland, USA, and in India covering Indian Financial Institutions, Corporates and Government customers',
      'JRK is currently in the Proposal Approval Committee of National Skill Development Corporation(NSDC).Jayaramakrishnan was the Chairman - Convener for the CII Task Force on Industry - Institute - Interaction from 2004 to 2009. He was the Chairman of Computer Society of India, Chennai Chapter - 2008. JRK was the member of the IT Task Force of the Government of Gujarat and e - Governance Standards Committee of Government of Tamil Nadu.He was the convener for the ICT Academy of Tamilnadu(A Consortium of Govt.of India, Govt.of Tamilnadu & CII).'
    ],
    image1: mentorjrksir1,
    image2: mentorjrksir2,
    url: 'https://www.linkedin.com/in/jayaramakrishnan/'
  }
];
