import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

const ReusableForm = ({ formData, formErrors, handleInputChange, handleSubmit, topics, isLoading }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <div className="col-lg-7 col-md-6 col-sm-12 mb-lg-0 mb-md-0 mb-sm-3">
          <Form.Group controlId="firstName">
            <Form.Label className="mt-1">First Name</Form.Label>
            <Form.Control type="text" name="firstName" placeholder="Enter Name" value={formData.firstName} onChange={handleInputChange} />
            {formErrors.firstName && <Form.Text className="error m-0">{formErrors.firstName}</Form.Text>}
          </Form.Group>
        </div>
        <div className="col-lg-5 col-md-6 col-sm-12">
          <Form.Group controlId="lastName">
            <Form.Label className="mt-1">Last Name</Form.Label>
            <Form.Control type="text" name="lastName" placeholder="Enter Name" value={formData.lastName} onChange={handleInputChange} />
            {formErrors.lastName && <Form.Text className=" error m-0">{formErrors.lastName}</Form.Text>}
          </Form.Group>
        </div>
      </Row>
      <Row className="">
        <div className="col-lg-7 col-md-6 col-sm-12 mb-lg-0 mb-md-0 mb-sm-3">
          <Form.Group controlId="email">
            <Form.Label className="mt-1">Email</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter Email" value={formData.email} onChange={handleInputChange} />
            {formErrors.email && <Form.Text className=" error m-0">{formErrors.email}</Form.Text>}
          </Form.Group>
        </div>
        <div className="col-lg-5 col-md-6 col-sm-12">
          <Form.Group controlId="phoneNumber">
            <Form.Label className="mt-1">Contact Number</Form.Label>
            <Form.Control type="tel" name="phoneNumber" placeholder="Enter Contact Number" value={formData.phoneNumber} onChange={handleInputChange} />
            {formErrors.phoneNumber && <Form.Text className=" error m-0">{formErrors.phoneNumber}</Form.Text>}
          </Form.Group>
        </div>
      </Row>
      <Form.Group controlId="topic" className="my-3">
        <Form.Label className="mt-1">Choose a Topic</Form.Label>
        <Form.Control as="select" name="topic" value={formData.topic} onChange={handleInputChange}>
          {topics.map((topic) => (
            <option key={topic.value} value={topic.value}>
              {topic.label}
            </option>
          ))}
        </Form.Control>
        {formErrors.topic && <Form.Text className=" error m-0">{formErrors.topic}</Form.Text>}
      </Form.Group>
      <Form.Group controlId="message">
        <Form.Label className="mt-1">Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          name="message"
          placeholder="Enter Message"
          value={formData.message}
          onChange={handleInputChange}
          className="fixed-textarea" // Add this class
        />
      </Form.Group>
      <Row className="justify-content-around pt-2">
        <Col className="py-4 d-flex align-items-center">
          <Form.Group controlId="acceptTerms">
            <div style={{ display: 'flex', alignItems: 'baseline' }}><div><Form.Check type="checkbox" label="I accept the" name="acceptTerms" checked={formData.acceptTerms} onChange={handleInputChange} className="text-light" /></div> <a href='/terms&condition' target='_blank'><div style={{ marginLeft: '5px', color: 'rgb(130 180 255)', fontFamily:'Poppins', fontSize: '13.898px', cursor: 'pointer' }}>terms and conditions</div></a></div>
            {formErrors.acceptTerms && <Form.Text className="error m-0">{formErrors.acceptTerms}</Form.Text>}
          </Form.Group>
        </Col>
        <Col className="d-flex justify-content-end py-4">
          <Button type="submit" className="bookingform_btn" disabled={isLoading}>
            {isLoading ? (
              <>
                Booking...
                <div className="loading-spinner"></div>
              </>
            ) : (
              'Book a Demo'
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ReusableForm;
