import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReusableForm from '../../../../Form/BookingForm/Index';
import { validateForm, initialFormErrors } from '../../../../Form/FormValidation';
import AlertToken from '../../../../Component/AlertTokan/Index'; // Correct import path
import './booking.css';
import DarkModal from '../../DarkModalFormat/Index';

const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  topic: '',
  message: '',
  acceptTerms: false
};

const BookingModal = ({ showModal, handleCloseModal }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [showSuccess, setShowSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Reset the form when modal is closed
  useEffect(() => {
    if (!showModal) {
      setFormData(initialFormData);
      setFormErrors(initialFormErrors);
    }
  }, [showModal]);

  const handleInputChange = (event) => {
    setSendError(false);
    setShowSuccess(false);
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isValid, errors } = validateForm(formData);

    const instance = axios.create({
      baseURL: `https://${window.location.hostname}:5000`,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      }
    });
    if(isValid) {
      setIsLoading(true); // Set loading state to true
      const apiData = { method:'get', url:`/productdemo?email=${formData.email}&lastname=${formData.lastName}&contact=${formData.phoneNumber}&firstname=${formData.firstName}&topic=${formData.topic}&message=${formData.message}` };
      instance(apiData)
        .then((response) => {
          const { status, type } = response.data;
          if (status) {
            if (type === 'productDemo') {
              setShowSuccess(true);
              setFormData(initialFormData);
              setFormErrors(initialFormErrors);
              setIsLoading(false);
              handleCloseModal();
            }
          }
          return response.data;
        })
        .catch((error) => {
          if (error.code === 'ERR_NETWORK') {
            setSendError(true);
            setShowSuccess(false);
            setIsLoading(false);
            handleCloseModal();
  
          }
          return error.message;
        });
    } else {
      setFormErrors(errors);
    }
  };

  const topics = [
    { value: '', label: 'Select One' },
    { value: 'MDR Service', label: 'MDR Service' },
    { value: 'Assessment Service', label: 'Assessment Service' },
    { value: 'Cloud Security Service', label: 'Cloud Security Service' },
    { value: 'SOC Technology', label: 'SOC Technology' }
    // Add more topics as needed
  ];

  return (
    <div className="modal__container">
      <DarkModal showModal={showModal} handleCloseModal={handleCloseModal} title="Get In Touch" closeBtn="&times;">
        <ReusableForm formData={formData} formErrors={formErrors} handleInputChange={handleInputChange} handleSubmit={handleSubmit} topics={topics} isLoading={isLoading} />
      </DarkModal>
      {/* Show the success and error alerts based on state */}
      <AlertToken
        variant="success"
        show={showSuccess}
        message={
          <>
            Thank You for Requesting a Product Demo! <br />
            <br />
            Hello there, <br />
            <br />
            Thank you for expressing interest in booking the demo. Your curiosity is valued, and we're eager to showcase our capabilities to you. <br />
            <br />
            A member of our team will reach out to you via email to coordinate the details and set up the demo. If you have any specific preferences or topics you'd like covered during the demo,
            please be sure to let us know. We're committed to providing you with a comprehensive and enlightening experience.
            <br />
            <br />
            Warm regards, <br />
            <br />
            Team Zeronsec
          </>
        }
        duration={10000}
        onClose={() => setShowSuccess(false)}
      />
      <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." duration={10000} onClose={() => setSendError(false)} />
    </div>
  );
};

export default BookingModal;
