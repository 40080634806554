import styled from 'styled-components';

const ZSSecurityPartnerBox = styled.div`
  margin: 0 !important;

  @media screen and (max-width: 776px) {
    .Center__title {
      font-size: 26px;
    }
  }
  @media screen and (min-width: 1400px) {
    .securityPartnerContainer {
      max-width: 1200px;
    }
  }

  .SecurityPartner_cards {
    .SecurityPartner_card {
      @media (min-width: 550px) and (max-width: 1000px) {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 776px) {
    .Center__title {
      font-size: 26px;
    }
  }

  @keyframes scrollDown {
    0% {
      opacity: 0;
      transform: translateY(1000px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .scroll-down-animation {
    animation: scrollDown 1.4s ease-in-out;
  }
`;

export default ZSSecurityPartnerBox;
