import styled from 'styled-components';

const ZSEnsureOrganizationBox = styled.div`
  background-color: var(--main-light-bg);

  @media (min-width: 1200px) {
    &.container {
      max-width: 1200px;
    }
  }

  .EnsureOrganization_box {
  }

  .EnsureOrganization_box_title {
    color: #1230bd;
    font-family: Poppins;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  .EnsureOrganization_box_para {
    font-size: var(--fs-1);
    margin-top: 6px;
    font-weight: var(--fw-500);

    @media (max-width: 600px) {
      margin-bottom: 2rem;
    }
  }

  .EnsureOrganization_box_input_btn {
    position: relative;
  }

  .EnsureOrganization_box_input {
    width: 80%;
    height: 3.1875rem;
    padding-left: 1.4rem;
    border-radius: 0.375rem;
    background: #f1f1f1;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.07) inset;
    border: none;

    &:focus {
      border: 1px solid #80808038 !important;
    }

    &::placeholder {
      color: rgba(17, 48, 71, 0.3);
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 134.4%; /* 1.344rem */
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .EnsureOrganization_box_input_btn_btn {
    margin-right: 6rem;
    margin-left: auto;
    @media (max-width: 1024px) {
      margin-left: 0;
    }
  }

  .help_btn:hover {
    color: var(--main-dark-clr) !important;
  }
  .Email_valid {
    display: flex;
    flex-direction: column;
    align-items: unset;
    margin-left: 5rem;

    @media (max-width: 1024px) {
      margin-left: 0;
      width: 60%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .invalid-feedback {
    color: red;
    position: absolute;
    top: 48px;
    right: 0;
    font-size: 14px;
    margin-top: 5px;
    width: 80%;
    text-align: end;
  }
`;

export default ZSEnsureOrganizationBox;
