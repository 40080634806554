import React from 'react';
import CompanyAboutStyle from './CompanyAboutStyle.js';
import img1 from '../../../../Image/ZSCompany/1.jfif';
import img2 from '../../../../Image/ZSCompany/2.jfif';
import img3 from '../../../../Image/ZSCompany/3.jfif';
import img4 from '../../../../Image/ZSCompany/4.jfif';
import centerimg from '../../../../Image/ZSCompany/centerimg.png';

const images = [img1, img2, img3, img4, img1, img2, img3, img4];

const CompanyAbout = () => {
  return (
    <CompanyAboutStyle>
      <div className="companyabout">
        <div className="companyabout_imgslider">
          <div className="companyabout_imgslider_track">
            {images.map((img, index) => (
              <div className="companyabout_imgslider_slide" key={index}>
                <div className="companyabout_imgslider_box">
                  <img src={img} className="rounded-4" height="280px" width="426.632px" alt={`Image ${index + 1}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="center_content d-flex justify-content-center  ">
          <div className="center_content_box">
            <div className="center_content_box_title">Who are we?</div>
            <div className="center_content_box_para">
              Greetings! Welcome to Zeronsec, a team of industry veterans driven by a deep passion for cutting-edge technologies that protect your business. We find joy in technology that not only
              addresses business challenges but also fortifies your cyber security posture. With unwavering confidence in next-generation security solutions, we are poised to detect and swiftly
              respond to even the most sophisticated attacks. Collaborating closely with our clients fosters outstanding security design and enduring partnerships. We eagerly anticipate the
              opportunity to engage in meaningful face-to-face discussions, understanding your unique security challenges, and delivering the level of protection you envision.
            </div>
          </div>
        </div>
        <div className="center_content_box_img">
          <div className="center_content_box_blur">
            <p className="blur_contentTitle">Our Vision</p>
            <span className="blur_content">
              To become a global information security company that fosters a dynamic workplace culture, values strategic partnerships, and delivers exceptional customer experiences.
            </span>
          </div>
          <img src={centerimg} className="rounded" />
        </div>
        {/* <div className="center_content d-flex justify-content-center  ">
          <div className="center_content_box2"></div>
        </div> */}
      </div>
    </CompanyAboutStyle>
  );
};

export default CompanyAbout;
