import React, { useEffect, useRef, useState } from 'react';
import ZSProductStyle from './ZSProductStyle.js';
import TrustedIndusstryLeaders from '../ZSHome/Lib/ZSTrustedIndustryLeaders/Index.js';
import EnsureOrganization from '../ZSHome/Lib/ZSEnsureOrganization/Index.js';
import ProductEnvData from '../../envData/ProductEnvData.js';
import ProductsHead from './Lib/ProdutctHead/Index.js';
import ProductAbout from './Lib/ProductAbout/Index.js';
import ProductFeatures from './Lib/ProductFeatures/Index.js';
import ProductBenifits from './Lib/ProductBenifits/Index.js';
import ProductProtect from './Lib/ProductProtect/Index.js';
import $ from 'jquery';
import gsap from 'gsap';
import { animateScroll as scroll } from 'react-scroll';
import AlertToken from '../../Component/AlertTokan/Index.js';

const ZSProducts = (props) => {
  const { type } = props;
  const ProductDataIndex = ProductEnvData.findIndex((d) => d.product === type);

  const [showSuccess, setShowSuccess] = useState(false);
  const [sendError, setSendError] = useState(false); // Add state for error alert

  const aboutSectionRef = useRef(null);
  const featuresSectionRef = useRef(null);
  const anim = useRef();

  const animeHeadRef = useRef();
  const animeTailRef = useRef();

  const [isAnimated, setIsAnimated] = useState(false);
  const [currentType, setCurrentType] = useState();

  const [ctx, setCtx] = useState();

  const scrollHandlar = (event, isDown) => {
    if (isDown) {
      animate();
      return false;
    }
    var delta = event.originalEvent.wheelDelta / 30 || -event.originalEvent.detail;
    console.log('🚀 ~ file: Index.js:37 ~ scrollHandlar ~ delta:', delta, $(window).scrollTop());
    console.log('🚀 ~ file: Index.js:37 ~ scrollHandlar ~ isAnimated:', isAnimated);

    if (delta < 0 && !isAnimated && animeHeadRef.current && $(window).scrollTop() < 1) {
      console.log('🚀 ~ file: Index.js:34 ~ scrollHandlar ~ event:', event, isAnimated);
      animate();
    }
  };

  useEffect(() => {
    console.log('🚀 ~ file: Index.js:40 ~ useEffect ~ isAnimated:', isAnimated, type, currentType);
    if (type !== currentType) {
      setIsAnimated(true);
      $('body').addClass('stop-scrolling');
      setCurrentType(type);
    }

    if (isAnimated) {
      gsap.context(() => {
        gsap.set('.anim', {
          y: 0,
          onComplete: () => {
            console.log('🚀 ~ file: Index.js:49 ~ gsap.set ~ onComplete: set');
            setIsAnimated(false);
          }
        });
      }, anim);
    }
  }, [type]);

  useEffect(() => {
    console.log('🚀 ~ file: Index.js:58 ~ ZSProducts ~ isAnimated:', isAnimated);
  }, [isAnimated]);

  const animate = () => {
    console.log('🚀 ~ file: Index.js:65 ~ animate ~ animate:');

    let ctx = gsap.context(() => {
      if (animeHeadRef.current) {
        gsap.to('.anim', {
          y: -(animeHeadRef.current.offsetHeight + 50),
          duration: 2,
          ease: 'power2.inOut',
          onComplete: () => {
            console.log('🚀 ~ file: Index.js:64 ~ ctx ~ onComplete: to');
            setIsAnimated(true);
            $('body').removeClass('stop-scrolling');
            if (animeHeadRef.current) $('.anim').css('margin-bottom', '-' + animeHeadRef.current.offsetHeight - 50 + 'px');
          }
        });
      }
    }, anim);

    setCtx(ctx);
  };

  useEffect(() => {
    $(window).on('mousewheel DOMMouseScroll', scrollHandlar);

    $('.scroll_d').on('click', (e) => scrollHandlar(e, true));

    return () => {
      $(window).off('mousewheel DOMMouseScroll', scrollHandlar);
      // Remove the class when the component unmounts
      $('body').removeClass('stop-scrolling');

      if (ctx) {
        ctx.revert();
      }
    };
  }, []);

  useEffect(() => {
    const handleScrollDown = () => {
      scroll.scrollTo(aboutSectionRef?.current.offsetTop - (animeHeadRef?.current.offsetHeight + 50), {
        duration: 800,
        delay: 0,
        smooth: 'easeInQuart'
      });
    };

    const handleScrollToFeatures = () => {
      scroll.scrollTo(featuresSectionRef?.current.offsetTop - (animeHeadRef?.current.offsetHeight + 50), {
        duration: 1000,
        delay: 0,
        smooth: 'easeInQuart'
      });
    };

    // Add event listener for scrolling down
    const scrollButton = document.querySelector('.scroll_d');
    if (scrollButton) {
      scrollButton.addEventListener('click', handleScrollDown);
    }

    // Add event listener for scrolling to features section
    const featuresButton = document.querySelector('.scroll_features');
    const featuresButton1 = document.querySelector('.scroll_features1');
    if (featuresButton) {
      featuresButton.addEventListener('click', handleScrollToFeatures);
    }
    if (featuresButton1) {
      featuresButton1.addEventListener('click', handleScrollToFeatures);
    }

    // Clean up the event listeners
    return () => {
      if (scrollButton) {
        scrollButton.removeEventListener('click', handleScrollDown);
      }
      if (featuresButton) {
        featuresButton.removeEventListener('click', handleScrollToFeatures);
      }
      if (featuresButton1) {
        featuresButton1.removeEventListener('click', handleScrollToFeatures);
      }
    };
  }, [type]);

  return (
    <ZSProductStyle>
      <div ref={anim}>
        <div className="anim">
          <div className="anim-head" ref={animeHeadRef}>
            <ProductsHead productData={ProductEnvData[ProductDataIndex]} />
          </div>
          <div className="anim-tail" ref={animeTailRef}>
            <div ref={aboutSectionRef}>
              <ProductAbout productData={ProductEnvData[ProductDataIndex]} setSendError={setSendError} setShowSuccess={setShowSuccess} />
            </div>
            <div ref={featuresSectionRef}>
              <ProductFeatures productData={ProductEnvData[ProductDataIndex]} type={type} />
            </div>
            {ProductEnvData[ProductDataIndex].product !== 'threati' && <ProductBenifits productData={ProductEnvData[ProductDataIndex]} />}
            <ProductProtect productData={ProductEnvData[ProductDataIndex]} />
            <TrustedIndusstryLeaders />
            <EnsureOrganization />
          </div>
        </div>
        <AlertToken
          variant="success"
          show={showSuccess}
          message={
            <>
            Thank you for downloading the datasheet!
              <br />
              <br />
            We appreciate your interest. Have a great day!
            </>
          }
          duration={10000}
          onClose={() => setShowSuccess(false)}
        />
        <AlertToken variant="danger" show={sendError} message="Email sending failed. Please try again later." duration={10000} onClose={() => setSendError(false)} />
      </div>
    </ZSProductStyle>
  );
};

export default ZSProducts;
